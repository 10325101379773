<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row  justify-content-center">
                <!-- <div class="col-lg-5 d-none d-lg-flex back"> -->
                  <!-- <div class="image-box">
                    <img
                      class="image-left"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                    />
                  </div> -->
                  <!-- <div class="flex-grow-1 bg-login-image logo" :style="{ backgroundImage: 'url(' + require('@/assets/img/oneNotice/oneNoticeLogo.png') + ')' }"/> -->
                <!-- </div> -->
                <div class="col-lg-7">
                   <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                    </div>
                  <div class="p-5">
                    <div class="text-center">
                      <h4 class="text-dark mb-4">Reset Password!</h4>
                    </div>
                    <form class="user" @submit.prevent="submitForm">
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          v-bind:class="{
                            'is-invalid': !isEmailValid() && submitted,
                          }"
                          type="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                          v-model.trim="email"
                        />
                        <p>
                          <small
                            class="text-danger"
                            v-if="!isEmailValid() && submitted"
                            >Valid email id is required</small
                          >
                        </p>
                      </div>
                      <!-- btn-primary -->
                      <button
                        class="btn theme-btn btn-block text-light btn-user mx-0"
                        type="submit"
                        :disabled="submitStatus === 'PENDING'"
                      >
                        Reset
                      </button>

                      <hr />
                      <hr />
                    </form>

                    <div class="text-center">
                      <router-link :to="{ name: 'Login' }" class="small"
                        >Already have an account? Login</router-link
                      >
                    </div>
                    <div class="text-center">
                      <router-link :to="{ name: 'CreateAccount' }" class="small"
                        >Create an Account!</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";
import { FORGOT } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "Forgot Password",
  components: {
    Loading,
  },
  methods: {
    
    handleBlur(key) {
      this.$v[key].$dirty = true;
    },
    isEmailValid: function() {
      const emailRegex = /\S+@\S+\.\S+/;
      return this.email == ""
        ? false
        : emailRegex.test(this.email)
        ? true
        : false;
    },
    submitForm() {
      this.isLoading = true;
      this.submitted = true;
      if (!this.isEmailValid()) {
        this.submitStatus = "ERROR";
        this.isLoading = false;
      } else {
        this.onLogin(this.email);
      }
    },
    onLogin(email) {
      this.$store
        .dispatch(FORGOT, { email })
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onChange(e) {
      //this.submitted = false;
      if (e.target.name == "email") {
        this.email = e.target.value;
      }
    },
  },
  computed: {
    // users() {
    //   return this.$store.state.user;
    // },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      email: null,
      submitStatus: null,
    };
  },
  validations: {
    email: { required, email },
  },
};
</script>

<style scoped>
.backdrop
{
  background: transparent;
  height: 100vh;
}
</style>
