<template>
  <div class="col">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <div
      class="card shadow mb-3"
      v-if="currentUser.userRole != 'Super Admin' || superAdmin"
    >
      <div class="card-header theme-area-head py-3">
        <p class="text-white m-0 font-weight-bold">Account Settings</p>
      </div>
      <div class="card-body">
        <form v-if="deliveryAccounts" @submit.prevent="submitForm" novalidate>
          <div
            class="form-row"
            v-for="(item, index) in deliveryAccounts"
            v-bind:key="item.Deliveryaccount_id"
          >
            <div class="col">
              <div class="form-group">
                <label for="username"
                  ><strong
                    >{{ index + 1 + getNumberWithOrdinal(index + 1) }} Account
                    ID</strong
                  ></label
                ><input
                  class="form-control"
                  type="text"
                  disabled="true"
                  :value="item.Deliveryaccount"
                  :name="'Account Id' + index"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label :for="'Alias Name ' + index"
                  ><strong>Account Alias</strong><br /></label
                ><input
                  class="form-control"
                  type="text"
                  :value="item.Deliveryaccount_Alias"
                  :id="item.Deliveryaccount_id"
                  :name="'Alias Name ' + index"
                  :disabled="
                    !canUpdateAlias ||
                    this.currentUser.userRole == 'Super Admin'
                  "
                  @change="onChange"
                />
              </div>
            </div>
          </div>
          <div
            class="form-group"
            v-if="this.currentUser.userRole != 'Super Admin'"
          >
            <button
              :disabled="!canUpdateAlias"
              class="btn theme-btn text-light btn-sm"
              type="submit"
            >
              Save Settings
            </button>
          </div>
        </form>
        <p class="lead card-text m-0 text-warning" v-else>
          Your current plan doesn't allow to access the delivery account.
        </p>
      </div>
    </div>

    <ContactSettings
      v-bind:company-id="companyId"
      v-bind:super-admin="superAdmin"
      v-bind:selected-user="selectedUser"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  LIST_DELIVERY_ACCOUNTS,
  SAVE_DELIVERY_ACCOUNTS,
  LISTUSER_PERMISSIONS,
} from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import ContactSettings from "./ContactSettings";
export default {
  name: "DeliveryAccounts",
  components: {
    Loading,
    ContactSettings,
  },
  props: ["company-id", "super-admin", "selected-user"],
  computed: {
    ...mapGetters(["deliveryAccounts", "currentUser", "listPermission"]),
  },
  beforeMount() {
    this.isLoading = true;
    this.getDeliveryAccounts();

    this.getPermissions();
  },
  watch: {
    selectedUser() {
      this.getDeliveryAccounts();
    },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      deliveryAccountsArray: [],
      submitted: false,
      canUpdateAlias: false,
      isAliasUpdate: false,
    };
  },
  methods: {
    checkButtonStatus() {
      return this.canUpdateAlias;
    },
    getPermissions: function () {
      let body = {
        userdetails_id: this.superAdmin
          ? this.selectedUser.userdetails_id
          : this.$cookie.getCookie("userdetails_id"),
      };

      this.$store
        .dispatch(LISTUSER_PERMISSIONS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.listPermission.map((item) => {
              if (item.permissionType == "Administration Settings") {
                this.isAliasUpdate = true;
              }
            });
            this.setPermissions();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setPermissions() {
      // corporate admin can edit account alias in all cases
      if (
        this.currentUser.userRole == "Corporate Admin" ||
        this.isAliasUpdate
      ) {
        this.canUpdateAlias = true;

        return;
      }

      this.listPermission.map((item) => {
        if (item.permissionType == "Administration Settings") {
          this.canUpdateAlias = true;
        }
      });
    },
    getNumberWithOrdinal: function (n) {
      return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    },
    getDeliveryAccounts: function () {
      let body = {
        userdetails_id: this.superAdmin
          ? this.selectedUser.userdetails_id
          : this.$cookie.getCookie("userdetails_id"),
      };

      this.$store
        .dispatch(LIST_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    onChange(e) {
      this.pushToArray(this.deliveryAccountsArray, {
        id: e.target.id,
        accountAlias: e.target.value,
      });
    },

    pushToArray(arr, obj) {
      const index = arr.findIndex((e) => e.id === obj.id);
      if (index === -1) {
        arr.push(obj);
      } else {
        arr[index] = obj;
      }
    },

    submitForm() {
      this.isLoading = true;
      let body = {
        companyId: this.$cookie.getCookie("COMPANY_ID"),
        deliveryAccounts: this.deliveryAccountsArray,
        userdetails_id: this.$cookie.getCookie("userdetails_id")
          ? this.$cookie.getCookie("userdetails_id")
          : this.selectedUser.userdetails_id,
      };
      this.updateContact(body);
    },

    updateContact(body) {
      this.$store
        .dispatch(SAVE_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });

            this.$emit("updateDeliveryList", this.deliveryAccountsArray);
            this.getDeliveryAccounts();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.show(error.message, {
            type: "error ",
            position: "top-right",
          });
        });
    },
  },
};
</script>
