<template>
  <div id="new-user-section">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <h2 class="text-primary card-subtitle small font-weight-bold mb-3">
      Update User Profile
    </h2>
    <div class="form-row">
      <div class="col">
        <input
          type="text"
          placeholder="First Name"
          class="form-control"
          name="firstName"
           v-model="firstName"
          @change="onChange"
        />
        <p>
          <small class="text-danger" v-if="!firstName && submitted"
            >First name is required</small
          >
        </p>
      </div>
      <div class="col">
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          v-model="lastName"
          @change="onChange"
          class="form-control"
        />
        <p>
          <small class="text-danger" v-if="!lastName && submitted"
            >Last name is required</small
          >
        </p>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col">
        <input
          type="email"
          placeholder="Email"
          name="email"
          v-model="email"
          @change="onChange"
          class="form-control"
          disabled
        />
        <!-- <p>
          <small class="text-danger" v-if="!isEmailValid() && submitted"
            >Valid email id is required</small
          >
        </p> -->
      </div>
      <div class="col">
     
        <Multiselect
          id="userDeliveryAcc"
          v-model="accounts.value"
          :options="accounts.options"
          mode="tags"
          placeholder='Select Account(s)'
          closeOnSelect:false
          createTag:true
          @change="onSelect"
          noResultsText="All Selected!"
          ref="multiselect"
          label="label" track-by="label"
          />
      </div>
    </div>
    <Permissions
    v-bind:existingPermissions="this.permissions"/>
  </div>
</template>
<script>
import Permissions from "./Permissions";
//,LISTUSER_PERMISSIONS
import { EDIT_CORPORATE_USER, SET_ALL_DELIVERY_ACCOUNT_ITEM,GET_CORPORATE_USERS,GET_DELIVERY_ACCOUNTS,LISTUSER_PERMISSIONS } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
export default {
  name: "EditUser",
  components: {
    Permissions,
    Loading,
    Multiselect
  },
  props: ["user","super-admin","company-admin"],
computed: {
    ...mapGetters(["deliveryAccounts","currentUser"]),
  },
  beforeMount()
  {
    this.getPermissions();
    if ((!this.currentUser?.planDetails?.price == 0) || this.superAdmin) {
      this.getDeliveryAccounts();
    }
  },
  created() {
   
     this.firstName = this.user.firstName
    this.lastName = this.user.lastName
    this.email = this.user.email
    this.accountId = this.user.deliveryAccounts
    this.selectedUserId = this.user.id
    this.selectedUserDetailsID =this.user.userdetails_id
    this.permissions = this.user.permissionIds
    this.accounts.value = this.accountId.map(account=>{
                  return account.value
                        })
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      firstName: "",
      lastName: "",
      email: "",
      accountId: "",
      selectedUserId:'',
      selectedUserDetailsID:'',
      submitted: false,
      permissions:[],
       accountsSelected: [],
      //for the multiselect tag
      accounts: {
        value: [],
        //options object must be in "value", "label" format ; 'label' gets displayed as options
        //eg options:[  {value:'some value1',label:'text1'} , {value:'some value 2',label:'text2'}]
        options: []
      },

    };
  },

  methods: {
    getDeliveryAccounts: function() {
          let body = {
            
            userdetails_id:
                this.companyAdmin ?
                this.companyAdmin
                :
                this.$cookie.getCookie("userdetails_id")
          };
          this.$store
            .dispatch(GET_DELIVERY_ACCOUNTS, body)
            .then((data) => {
              this.isLoading = false;
              if (data.status == "Failure") {
                this.$toast.show(data.message, {
                  type: "error ",
                  position: "top-right",
                });
              } else {
                //populating multiselect dropdown
                 this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);

                this.accounts.options = data.data.map(account=>{
                  return{
                    "value":account.Deliveryaccount,
                    'values':
                        {
                          "id":account.Deliveryaccount_id,
                          "value":account.Deliveryaccount,
                          "accountAlias":account.Deliveryaccount_Alias
                        },
                    "label":account.Deliveryaccount_Alias ?
                            account.Deliveryaccount +" - "+ account.Deliveryaccount_Alias
                            :
                            account.Deliveryaccount
                        }
                        })
              }
            })
            .catch(() => {
              this.isLoading = false;
            });
        },
        onSelect(optionValue)
        {
         this.accountsSelected = optionValue;
        },
    getPermissions: function () {
       let body = {
  userdetails_id:this.user.userdetails_id
};
      this.$store
        .dispatch(LISTUSER_PERMISSIONS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.permissions = data.data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    isEmailValid: function () {
      const emailRegex = /\S+@\S+\.\S+/;
      return this.email == ""
        ? false
        : emailRegex.test(this.email)
        ? true
        : false;
    },
    onChange(e) {
      if (e.target.name == "firstName") {
        this.firstName = e.target.value;
      } else if (e.target.name == "lastName") {
        this.lastName = e.target.value;
      } else if (e.target.name == "email") {
        this.email = e.target.value;
      } else if (e.target.name == "accountId") {
        this.accountId = e.target.value;
      }
    },

   submitInAddUsers(permissions) {
      this.isLoading = true;
      this.submitted = true;

      let assignedDeliveryAccounts = [];
      let selectAccounts = document.getElementById("userDeliveryAcc");
     
      this.accountsSelected = this.accounts.value; // accounts selected - if no change done to multiselect, accountsSelected will be null -
      this.accounts.options.map(option=>
      {
        this.accountsSelected.map(optionValue=>
        {
          if(optionValue == option.value)
          {
            assignedDeliveryAccounts.push(option.values)
          }
        })
      })
      

      if (!this.firstName || !this.lastName ||
      !this.isEmailValid()||
        assignedDeliveryAccounts.length < 1
      ) {
        this.isLoading = false;
        if (assignedDeliveryAccounts.length < 1) {
          selectAccounts.focus();
        }
      } else {
        this.$parent.$emit("colse-modal");
       
        this.createNewUser(permissions, assignedDeliveryAccounts);
      }
    },
    createNewUser(permissions, accounts) {
      let body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        
        companyId: this.user.companyId,
        deliveryAccountId: this.accountId,
        permissionIds: permissions,
        userId: this.selectedUserId,
        deliveryAccounts: accounts,
        userdetails_id:this.selectedUserDetailsID
      };
     
      this.$store
        .dispatch(EDIT_CORPORATE_USER, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });

// Update the list
            let body = {
             
              companyId: this.user.companyId
            };
            this.$store
              .dispatch(GET_CORPORATE_USERS, body)
              .then((data) => {
                this.isLoading = false;
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.accountId = "";
                this.submitted = false;
                   this.selectedUserDetailsID ="";
                this.permissionIds = [];
                if (data.status == "Failure") {
                  this.$toast.show(data.message, {
                    type: "error ",
                    position: "top-right",
                  });
                }
              })
              .catch(() => {
                this.isLoading = false;
              });

          }
        })
        .catch((error) => {
          this.$toast.show(error.message, {
            type: "error ",
            position: "top-right",
          });
          this.isLoading = false;
        });
    },
  },
};
</script>