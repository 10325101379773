<template>
    <div id="new-user-section">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>

    <div class="form-row">
      <div class="col">
        <label>Discount %</label>
        <input
          type="text"
          placeholder="Discount %"
          class="form-control"
          name="discount"
          v-model="discount"
          @change="onChange"
        />
        <p>
          <small class="text-danger" v-if="!discount && submitted"
            >Discount % is required</small
          >
        </p>
      </div>
       <div class="col">
        <label>Duration in months</label>
        <input
          type="text"
          placeholder="Duration in months"
          class="form-control"
          name="durationInMonths"
          v-model="durationInMonths"
          @change="onChange"
        />
        <p>
          <small class="text-danger" v-if="!durationInMonths && durationPattern=='repeating' && submitted"
            >Coupon Duration required</small
          >
        </p>
      </div>
      <div class="col">
        <label>Duration</label>
        <select
            id="selectCompany"
            class="form-control"
            v-model="durationPattern"
            name="duration"
            @change="onChange"
          >
        <option :selected="true" disabled
        >Select Duration</option
        >
        <!-- v-bind:key="currency.companyId" -->
        <option
          v-for="duration in durations"
          v-bind:key="duration"
          v-bind:value="duration"
        >
          {{ duration.toUpperCase() }}
        </option>
        </select>
        <p>
          <small class="text-danger" v-if="durationPattern=='Select Duration' && submitted"
            >Duration is required</small
          >
        </p>
      </div>
    </div>
    <button
      class="btn theme-btn text-light btn-sm"
      type="submit"
      style="margin-top: 20px"
      v-on:click="submitClick"
      :disabled="!discount || !durationPattern"
    >
      Save
    </button>
    </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import { CREATE_COUPON, LIST_COUPONS } from "@/store/actions.type";
export default{
    name:'EditCoupon',
    components:{
        Loading
    },
    data(){
        return {
            durations:['forever','once','repeating'],
            durationPattern:"Select Duration",
            discount:'',
            durationInMonths:'',
            submitted: false,
            isLoading:false
        }
    },
    methods:
    {
        onChange(e)
        {
            if(e.target.name=="discount")
            {
                this.discount = e.target.value
            }
            else if(e.target.name == "durationInMonths")
            {
                this.durationInMonths = e.target.value
            }
            else if(e.target.name == "duration")
            {
                this.durationPattern = e.target.value
            }
            this.checkNumber(e.target.value)
        },
        checkNumber(value)
        {
            let pattern = /^[0-9]+$/
            return pattern.test(value)
        },
         submitClick(){
        this.submitted = true
        if(!this.checkNumber(this.discount))
        {
            this.$toast.show("Enter Valid Discount %", {
                type: "error ",
                position: "top-right",
                });
        }
        else if(this.discount > 100 )
            {
                this.$toast.show("Enter Valid discount %", {
                type: "error ",
                position: "top-right",
                });
            }
        else if(this.durationPattern == "Select Duration")
        {
            this.$toast.show("Please Select Duration", {
                type: "error ",
                position: "top-right",
                });
        }
        else
        {
            if(this.durationPattern =='repeating' && !this.checkNumber(this.durationInMonths))
            {
               this.$toast.show("Enter Valid Duration in Months", {
                type: "error ",
                position: "top-right",
                });
            }
            else
            {
                this.isLoading = true
                this.createCoupon();
            }
        }
         },
         createCoupon()
         {
          this.isLoading = true
             let body = {
                     percent_off: this.discount,
                     duration_in_months: this.durationPattern == 'repeating' ? this.durationInMonths : 0,
                     duration: this.durationPattern
                 }
                 this.$store.dispatch(CREATE_COUPON,body)
                 .then((data)=>
                 {
                     this.isLoading = false;
                    if (data.status == "failure") {
                        this.$toast.show(data.message, {
                        type: "error ",
                        position: "top-right",
                        });
                    } else {
                      this.isLoading = true;
                        this.$toast.show(data.message, {
                        type: "success ",
                        position: "top-right",
                        });
                        this.$parent.$emit("colse-modal");
                        this.$store
                        .dispatch(LIST_COUPONS,{offset:0, limit:20, search:''})
                        .then(data=>
                        {
                            this.isLoading = false;
                            if (data.status == "failure") {
                                this.$toast.show(data.message, {
                                type: "error ",
                                position: "top-right",
                                });
                                }
                        })
                        .catch(()=>
                        {
                            this.isLoading = false;
                            this.$toast.show("Oops..Something went wrong!", {
                                type: "error ",
                                position: "top-right",
                                });
                        })
                    }
                 })
                 .catch(()=>
                        {
                            this.isLoading = false;
                            this.$toast.show("Oops..Something went wrong!", {
                                type: "error ",
                                position: "top-right",
                                });
                        })
                this.submitted= false
         }
    }
}
</script>