<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <div class="d-flex col-lg-7 justify-content-center p-2">
                  <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                  </div>
                  <!-- <div class="flex-grow-1 bg-login-image logo" :style="{ backgroundImage: 'url(' + require('@/assets/img/oneNotice/oneNoticeLogo.png') + ')' }"/> -->
                </div>
                <div class="col-lg-6">
                  <div class="p-3">
                    <div class="text-center">
                      <h4 class="text-dark mb-4">Create an Account!</h4>
                    </div>
                    <form
                      class="user"
                      @submit.prevent="
                        submit(
                          firstName,
                          lastName,
                          email,
                          password,
                          repeatPassword,
                          companyName
                        )
                      "
                    >
                      <div class="form-group row mb-0 mb-sm-0">
                        <div class="col-sm-6">
                          <input
                            v-bind:class="{
                              'is-invalid': !firstName && submitted,
                            }"
                            class="form-control form-control-user"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            v-on:change="onChange"
                            v-model.trim="firstName"
                          />
                          <p>
                            <small
                              class="text-danger"
                              v-if="!firstName && submitted"
                              >Valid first name is required</small
                            >
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <input
                            class="form-control form-control-user"
                            type="text"
                            v-bind:class="{
                              'is-invalid': !lastName && submitted,
                            }"
                            v-on:change="onChange"
                            placeholder="Last Name"
                            name="last_name"
                            v-model.trim="lastName"
                          />
                          <p>
                            <small
                              class="text-danger"
                              v-if="!lastName && submitted"
                              >Valid last name is required</small
                            >
                          </p>
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          type="email"
                          v-bind:class="{
                            'is-invalid': !isEmailValid() && submitted,
                          }"
                          v-on:change="onChange"
                          aria-describedby="emailHelp"
                          placeholder="Email Address"
                          name="email"
                          v-model.trim="email"
                        />
                        <p>
                          <small
                            class="text-danger"
                            v-if="!isEmailValid() && submitted"
                            >Valid email id is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          type="text"
                          v-bind:class="{
                            'is-invalid': !companyName && submitted,
                          }"
                          v-on:change="onChange"
                          aria-describedby="companyHelp"
                          placeholder="Company Name"
                          name="companyName"
                          v-model.trim="companyName"
                        />
                        <p>
                          <small
                            class="text-danger"
                            v-if="!companyName && submitted"
                            >Valid Company Name is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <div style="position: relative">
                            <input
                              class="form-control form-control-user"
                              :class="{ 'is-invalid': !password && submitted }"
                              :type="showPassword1 ? 'text' : 'password'"
                              maxlength="20"
                              v-on:change="onChange"
                              placeholder="Password"
                              name="password"
                              v-model.trim="password"
                            />
                            <em
                              v-show="!showPassword1 && password"
                              @click="togglePasswordVisibility1"
                              class="fas fa-eye fa-sm fa-fw text-black-400"
                              style="
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translate(0, -50%);
                              "
                            ></em>
                            <em
                              v-show="showPassword1 && password"
                              @click="togglePasswordVisibility1"
                              class="fas fa-eye-slash fa-sm fa-fw text-black-400"
                              style="
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translate(0, -50%);
                              "
                            ></em>
                          </div>
                          <p>
                            <small
                              class="text-danger"
                              v-if="!password && submitted"
                              >Valid password is required.
                            </small>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <div style="position: relative">
                            <input
                              class="form-control form-control-user"
                              :class="{
                                'is-invalid': !repeatPassword && submitted,
                              }"
                              :type="showPassword2 ? 'text' : 'password'"
                              maxlength="20"
                              v-on:change="onChange"
                              placeholder="Repeat Password"
                              name="password_repeat"
                              v-model.trim="repeatPassword"
                            />
                            <em
                              v-show="!showPassword2 && repeatPassword"
                              @click="togglePasswordVisibility2"
                              class="fas fa-eye fa-sm fa-fw text-black-400"
                              style="
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translate(0, -50%);
                              "
                            ></em>
                            <em
                              v-show="showPassword2 && repeatPassword"
                              @click="togglePasswordVisibility2"
                              class="fas fa-eye-slash fa-sm fa-fw text-black-400"
                              style="
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translate(0, -50%);
                              "
                            ></em>
                          </div>
                          <p>
                            <small
                              class="text-danger"
                              v-if="!repeatPassword && submitted"
                              >Valid password is required.
                            </small>
                          </p>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary btn-block text-white btn-user register-btn mx-0 border-0"
                        type="submit"
                        :disabled="submitStatus === 'PENDING'"
                      >
                        Register Account
                      </button>

                      <hr />
                      <hr />
                    </form>
                    <div class="text-center">
                      <router-link to="/" class="small"
                        >Already have an account? Login!</router-link
                      >
                    </div>
                    <div class="text-center small">
                      By clicking Register account, I argee to the
                      <router-link to="/terms">Terms of Service</router-link>
                      and
                      <router-link to="/privacy">Privacy Policy</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";
import { VALIDATE } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { validatePassword } from "../store/modules/rejex.module";
export default {
  name: "Create Account",
  components: {
    Loading,
  },
  methods: {
    handleBlur(key) {
      this.$v[key].$dirty = true;
    },
    togglePasswordVisibility1() {
      this.showPassword1 = !this.showPassword1;
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
    },

    isEmailValid: function () {
      const emailRegex = /\S+@\S+\.\S+/;
      return this.email == ""
        ? false
        : emailRegex.test(this.email)
        ? true
        : false;
    },
    submit(firstName, lastName, email, password, repeatPassword, companyName) {
      this.isLoading = true;
      this.submitted = true;
      if (
        !this.password ||
        !this.repeatPassword ||
        !this.isEmailValid() ||
        !this.repeatPassword ||
        !this.firstName ||
        !this.lastName ||
        !this.companyName
      ) {
        this.submitStatus = "ERROR";
        this.isLoading = false;
      } else {
        if (this.password.trim() == this.repeatPassword.trim()) {
          //Your password must contain at least one number and
          //      one uppercase and lowercase letter and
          //      one special character, and
          //      at least 8 or more characters and
          //      no space allowed
          if (validatePassword(this.password)) {
            this.onValidateAccount(
              firstName,
              lastName,
              email,
              password,
              repeatPassword,
              companyName
            );
          } else {
            this.isLoading = false;
            this.submitStatus = "ERROR";
            this.$toast.show(
              "Your password must contain at least 8 characters, one number, one uppercase letter, one lowercase letter, and one special character. No space is allowed.",
              {
                type: "error ",
                position: "top-right",
              }
            );
          }
        } else {
          this.isLoading = false;
          this.submitStatus = "ERROR";
          this.$toast.show("Password and repeat-password do not match", {
            type: "error",
            position: "top-right",
          });
        }
      }
    },
    onValidateAccount(
      firstName,
      lastName,
      email,
      password,
      repeatPassword,
      companyName
    ) {
      this.$store
        .dispatch(VALIDATE, {
          firstName,
          lastName,
          email,
          password,
          repeatPassword,
          companyName,
        })
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$router.push({ name: "ChooseAccountTypes" });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast.show(err.message, {
            type: "error ",
            position: "top-right",
          });
        });
    },
    onChange(e) {
      //this.submitted = false;
      if (e.target.name == "email") {
        this.email = e.target.value;
      } else if (e.target.name == "password") {
        this.password = e.target.value;
      } else if (e.target.name == "repeatPassword") {
        this.repeatPassword = e.target.value;
      } else if (e.target.name == "firstName") {
        this.firstName = e.target.value;
      } else if (e.target.name == "lastName") {
        this.lastName = e.target.value;
      } else if (e.target.name == "companyName") {
        this.companyName = e.target.value;
      }
    },
  },
  computed: {
    users() {
      return this.$store.state.user;
    },
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      email: null,
      password: null,
      showPassword1: false,
      showPassword2: false,
      firstName: null,
      lastName: null,
      repeatPassword: null,
      submitStatus: null,
      companyName: null,
    };
  },
  validations: {
    email: { required, email },
    password: { required },
    firstName: { required },
    lastName: { required },
    repeatPassword: { required },
    companyName: { required },
  },
};
</script>

<style scoped>
.backdrop {
  background: transparent;
  height: 100%;
}
.logo {
  height: 60px;
  align-self: center;
}
.back {
  margin: 0px;
  padding: 20px;
  margin-right: 0px;
}
.image-left {
  width: 100%;
  height: auto;
  place-self: center;
  padding-left: 20px;
  align-items: center;
  align-self: center;
}
.image-box {
  display: grid;
}
.register-btn {
  background-color: #00a5b1bd;
}
</style>
