import { Service, post } from "@/api/service";
import {
    GET_ACCOUNTS,
    LIST_SUBSCRIPTIONS_TIER,
    ADD_SUBSCRIPTIONS_TIER,
    EDIT_SUBSCRIPTIONS_TIER,
    UPDATE_ACCOUNT_STATUS,
    LIST_PAYMENTS,
    LIST_COUPONS,
    DELETE_COUPON,
    CREATE_COUPON,
    ADD_PROMO_CODE
} from "../actions.type";
import { SET_ERROR, SET_SUBSCRIPTIONS_TIER,SET_COUPONS } from "../mutations.type";

const state = {
    errors: null,
    accountTypes: '',
    tiers: '',
    coupons: null,
    couponCount: null
};

const getters = {
    getAccountTiers(state) {
        return state.tiers;
    },
    getCoupons(state)
    {
        return state.coupons;
    },
    couponCount(state)
    {
        return state.couponCount;
    }
}

const actions = {
    [GET_ACCOUNTS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/listAccounts", body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [LIST_SUBSCRIPTIONS_TIER](context) {
        return new Promise((resolve, reject) => {
            Service.get("v2/listSubscriptionTiers")
                .then((data) => {
                    if (data.status == 'failure') {
                        reject(data)
                    } else {
                        resolve(data);
                        context.commit(SET_SUBSCRIPTIONS_TIER, data.data)
                    }
                }).catch((response) => {
                    reject(response)
                });
        })
    },
    [UPDATE_ACCOUNT_STATUS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/changeCorporateStatus", body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },

    [ADD_SUBSCRIPTIONS_TIER](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/addSubscriptionTier", body)
                .then((data) => {
                   checkFailureStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [EDIT_SUBSCRIPTIONS_TIER](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/editSubscriptionTier", body)
                .then((data) => {
                   checkFailureStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [LIST_PAYMENTS](context, body) {
        return new Promise((resolve, reject) => {
            //listPayments
            post("v2/getPaymentDetails", body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [LIST_COUPONS](context,body) {
        return new Promise((resolve, reject) => {
            Service.post("v2/list_coupons",body)
                .then((data) => {
                    if (data.status == 'failure') {
                        reject(data)
                    } else {
                        resolve(data);
                        context.commit(SET_COUPONS, data.data)
                    }
                }).catch((response) => {
                    reject(response)
                });
        })
    },
    [CREATE_COUPON](context,body)
    {
        return new Promise((resolve, reject) => {
            Service.post("v2/create_coupon",body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                }).catch((response) => {
                    reject(response)
                });
        })
    },
    [DELETE_COUPON](context,body){
        return new Promise((resolve, reject) => {
           post("v2/delete_coupon",body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                }).catch((response) => {
                    reject(response)
                });
        }) 
    },
    [ADD_PROMO_CODE](context,body){
        return new Promise((resolve, reject) => {
           post("v2/add_premocode",body)
                .then((data) => {
                    checkFailureStatus(data,resolve,reject)
                }).catch((response) => {
                    reject(response)
                });
        }) 
    }
};
function checkFailureStatus(data,resolve,reject)
{  
    if (data.status == 'failure') {
        reject(data)
    } else
        resolve(data);
}
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_SUBSCRIPTIONS_TIER](state, tiers) {
        state.tiers = tiers;
        state.errors = {};
    },
    [SET_COUPONS](state, coupons) {
        state.coupons = coupons.resp;
        state.couponCount = coupons.totalcount
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};