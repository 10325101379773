import {

    TWO_FACTOR_AUTHENTICATION,
    VERIFY_OTP,
    GENETATE_EMAL_OTP
} from "../actions.type";
import { SET_ERROR } from "../mutations.type";
import { post } from "@/api/service";
//Service
const state = {
    errors: null,
    accountTypes: '',
};

const actions = {

    [TWO_FACTOR_AUTHENTICATION](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/twoFactorAuthentication", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },
    [VERIFY_OTP](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/verifyOtp", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },
    [GENETATE_EMAL_OTP](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/generateEmailOtp", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
};