<template>
  <div class="container-fluid">
    <Loading :active="isLoading" :can-cancel="true"></Loading>
    <h3 class="text-dark mb-4">
      Profile:
      {{
        currentUser.companyName ? currentUser.companyName : currentUser.userRole
      }}
    </h3>
    <div class="row mb-3">
      <div class="col-lg-5">
        <div class="card mb-3">
          <div class="card-body text-center shadow">
            <img
              v-if="
                currentUser.profileImage ||
                selectedUser.profilePhoto ||
                profileImageItem
              "
              class="rounded-circle mb-3 mt-4"
              v-bind:src="
                fromSuperAdmin
                  ? selectedUser.profilePhoto
                    ? selectedUser.profilePhoto
                    : require('../../assets/img/avatars/avatar1.png')
                  : getS3Image
              "
              width="160"
              height="160"
              alt="image"
            />
            <!-- <img
              v-else
              class="rounded-circle mb-3 mt-4"
              src="../../assets/img/avatars/avatar1.png"
              width="160"
              height="160"
              alt="profile"
            /> -->
            <div v-else class="profile-default text-center mx-auto">
              <p class="profile-default-p">
                {{ profileDefault }}
              </p>
            </div>

            <div class="col">
              <div class="row justify-content-center">
                <label class="col-form-label" for="first_name"
                  ><strong
                    >{{
                      fromSuperAdmin
                        ? selectedUser.fullName
                        : currentUser.firstName
                    }}
                    {{ fromSuperAdmin ? "" : currentUser.lastName }}</strong
                  ></label
                >
              </div>
            </div>
            <div class="col justify-content-center">
              <label class="col-form-label" for="username"
                ><strong>{{
                  fromSuperAdmin ? selectedUser.email : currentUser.email
                }}</strong></label
              >
            </div>
            <!-- company name -->
            <div
              class="col justify-content-center"
              v-if="fromSuperAdmin || currentUser.userRole != 'Super Admin'"
            >
              <label class="col-form-label" for="companyname"
                ><strong>
                  {{
                    currentUser.companyName
                      ? currentUser.companyName
                      : selectedUser.companyName
                      ? selectedUser.companyName
                      : ""
                  }}
                  {{
                    currentUser.companyAlias
                      ? "(" + currentUser.companyAlias + ")"
                      : ""
                  }}
                </strong></label
              >
            </div>

            <div
              v-if="currentUser.userRole == 'Super Admin'"
              class="col justify-content-center"
            >
              <a
                class="btn theme-btn text-light btn-sm"
                role="button"
                v-on:click="onResetPassword()"
                >Reset Password</a
              >
            </div>

            <div class="mb-3" v-if="!fromSuperAdmin">
              <input
                id="fileUpload"
                type="file"
                accept="image/*"
                @change="uploadImage"
                hidden
              />

              <button
                class="btn theme-btn text-light btn-sm"
                type="button"
                style="margin-top: 10px"
                @click="onSelectPhoto()"
              >
                Choose Photo
              </button>
            </div>
            <my-upload
              field="img"
              @crop-success="cropSuccess"
              v-model="showCropModel"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              :width="100"
              :height="100"
              url=""
              lang-type="en"
              noCircle="true"
              :params="params"
              :headers="headers"
              img-format="png"
            ></my-upload>
          </div>
        </div>
        <div
          class="card shadow mb-5"
          v-if="fromSuperAdmin || currentUser.userRole != 'Super Admin'"
        >
          <div
            v-if="canCreateUser || currentUser.userRole == 'Corporate Admin'"
            class="card-body"
          >
            <AddUsers
              v-bind:company-id="profileId"
              v-bind:super-admin="fromSuperAdmin"
              v-bind:company-admin-id="selectedUser.userdetails_id"
            />
          </div>
          <div class="card-body">
            <ExistingUserProfiles
              v-bind:company-id="profileId"
              v-bind:super-admin="fromSuperAdmin"
              v-bind:company-admin-id="selectedUser.userdetails_id"
            />

            <div v-if="fromSuperAdmin">
              <h2
                class="text-primary card-subtitle small font-weight-bold"
                style="padding: 3px; font-size: 16px"
              >
                Add Additional Users&nbsp;
              </h2>
              <label
                for="email"
                style="margin-left: 0px; padding: 0px; margin-right: 25px"
                ><strong>Number of additional user accounts to add</strong><br
              /></label>
              <input
                type="number"
                placeholder="0"
                name="Count"
                v-model.trim="userCount"
              />
              <h2
                class="text-primary mt-auto card-subtitle small font-weight-bold mt-5"
                style="
                  padding: 3px;
                  font-size: 16px;
                  margin-top: 16px !important;
                "
              >
                Add Additional Account IDs
              </h2>
              <label
                for="email"
                style="margin-left: 0px; padding: 0px; margin-right: 25px"
                ><strong>Number of additional account IDs to add</strong><br
              /></label>
              <input
                type="number"
                placeholder="0"
                name="Count"
                v-model.trim="deliveryCount"
              />
              <div class="form-group" style="border-color: var(--blue)">
                <button
                  class="btn theme-btn text-light btn-sm mt-3"
                  type="submit"
                  v-on:click="updateAccountLimits"
                >
                  Save Settings
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row" v-if="currentUser.userRole == 'Corporate Admin'">
          <div class="col">
            <div class="card shadow mb-3">
              <div class="card-header theme-area-head py-3">
                <p class="text-white m-0 font-weight-bold">Plan Details</p>
              </div>
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col text-center">
                    <h3 class="m-0 p-1 plan-theme">
                      <strong>
                        {{ planDetails.planName.toUpperCase() }}
                      </strong>
                    </h3>
                    <strong>
                      <p class="m-0 p-1">
                        Notice Count : {{ planDetails.noticeCount }}
                      </p>
                      <p class="m-0 p-1">
                        Attachment Count : {{ planDetails.attachmentCount }}
                      </p>
                      <p class="m-0 p-1">
                        User Count : {{ planDetails.userCount }}
                      </p>
                    </strong>
                  </div>
                  <div class="col text-center my-auto">
                    <h3 class="m-0 p-2 plan-price">
                      <strong>$ {{ planDetails.price / 100 }} </strong>
                    </h3>

                    <a
                      class="btn btn-sm theme-btn text-light border-0"
                      role="button"
                      v-on:click="viewSubscription"
                      >View / Cancel Subscription</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 17px">
          <DeliveryAccounts
            v-bind:company-id="profileId"
            v-bind:super-admin="fromSuperAdmin"
            v-bind:selected-user="selectedUser"
            @updateDeliveryList="updateListOfDeliveryItems($event)"
          />
        </div>
        <div
          class="row"
          v-if="currentUser.userRole == 'Corporate Admin' || fromSuperAdmin"
        >
          <div class="col">
            <div class="card shadow mb-3">
              <div class="card-header theme-area-head py-3">
                <p class="text-white m-0 font-weight-bold">
                  Authentication Settings
                </p>
              </div>
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col text-start">
                    <strong>
                      <input
                        type="checkbox"
                        name="TwoFactorStatus"
                        id="TwoFactorStatus"
                        value="TwoFactorStatus"
                        v-model="TwoFactorStatus"
                        @change="onChangeTwoFactorStatus($event)"
                      />
                      &nbsp; Enable Two Factor Authentication
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition
      name="resetModal"
      v-if="showResetModal"
      v-on:colse-modal="modalResetHide"
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  v-on:click="modalResetHide"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <ResetPassword
                  v-bind:userId="profileId"
                  v-bind:fromSuper="fromSuperAdmin"
                  v-bind:selectedUser="selectedUser.user_id"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AddUsers from "./AddUsers.vue";
import Loading from "vue3-loading-overlay";
import VueImageCropUpload from "vue-image-crop-upload/upload-3.vue";
import JwtService from "@/api/jwt.service";

import {
  UPLOAD_PROFILE_IMAGE,
  GET_USER_DETAILS,
  UPDATE_ACCOUNT_LIMITS,
  GET_DELIVERY_ACCOUNTS,
  GET_IMAGE,
  VIEW_SUBSCRIPTION,
  UPDATE_2FA,
  GET_COMPANY_TWO_FACTOR,
} from "@/store/actions.type";

import ExistingUserProfiles from "./ExistingUserProfiles.vue";
import { mapGetters } from "vuex";
import DeliveryAccounts from "./DeliveryAccounts";
import encrypter from "object-encrypter";
import ResetPassword from "./ResetPassword";
var engine = encrypter(process.env.VUE_APP_EK, { ttl: true });

export default {
  name: "ProfileContent",
  computed: {
    ...mapGetters([
      "currentUser",
      "listPermission",
      "getS3Image",
      "planDetails",
    ]),
    profileDefault() {
      let initials = "";
      this.fromSuperAdmin
        ? (initials = this.selectedUser.fullName[0])
        : this.currentUser.lastName[0]
        ? (initials =
            this.currentUser.firstName[0] + "." + this.currentUser.lastName[0])
        : (initials = this.currentUser.firstName[0]);
      return initials;
    },
  },
  en: {
    hint: "Click or drag the file here to upload",
    loading: "Uploading…",
    noSupported: "Browser is not supported, please use IE10+ or other browsers",
    success: "Upload success",
    fail: "Upload failed",
    preview: "Preview",
    btn: {
      off: "Cancel",
      close: "Close",
      back: "Back",
      save: "Save",
    },
    error: {
      onlyImg: "Image only",
      outOfSize: "Image exceeds size limit: ",
      lowestPx: "Image's size is too low. Expected at least: ",
    },
  },

  components: {
    Loading,
    AddUsers,
    ExistingUserProfiles,

    DeliveryAccounts,
    ResetPassword,
    "my-upload": VueImageCropUpload,
  },
  data() {
    return {
      userId: "",
      isLoading: false,
      previewImage: null,
      permissionsList: "",
      canCreateUser: false,
      selectedUser: "",
      deliveryCount: 0,
      setProfileImage: false,
      userCount: 0,
      showResetModal: false,
      showCropModel: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        Authorization: JwtService.getToken(),
      },
      imgDataUrl: "",
      profileImageItem: false,
      TwoFactorStatus: false,
    };
  },
  props: ["profile-id", "from-super-admin"],

  beforeMount() {
    this.profileImageItem = false;
    this.imgDataUrl = "";
    this.setPermissions();
    if (this.fromSuperAdmin) {
      this.getUserDetails();
    } else {
      this.getTwoFactorStatus();
    }
    this.getProfileImage();
  },
  methods: {
    updateListOfDeliveryItems(deliveryAccountsArray) {
      this.$emit("updateDeliveryList", deliveryAccountsArray);
    },
    viewSubscription() {
      this.isLoading = true;
      let body = {
        companyId: this.$cookie.getCookie("COMPANY_ID")
          ? this.$cookie.getCookie("COMPANY_ID")
          : this.companyId,
      };

      this.$store
        .dispatch(VIEW_SUBSCRIPTION, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show("Redirecting to STRIPE...", {
              type: "warning",
              position: "top-right",
            });
            if (data && data.data) {
              window.location.href = data.data;
            }
          }
        })
        .catch(() => {
          this.$toast.show("oops, something went wrong! Please Try Again.", {
            type: "error ",
            position: "top-right",
          });
        });
    },
    getProfileImage() {
      this.profileImageItem = false;
      let body = {
        userId: this.currentUser.userId,
      };
      this.$store
        .dispatch(GET_IMAGE, body)
        .then((data) => {
          this.isLoading = false;

          if (data.status == "failure") {
            this.profileImageItem = false;
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            if (data.data.profileImage) {
              this.profileImageItem = true;
            }
            this.currentUser.profileImage = data.data.profileImage;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    modalResetHide: function () {
      this.showResetModal = false;
    },
    onResetPassword() {
      this.showResetModal = true;
      this.userId = this.profileId;
    },
    updateAccountLimits() {
      let body = {
        companyId: this.profileId,
        deliveryCount: this.deliveryCount,
        userCount: this.userCount,
      };
      if (this.deliveryCount < 0) {
        this.$toast.show("Please choose a valid delivery count", {
          type: "error ",
          position: "top-right",
        });
        return;
      } else if (this.userCount < 0) {
        this.$toast.show("Please choose a valid user count", {
          type: "error ",
          position: "top-right",
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_ACCOUNT_LIMITS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.getDeliveryAccounts();
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDeliveryAccounts: function () {
      let body = {
        userdetails_id: this.fromSuperAdmin
          ? this.selectedUser.userdetails_id
          : this.$cookie.getCookie("userdetails_id"),
      };

      this.$store
        .dispatch(GET_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getUserDetails() {
      let body = {
        companyId: this.profileId,
      };

      this.$store
        .dispatch(GET_USER_DETAILS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.selectedUser = data?.data;
            this.selectedUser.companyId = data?.companyId;
            this.getTwoFactorStatus();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectPhoto() {
      this.showCropModel = !this.showCropModel;
    },
    setPermissions() {
      this.listPermission.map((item) => {
        if (item.permissionType == "Account Settings") {
          this.canCreateUser = true;
        }
      });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.currentUser.profileImage = e.target.result;

        this.onUploadImage(image);
      };
    },

    async onUploadImage(image) {
      this.isLoading = true;
      let body = {
        userId: this.currentUser.userId,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        username: this.currentUser.username,
      };
      var hex = engine.encrypt(body, 1000000);
      let formData = new FormData();
      formData.append("data", hex);
      formData.append("profileImage", image);

      this.$store
        .dispatch(UPLOAD_PROFILE_IMAGE, formData)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.getProfileImage();
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      let arr = imgDataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let filename = "profile" + Math.floor(Date.now() / 1000) + ".png";
      let file = new File([u8arr], filename, { type: mime });

      this.onUploadImage(file);
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    //--cropUploadSuccess(jsonData, field) {

    // },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    //--cropUploadFail(status, field) {

    //--},

    getTwoFactorStatus() {
      let body;
      if (this.$cookie.getCookie("COMPANY_ID")) {
        body = { companyid: this.$cookie.getCookie("COMPANY_ID") };
      } else {
        if (this.selectedUser.companyId) {
          body = { companyid: this.selectedUser.companyId };
        } else {
          return;
        }
      }

      this.$store
        .dispatch(GET_COMPANY_TWO_FACTOR, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            if (data.status == "success") {
              if (data.data.isTwofact == "active") {
                this.TwoFactorStatus = true;
              } else {
                this.TwoFactorStatus = false;
              }
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    updateTwoFatorStatus() {
      let userdetailsId, userId;
      if (this.currentUser.userRole == "Super Admin") {
        if (this.selectedUser) {
          userdetailsId = this.selectedUser.userdetails_id;
          userId = this.selectedUser.user_id;
        } else {
          return;
        }
      } else {
        userdetailsId = this.$cookie.getCookie("userdetails_id");
        userId = this.$cookie.getCookie("USER_ID");
      }
      let body = {
        userId: userId,
        companyId: this.$cookie.getCookie("COMPANY_ID")
          ? this.$cookie.getCookie("COMPANY_ID")
          : this.selectedUser.companyId
          ? this.selectedUser.companyId
          : 1,
        userdetails_id: userdetailsId,
      };

      this.$store
        .dispatch(UPDATE_2FA, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            if (data.status == "success") {
              this.getTwoFactorStatus();
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onChangeTwoFactorStatus(e) {
      if (e.target.name == "TwoFactorStatus") {
        this.updateTwoFatorStatus();
      }
    },
  },
};
</script>

<style scoped>
.profile-default {
  border: 2px solid #d14f52;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  font-size: 60px;
  font-weight: bolder;
  background-color: #27588d;
  color: #fff;
}
.profile-default-p {
  text-align: center;
  line-height: 150px;
}
.plan-theme {
  color: #27588d;
}
.plan-price {
  color: #d14f52;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
</style>
