<template>
  <div style="background:white">
    <Loading :active="isLoading" :can-cancel="true">
      <h3>Generating report...</h3>
    </Loading>
    <div id="report">
      
    <div id="content">
<!-- added for the print window -->
      <div class="heading">
      <div class="col-4 d-flex">
        <div class="image-box">
            <img
              src="../assets/img/oneNotice/oneNoticeLogo.png"
              style="width: 35%"
              alt="oneNoticeLogo"
              />
        </div>
      </div>
      <div class="col-8 d-flex">
        <h1 style="margin: 0">
          <strong>      
            
            {{ fromDate && toDate ? 
                 ( fromDate == toDate ? 
                  fromDate.split(" ")[0] : 
                  fromDate.split(" ")[0] +' to '+ toDate.split(" ")[0] ) 
                :
                   moment(new Date()).format('MMMM YYYY')
            }}
          </strong>
        </h1>
      </div>
      </div>
<!-- added for the print window :end -->
      <div class="container-fluid">
        <div class="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 class="text-dark mb-0" style="margin-top:20px">
            Dashboard - Account:
            {{
              currentUser.companyName ? currentUser.companyName : userRole 
            }}
           
          </h3>
        </div>
        <div class="row">
          <div class="col-md-6 col-xl-6 mb-4">
            <div class="card shadow border-left-primary py-2">
              <div class="card-body">
                <div class="row align-items-center no-gutters">
                  <div class="col mr-2">
                    <div
                      class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                      style="font-size: 16px"
                    >
                    <!--  THIS MONTH, +RECEIVED -->
                      <span style="font-size: 14px">
                        {{
                          currentUser.userRole != "Super Admin" || !isDefault
                            ? "NOTICES RECEIVED"
                            : "TOTAL NOTICES"
                        }}</span
                      >
                    </div>
                    <div class="text-dark font-weight-bold h5 mb-0">
                      <span>{{ receivedCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentUser.userRole != 'Super Admin' || !isDefault"
            class="col-md-6 col-xl-6 mb-4"
          >
            <div class="card shadow border-left-success py-2">
              <div class="card-body">
                <div class="row align-items-center no-gutters">
                  <div class="col mr-2">
                    <div
                      class="text-uppercase text-success font-weight-bold text-xs mb-1"
                      style="color: rgb(78, 115, 223); font-size: 14px"
                    >
                      <span style="color: rgb(78, 115, 223)"
                        >NOTICES SENT THIS MONTH</span
                      >
                    </div>
                    <div class="text-dark font-weight-bold h5 mb-0">
                      <span>{{ sentCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-xl-7">
            <div class="card shadow mb-4">
              <div
                class="card-header theme-area-head d-flex justify-content-between align-items-center"
              >
                <h6 class="text-white font-weight-bold m-0">
                  ACCOUNT STATISTICS
                </h6>
                <div class="dropdown no-arrow d-none">
                  <button
                    class="btn btn-link btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    type="button"
                  >
                    <em class="fas fa-ellipsis-v text-gray-400"></em>
                  </button>
                  <div
                    class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                  >
                    <p class="text-center dropdown-header">
                      dropdown header:
                    </p>
                    <a class="dropdown-item" href="#">&nbsp;Action</a
                    ><a class="dropdown-item" href="#">&nbsp;Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#"
                      >&nbsp;Something else here</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <apexchart
                  type="line"
                  height="350"
                  :options="chartOptionsTwo"
                  :series="seriesTwo"
                ></apexchart>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-5">
            <div class="card shadow mb-4">
              <div
                class="card-header theme-area-head d-flex justify-content-between align-items-center"
              >
                <h6 class="text-white font-weight-bold m-0">
                  NOTICE SOURCES
                </h6>
                <div class="dropdown no-arrow d-none">
                  <button
                    class="btn btn-link btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    type="button"
                  >
                    <em class="fas fa-ellipsis-v text-gray-400"></em>
                  </button>
                  <div
                    class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                  >
                    <p class="text-center dropdown-header">
                      dropdown header:
                    </p>
                    <a class="dropdown-item" href="#">&nbsp;Action</a
                    ><a class="dropdown-item" href="#">&nbsp;Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#"
                      >&nbsp;Something else here</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <apexchart
                  type="donut"
                  :options="chartOptions"
                  :series="series"
                  :legend="chartOptions.legend"
                >
                </apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="row users">
          <div class="col-lg-12 mb-4">
            <div v-if="currentUser.userRole != 'User'" class="card shadow mb-4">
              <div class="card-header theme-area-head py-3">
                <h6 class="text-white font-weight-bold m-0">
                  Currently Active Users
                </h6>
              </div>
             
                            <div v-if="activeUsers?.length != 0" class="card-body">
                  <div v-for="item in activeUsers" v-bind:key="item.id">
                    <h1
                      class="text-primary card-title small font-weight-bold"
                      style="font-size: 14px"
                    >
                      {{ item.name ? item.name : item.companyName }}
                    </h1>
                    <div class="progress mb-4" style="position: relative;">
                      <div
                        class="progress-bar"
                        v-bind:class="[
                          item.percent < 10
                            ? 'bg-danger'
                            : item.percent > 10 && item.percent < 30
                            ? 'bg-warning'
                            : 'bg-success',
                        ]"
                        aria-valuenow="{{item.percent}}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-bind:style="{ width: item.percent + '%' }"
                      >
                       
                      </div>
                      <div style="padding-top: 8px;padding-left: 15px; position: absolute;">
                        {{ item.percent }}%
                        </div>
                    </div>
                  </div>
                </div>
              <div class="card" v-else-if="accountCompanyId!=''">
                No active users under {{ accountCompanyId }}
              </div>
               <div class="card" v-else>
                  No active users
                </div>
            </div>
          </div>
          <div v-if="currentUser.userRole != 'User'" class="col">
            <div v-if="deliveryAccounts?.length != 0" class="row">
              <div
                class="col-lg-6 mb-4"
                v-for="(item, index) in deliveryAccounts"
                v-bind:key="item.Deliveryaccount_id"
              >
             
                <div
                  v-bind:class="[
                    item.Deliveryaccount_id != accountCompanyId
                      ? 'card text-white shadow backbox '
                      : 'card text-white theme-delAcc border-success shadow',
                  ]"
                  v-on:click="deliveryAccountSelected(item)"
                >
                  <div class="card-body">
                    <p v-if="index == 0" class="m-0">Primary Account</p>
                    <p v-else class="m-0">Additional Account</p>
                    <p
                      class="text-white-50 small m-0"
                      style="color: black; font-size: 14px"
                    >
                      {{ item.Deliveryaccount }} - {{ item.Deliveryaccount_Alias }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-4">
                
              </div>
            </div>
            <div
              v-else-if="selectedUserRole == 'Corporate Admin'"
              class="card shadow mb-4 card"
            >
              No delivery accounts
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="content">
      <div class="container-fluid">
        <div class="card shadow">
          <div class="card-body">
            <div class="row oneBoxTable">
              <div class="col-md-6 text-nowrap">
                <div
                  id="dataTable_length"
                  class="dataTables_length"
                  aria-controls="dataTable"
                ></div>
              </div>
            </div>
            <div
              v-if="oneBoxList != null && oneBoxList?.length > 0"
              class="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
            <p
                      id="dataTable_info"
                      class="dataTables_info"
                      role="status"
                      aria-live="polite"
                      v-if="oneBoxList"
                    >
                     Recent {{oneBoxList.length}} of Onebox List
                    </p>
              <table
                class="table my-0 table-light table-bordered table-hover"
                id="dataTable"
              >
              <caption> </caption>
                <thead class="thead-dark">
                  <tr>
                    <th id="in-out" class="table-cell-adjust">Inbound / Outbound</th>
                    <th id="from">From</th>
                    <th id="to">To</th>
                    <th id="sent-rece">Sent / Received Date</th>
                    <th id="delv">Delivered To</th>
                    <th id="date">Delivered Date</th>
                    <th id="dwnDate">Download Date</th>
                    <th id="by">Downloaded By</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-grey"
                    v-bind:class="{
                      'text-grey': item.inboundOutboundStatus == 'inbound',
                    }"
                    v-for="item in oneBoxList"
                    v-bind:key="item.id"
                  >
                    <td class="table-cell-adjust">
                      <em
                        v-if="item.inboundOutboundStatus == 'outbound'"
                        class="fas fa-arrow-up"
                      ></em>
                      <em v-else class="fas fa-arrow-down"></em>
                    </td>
                    <td>
                     
                      {{
                        item.FromDeliveryAccount.accountAlias +
                          " - " +
                        item.FromDeliveryAccount.accountId
                      }}
                    </td>
                    <td>
                      {{
                        item.ToDeliveryAccount.accountAlias +
                         " - " + 
                        item.ToDeliveryAccount.accountId
                      }}
                    </td>
                    <td>
                      {{
                        moment(item?.createdAt).format(
                          "YYYY, MMM - DD hh:mm A"
                        )
                      }}
                    </td>
                    <td v-if="item.lastreadby != null">
                      {{ item.lastreadby }}
                    </td>
                    <td v-else>Unread</td>
                    <td v-if="item.lastread != null && item.lastread !=''">
                      {{
                        moment(item?.lastread)?.format("YYYY, MMM - DD hh:mm A")
                      }}
                    </td>
                    <td v-else>Unread</td>
                    <td v-if="item.lastdownload != null && item.lastdownload !=''">
                      {{
                        moment(item?.lastdownload)?.format(
                          "YYYY, MMM - DD hh:mm A"
                        )
                      }}
                    </td>
                    <td v-else>
                      <em class="fa fa-cloud-download"></em>&nbsp; Not
                      downloaded<br />
                    </td>
                    <td v-if="item.downloadedby != null">
                      {{ item.downloadedby }}
                    </td>
                    <td v-else>
                      <em class="fa fa-cloud-download"></em>&nbsp; Not
                      downloaded<br />
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="noData">
              Onebox is empty!
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import moment from "moment";
import Loading from "vue3-loading-overlay";
import {
  GET_NOTICE_COUNT,
  GET_ACTIVE_USERS,
  GET_NOTICE_PER_MONTH,
  GET_DASHBOARD_ONE_BOX,
  GET_NOTICE_SOURCES
} from "@/store/actions.type";
export default {
  props: ["selectedDeliveryId", "userId", "userRole", "companyId","fromDate","toDate"],
  computed: {
    ...mapGetters(["currentUser", "noticeSources"]),
  },
  components: {
    Loading,
  },
  beforeMount() {
    
    this.getNoticeSource();
  },
  data: function() {
    return {
      offset: 0,
      limit:10,
      totalCount: "",
      oneBoxList: null,
      isDefault: true,
      isLoading: false,
      selectedCompanyId: "",
      accountCompanyId: "",
      selectedUserRole: "",
      sentCount: "",
      receivedCount: "",
      deliveryAccounts: "",
      activeUsers: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chartOptions: {
        labels: [],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        dataLabels: {
          enabled: true,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (this.selectedIndex != config.dataPointIndex) {
                this.$emit(
                  "childToParent",
                  this.noticeSources.data[config.dataPointIndex].companyId,
                  "Corporate Admin"
                );
                this.selectedIndex = config.dataPointIndex;
              } else {
                this.$emit("childToParent", "", "");
                this.selectedIndex = "";
              }
            },
          },
        },
      },
      series: [],
      chartOptionsTwo: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      seriesTwo: [],
    };
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
     dateAdjuster(str){
      let date = new Date(str);
        date.setDate(date.getDate()+1)
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let seconds = ("0" + date.getSeconds()).slice(-2);
        var mySQLDate = [date.getFullYear(), month, day].join("-");
        var mySQLTime = [hours, minutes, seconds].join(":");
        return [mySQLDate, mySQLTime].join(" ");
    },
    getNoticeSource() {
      let dateEnd = this.dateAdjuster(this.toDate);
      this.isLoading = true;
      let body = {
          userRole: this.currentUser.userRole,
          companyId: this.currentUser.userRole=='Super Admin'? 0 : this.currentUser.companyId,
          
          deliveryId: 
                this.currentUser.userRole=='Super Admin'? [] : this.selectedDeliveryId,
          
          startDate: this.fromDate,
          endDate: dateEnd
        };
        
        this.$store
          .dispatch(GET_NOTICE_SOURCES, body)
          .then(() => {
            this.chartOptions = {labels:this.noticeSources.data.map((item)=> item.companyName)}
            this.series = this.noticeSources.data.map((item)=> parseInt(item.totalCount))

            this.getNoticeCount(
              this.userRole,
              this.selectedDeliveryId,
              this.fromDate,
              dateEnd
              
            );
         })
          .catch(() => {
            this.getNoticeCount(
              this.userRole,
              this.selectedDeliveryId,
              this.fromDate,
              dateEnd
             
            );
          });

    },
    getNoticeCount(userRole,deliveryId,fromDate,toDate) {
      let body = {
        userRole: userRole,
        
        deliveryId: userRole =='Super Admin'? [] : deliveryId,
        startDate: fromDate,
        endDate: toDate
        
      };
      
      this.$store
        .dispatch(GET_NOTICE_COUNT, body)
        .then((data) => {
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.sentCount = data.data.sent;
            this.receivedCount = data.data.received;
            if (this.currentUser.userRole != "User") {
              this.getActiveUsers(
                this.companyId,
                this.userRole,
                body.deliveryId,
                ""
              );
            } else {
              this.getNoticePerMonth(
                this.companyId,
                this.userRole,
                this.selectedDeliveryId
               
              );
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getActiveUsers(companyId, userRole, deliveryId, userId) {
      let body = {
        userRole: userRole,
        
        companyId: userRole =='Super Admin'? 0 : companyId,
        startDate: this.fromDate,
        endDate: this.dateAdjuster(this.toDate),
        userId: this.userId == "" ? userId : this.userId,
        delivery_accountids: deliveryId,
        selectedDeliveryId: userRole =='Super Admin'? []
                            :
          ( this.selectedDeliveryId == "" ? deliveryId : this.selectedDeliveryId ),
      };
      this.isLoading = true;
      this.$store
        .dispatch(GET_ACTIVE_USERS, body)
        .then((data) => {
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.activeUsers = data.data ? data.data : data;
            this.getNoticePerMonth(
               this.companyId,
                this.userRole,
                this.selectedDeliveryId
              
            );
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.activeUsers = [];
        });
    },
    getNoticePerMonth(companyId, userRole, deliveryId) {
      let body;
      if(this.fromDate !="" && this.toDate != "")
      {
        body = {
        companyId: userRole=='Super Admin' ? 0 : companyId,
       
        userRole: userRole,
        
        selectedDeliveryId: userRole=='Super Admin' ? [] : deliveryId,
        startDate: this.fromDate,
        endDate: this.dateAdjuster(this.toDate)
          
        };
      }
      else
      {
        body = {
        companyId: userRole=='Super Admin' ? 0 : companyId,
        
        userRole: userRole,
        
        selectedDeliveryId: userRole=='Super Admin' ? [] : deliveryId,
        
        };
      }

      this.$store
        .dispatch(GET_NOTICE_PER_MONTH, body)
        .then((data) => {
          if (userRole != "Super Admin") {
            this.seriesTwo = [
              {
                name: "Received",
                data: this.sortMonths(data.receivedData).map(
                  (item) => item.count
                ),
              },
              {
                name: "Send",
                data: this.sortMonths(data.sendData).map((item) => item.count),
              },
            ];
          } else {
            this.seriesTwo = [
              {
                name: "Notices",
                data: this.sortMonths(data.sendData).map((item) => item.count),
              },
            ];
          }
          this.chartOptionsTwo = {
            xaxis: {
              categories: this.sortMonths(data.receivedData).map(
                (item) => item.month
              ),
            },
          };
          this.getOneBoxList();
        })
        .catch(() => 
        {
          //intentional - empty-catch
        });
    },
    sortMonths(rData) {
      return rData.sort((a, b) => {
        return moment(a.month).format("MM") - moment(b.month).format("MM");
      });
    },
    getOneBoxList() {
      
      let deliveryIds = this.selectedDeliveryId.map(Number)
      let body = {
        filter: "all",
        search: "",
        deliveryId: this.userRole=='Super Admin'? [] : deliveryIds,
        limit: this.limit,
        offset: this.offset,
        startDate: this.fromDate,
        endDate: this.dateAdjuster(this.toDate),
        userRole : this.currentUser.userRole=='Super Admin' ? 'super admin' : ""
      };
      
      this.$store
        .dispatch(GET_DASHBOARD_ONE_BOX, body)
        .then((data) => {
          if (data.status == "failure") {
            this.isLoading = false;
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.oneBoxList = data.data;
            this.totalCount = data.totalCount;
            
            setTimeout(() => {
              this.downloadpdfreport();
            }, 1000);
            this.isLoading = false
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    downloadpdfreport() {
      
      let context = this;
      
        window.print()
        context.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped>
.table-icons-view {
  width: 28px;
}
.table-icons {
  width: 24px;
}
.heading {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;
}
@media print
{
.users, .oneBoxTable {page-break-before:always}
}
</style>
