<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <div class="col-lg-7">
                  <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                  </div>
                  <div class="p-3 scan-otp" v-if="showGoogleAuthArea">
                    <div class="scan-area" v-if="qrShow">
                      <div>
                        <img v-bind:src="url" alt="" />
                      </div>
                      <div>
                        <p>1. Open Google Authenticator app in your phone</p>
                        <p>
                          2. Scan The following QR code via Google Authenticator
                          app to register your phone
                        </p>
                        <p>
                          3. An OTP will be generated in the Google
                          Authenticator app
                        </p>
                      </div>
                    </div>
                    <div style="margin-top: 22px">
                      <hr v-if="qrShow" />
                      <div v-if="!qrShow">
                        <h6 style="text-align: center">
                          Get OTP from Google Authenticator app on your phone
                        </h6>
                        <hr />
                      </div>
                      <p style="font-size: 13px; font-weight: bold">
                        Enter the OTP from Google Authenticator app
                      </p>
                      <div class="input-otp">
                        <input
                          class="form-control form-control-user"
                          type="text"
                          name="authOtp"
                          v-model="authOtp"
                          @change="onChange"
                          placeholder="Enter OTP "
                        />
                        <button v-on:click="verifyOtp('authenticator')">
                          Verify&nbsp;OTP
                        </button>
                      </div>
                    </div>
                    <div v-if="!qrShow">
                      <p
                        style="
                          font-size: 12px;
                          margin-top: 22px;
                          text-align: center;
                        "
                      >
                        Lost Phone ?
                        <span class="lost-item" v-on:click="showEmailAuth()"
                          >Get OTP via email</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="p-3 email-otp" v-if="!showGoogleAuthArea">
                    <div style="margin-top: 22px">
                      <hr v-if="qrShow" />
                      <div>
                        <h5>
                          An OTP has been sent to your registered email id
                        </h5>
                        <hr />
                      </div>

                      <p style="font-size: 13px; font-weight: bold">
                        Enter the OTP from email
                      </p>
                      <div class="input-otp">
                        <input
                          class="form-control form-control-user"
                          type="text"
                          name="authOtp"
                          v-model="authOtp"
                          @change="onChange"
                          placeholder="Enter OTP "
                        />
                        <button v-on:click="verifyEmailOtp('email')">
                          Verify&nbsp;OTP
                        </button>
                      </div>
                    </div>
                    <div>
                      <p
                        style="
                          font-size: 12px;
                          margin-top: 22px;
                          text-align: center;
                        "
                      >
                        Lost Phone ?
                        <span class="lost-item" v-on:click="showGoogleAuth()"
                          >Get OTP via Google Authenticator app</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TWO_FACTOR_AUTHENTICATION,
  VERIFY_OTP,
  GENETATE_EMAL_OTP,
  GET_DELIVERY_ACCOUNTS,
  SET_ALL_DELIVERY_ACCOUNT_ITEM,
  SET_USER,
  SET_TOKEN,
} from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { mapGetters } from "vuex";

export default {
  name: "TwoFactorAuth",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["currentUser", "tempUser"]),
  },
  data: function () {
    return {
      url: "",
      qrShow: false,
      authOtp: "",
      showGoogleAuthArea: true,
    };
  },
  created() {
    this.getTwoFactorAuthentication();
  },
  methods: {
    showGoogleAuth() {
      (this.authOtp = ""), (this.showGoogleAuthArea = true);
    },
    showEmailAuth() {
      this.authOtp = "";
      this.generateEmailOtp();
    },
    generateEmailOtp() {
      let body = {
        userId: this.tempUser.userId
          ? this.tempUser.userId
          : this.$cookie.getCookie("USER_ID"),
      };

      this.$store
        .dispatch(GENETATE_EMAL_OTP, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
            this.showGoogleAuthArea = true;
          } else {
            if (data.status == "success") {
              this.showGoogleAuthArea = false;
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getTwoFactorAuthentication() {
      let body;

      let roleName = this.$cookie.getCookie("ROLE");

      if (roleName == "Super Admin") {
        body = {
          userId: this.$cookie.getCookie("USER_ID"),
        };
      } else {
        body = {
          userId: this.tempUser.userId
            ? this.tempUser.userId
            : this.$cookie.getCookie("USER_ID"),
          companyId: this.tempUser.companyId
            ? this.tempUser.companyId
            : this.$cookie.getCookie("COMPANY_ID"),
        };
      }

      this.$store
        .dispatch(TWO_FACTOR_AUTHENTICATION, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            if (data.url) {
              {
                this.url = data.url;
                this.qrShow = true;
              }
            } else {
              if (this.currentUser.twofactorEnable) {
                this.qrShow = false;
              }
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    verifyOtp(OtpType) {
      let body;
      let superAdminRole = this.$cookie.getCookie("ROLE");

      if (superAdminRole == "Super Admin") {
        body = {
          userId: this.$cookie.getCookie("USER_ID"),
          token: this.authOtp,
          type: OtpType,
        };
      } else {
        body = {
          companyId: this.tempUser.companyId
            ? this.tempUser.companyId
            : this.$cookie.getCookie("COMPANY_ID"),
          userId: this.tempUser.userId
            ? this.tempUser.userId
            : this.$cookie.getCookie("USER_ID"),
          token: this.authOtp,
          type: OtpType,
        };
      }

      this.$store
        .dispatch(VERIFY_OTP, body)
        .then((data) => {
          this.isLoading = false;

          if (data.status == "success") {
            this.$store.dispatch(SET_USER, this.tempUser);

            this.$cookie.setCookie("ID_TOKEN_KEY", data.data.authToken);

            if (superAdminRole !== "Super Admin") {
              this.$cookie.setCookie("COMPANY_ID", this.tempUser.companyId);
              this.$cookie.setCookie(
                "userdetails_id",
                this.tempUser.userdetails_id
              );
            }

            this.$store.dispatch(SET_TOKEN, data.data.authToken);

            this.$router.replace({ name: "Dashboard" });

            this.getDeliveryAccount();
          } else {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDeliveryAccount() {
      let body;

      let roleName1 = this.$cookie.getCookie("ROLE");

      if (roleName1 == "Super Admin") {
        body = {
          companyId: this.$cookie.getCookie("COMPANY_ID"),
          userdetails_id: this.$cookie.getCookie("userdetails_id"),
        };
      } else {
        body = {
          userId: this.tempUser.userId
            ? this.tempUser.userId
            : this.$cookie.getCookie("USER_ID"),
          companyId: this.tempUser.companyId
            ? this.tempUser.companyId
            : this.$cookie.getCookie("COMPANY_ID"),
        };
      }

      this.$store
        .dispatch(GET_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          if (Array.isArray(data.data)) {
            this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);
          }
        })
        .catch(() => {
          //intentional - catch error
        });
    },
    verifyEmailOtp(OtpType) {
      let body = {
        userId: this.$cookie.getCookie("USER_ID"),
        token: this.authOtp,
        type: OtpType,
      };

      this.$store
        .dispatch(VERIFY_OTP, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            if (data.status == "success") {
              this.showGoogleAuthArea = true;
              this.qrShow = true;
              this.getTwoFactorAuthentication();
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onChange(e) {
      if (e.target.name == "authOtp") {
        this.authOtp = e.target.value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.backdrop {
  background: transparent;
  height: 100vh;
}
.scan-area {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.lost-item {
  color: royalblue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.input-otp {
  display: flex;
  gap: 15px;
  button {
    font-size: 13px;
    padding: 0px 11px;
    border-radius: 6px;
    border: 1px solid #2e59d9;
    background-color: white;
    color: #2e59d9;
    &:hover {
      border: 1px solid #2e59d9;
      background-color: #2e59d9;
      color: white;
    }
  }
}
</style>
