import {
    GET_ACCOUNT_TYPES,
    CREATE_NEW_ACCOUNT
} from "../actions.type";
import { SET_ERROR } from "../mutations.type";
import { Service, post } from "@/api/service";

const state = {
    errors: null,
    accountTypes: '',
};



const actions = {
    [GET_ACCOUNT_TYPES](context) {
        return new Promise((resolve, reject) => {
            Service.get("v2/listSubscriptionTiers")
                .then(({ data }) => {
                    
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response)
                });
        })
    },


    [CREATE_NEW_ACCOUNT](context, body) {
        return new Promise((resolve, reject) => {
            //createNewAccount
            post("v2/create_subscription", body)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response)
                });
        })
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
};