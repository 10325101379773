import { createStore } from 'vuex'
import login from "./modules/login.module"
import register from "./modules/register.module"
import profile from "./modules/profile.module"
import notice from "./modules/notice.module"
import onebox from "./modules/onebox.module"
import dashboard from "./modules/dashboard.module"
import superAdmin from "./modules/superadmin.module"
import twoFactorAuth from "./modules/twoFactorAuth.module"
import createPersistedState from "vuex-persistedstate";
export const store = createStore({
    modules: {
        login,
        register,
        profile,
        notice,
        onebox,
        dashboard,
        superAdmin,
        twoFactorAuth
    },
    plugins: [createPersistedState()],
})