<template>
  <div class="container">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <div class="card shadow-lg o-hidden border-0 my-5">
      <div class="card-body p-0">
        <div class="row">
          <div class="col">
            <div class="d-flex  justify-content-around">
              <img
                style="max-width: 447px;height: auto;"
                src="../assets/img/oneNotice/oneNoticeLogo.png"
                alt="logoOneNotice"
              />
            </div>
            <div class="text-center">
              <h4 class="text-dark pt-4">Choose Account Type</h4>
            </div>
            <div class="p-2 rowAccoutTypes" style="margin-top: 25px;">
              <div
                v-for="item in accountTypes"
                v-bind:key="item.id"
                class="col px-3 px-lg-1 card shadow  mb-4"
                style="padding: 0px !important; height: 100%; "
              >
                <div class="card-header py-3 card-head">
                  <h6
                    v-if="item.planName == 'Free'"
                    class="text-white m-0 font-weight-bold"
                  >
                    {{ item.planName }}
                  </h6>
                  <h6 v-else class="text-white m-0 font-weight-bold">
                    {{ item.planName }} Account: ${{ item.price / 100 }} per
                    month
                  </h6>
                </div>
                <div class="card-body flex-grow-1" style="display: grid;">
                  <p
                    class="accoutDetailsText card-text m-0"
                    style="color: rgb(30, 135, 230)"
                  >
                    <strong>This plan includes</strong>:
                  </p>
                  <ul>
                    <li
                      v-if="item.planName == 'Free'"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      One sending account, cannot receive notices
                    </li>
                    <li
                      v-else
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      ({{ item.deliveryAccounts }}) delivery account
                    </li>
                    <li
                      v-if="item.planName == 'Free'"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      One ({{ item.userCount }}) user
                    </li>
                    <li
                      v-else
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Up to ({{ item.userCount }}) users
                    </li>
                    <li
                      v-if="item.multipleAttachment"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Multiple attachments per delivery notice
                    </li>
                    <li
                      v-else
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      One attachment at a time
                    </li>
                    <li
                      v-if="item.deliveryConfirmation"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Delivery confirmation
                    </li>
                    <li
                      v-if="item.readStatusConfirmation"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Read status confirmation
                    </li>
                    <li
                      v-if="item.noticePreview"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Notice previews
                    </li>
                    <li
                      v-if="item.sendAndRecieveNotice"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Can send and receive notices
                    </li>
                    <li
                      v-if="item.downloadNotice"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Can download notices
                    </li>
                    <li
                      v-if="item.planName == 'Free'"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Can send up to
                      {{ item.noticeCount }}
                      notices per month
                    </li>
                    <li
                      v-else-if="item.noticeCount == '-1'"
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Unlimited notices per month
                    </li>

                    <li
                      v-else
                      class="accoutDetailsText card-text m-0"
                      style="color: rgb(30, 135, 230)"
                    >
                      Limit of
                      {{
                        item.noticeCount === -1 ? "Unlimited" : item.noticeCount
                      }}
                      notices per month
                    </li>
                  </ul>
                  <!-- btn-primary -->
                  <a
                    class="btn theme-btn text-white text-center d-xl-flex justify-content-xl-center  border-0"
                    role="button"
                    style="text-align: center; margin-top: 15px; width: 100%;align-self: end;height: auto;justify-self: center;"
                    v-on:click="
                      selectAccountTypes(
                        item.id,
                        item.price,
                        item.deliveryAccounts,
                        item.userCount,
                        item.priceid,
                        item.product_id
                      )
                    "
                    >Select</a
                  >
                </div>
              </div>
            </div>
            <div class="text-center">
              <a class="small" href="/">Already have an account? Login!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ACCOUNT_TYPES, CREATE_NEW_ACCOUNT } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import { mapGetters } from "vuex";

export default {
  name: "ChooseAccountTypes",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["tempUser"]),
  },
  methods: {
    getAccountTypes() {
      this.$store
        .dispatch(GET_ACCOUNT_TYPES)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.accountTypes = data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    selectAccountTypes: function(
      id,
      price,
      count,
      usercount,
      priceId,
      productId
    ) {
      this.isLoading = true;
      let data = {
        firstName: this.tempUser.firstName,
        lastName: this.tempUser.lastName,
        email: this.tempUser.email,
        password: this.tempUser.password,
        repeatPassword: this.tempUser.repeatPassword,
        accountType: "free",
        planId: id,
        amount: price,
        deliveryAccounts: count,
        userCount: usercount,
        notes: "Free payment",
        companyName: this.tempUser.companyName,
        plan_id: id,
        product_id: productId,
        price_id: priceId,
      };
      this.$store
        .dispatch(CREATE_NEW_ACCOUNT, data)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            window.location.href = data.data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.getAccountTypes();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      accountTypes: "",
    };
  },
};
</script>

<style scoped lang="scss">
.card-head {
  background-color: #27588d;
  color: white;
}
.tier-btn {
  background-color: #00a5b1;
}
.accoutDetailsText {
  font-size: 15px;
  font-weight: 300;
  padding: 6px;
}
.rowAccoutTypes {
  display: grid;
  flex-wrap: wrap;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: 17px;
  align-items: baseline;
  justify-content: center;
  justify-items: center;
  column-gap: 18px;
}
@media only screen and (max-width: 991px) {
  .rowAccoutTypes {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 639px) {
  .rowAccoutTypes {
    grid-template-columns: 1fr;
  }
}
</style>
