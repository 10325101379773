<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <div class="col-lg-7">
                  <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                  </div>
                  <div class="p-5">
                    <div class="text-center">
                      <h4 class="text-dark mb-4">Create Password</h4>
                    </div>
                    <form
                      class="user"
                      @submit.prevent="submitForm(confirmPassword, newPassword)"
                    >
                      <div class="form-group">
                        <div style="position: relative">
                          <input
                            class="form-control form-control-user"
                            :class="{ 'is-invalid': !newPassword && submitted }"
                            :type="showPassword1 ? 'text' : 'password'"
                            name="newPassword"
                            maxlength="20"
                            aria-describedby="emailHelp"
                            placeholder="Enter new password"
                            v-model="newPassword"
                            @change="onChange"
                          />
                          <em
                            v-show="!showPassword1 && newPassword"
                            @click="togglePasswordVisibility1"
                            class="fas fa-eye fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                          <em
                            v-show="showPassword1 && newPassword"
                            @click="togglePasswordVisibility1"
                            class="fas fa-eye-slash fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                        </div>
                        <p>
                          <small
                            class="text-danger"
                            v-if="!newPassword && submitted"
                            >Valid password is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group">
                        <div style="position: relative">
                          <input
                            class="form-control form-control-user"
                            :class="{
                              'is-invalid': !confirmPassword && submitted,
                            }"
                            :type="showPassword2 ? 'text' : 'password'"
                            name="confirmPassword"
                            maxlength="20"
                            placeholder="Confirm Password"
                            v-model="confirmPassword"
                            @change="onChange"
                          />
                          <em
                            v-show="!showPassword2 && confirmPassword"
                            @click="togglePasswordVisibility2"
                            class="fas fa-eye fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                          <em
                            v-show="showPassword2 && confirmPassword"
                            @click="togglePasswordVisibility2"
                            class="fas fa-eye-slash fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                        </div>
                        <p>
                          <small
                            class="text-danger"
                            v-if="!confirmPassword && submitted"
                            >Confirm Password is required</small
                          >
                        </p>
                      </div>
                      <!-- btn-primary -->
                      <button
                        class="btn theme-btn btn-block text-white btn-user mx-0"
                        type="submitForm"
                        :disabled="submitStatus === 'PENDING'"
                      >
                        Reset Password
                      </button>

                      <hr />
                      <hr />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { RESET } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { validatePassword } from "../store/modules/rejex.module";

export default {
  name: "Login",
  components: {
    Loading,
  },
  methods: {
    handleBlur(key) {
      this.$v[key].$dirty = true;
    },
    togglePasswordVisibility1() {
      this.showPassword1 = !this.showPassword1;
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
    },
    submitForm() {
      this.isLoading = true;
      this.submitted = true;
      if (!this.newPassword || !this.confirmPassword || !this.token) {
        this.submitStatus = "ERROR";
        this.isLoading = false;
      } else {
        // Password match checking
        if (this.newPassword == this.confirmPassword) {
          if (validatePassword(this.newPassword)) {
            this.onResetPassword(this.newPassword, this.token);
          } else {
            this.isLoading = false;
            this.$toast.show(
              "Your password must contain at least 8 characters, one number, one uppercase letter, one lowercase letter, and one special character. No space is allowed.",
              {
                type: "error ",
                position: "top-right",
              }
            );
          }
        } else {
          this.isLoading = false;
          this.$toast.show("Password and Confirm Password must be the same", {
            type: "error ",
            position: "top-right",
          });
        }
      }
    },
    onChange(e) {
      //this.submitted = false;
      if (e.target.name == "newPassword") {
        this.newPassword = e.target.value;
      } else if (e.target.name == "confirmPassword") {
        this.confirmPassword = e.target.value;
      }
    },
    onResetPassword(newPassword, token) {
      this.$store
        .dispatch(RESET, { newPassword, token, repeatPassword: newPassword })
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$router.push({ name: "Login" });
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast.show(err.message, {
            type: "error ",
            position: "top-right",
          });
        });
    },
  },
  computed: {
    // users() {
    //   return this.$store.state.user;
    // },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      confirmPassword: null,
      token: null,
      submitStatus: null,

      newPassword: null,
      showPassword1: false,
      showPassword2: false,
    };
  },
  beforeMount() {
    this.token = this.$route.query.key;
  },
  validations: {
    newPassword: { required },
    confirmPassword: { required },
  },
};
</script>

<style scoped></style>
