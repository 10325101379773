<template>
  <div id="wrapper">
    <left-drawer />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header :checkUpdate="updateMsg" ref="form"/>
        <ProfileContent
        v-bind:profile-id="userId"
        v-bind:from-super-admin="false"
         @updateDeliveryList="updateListOfDeliveryItems($event)"
        />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import LeftDrawer from "./LeftDrawer.vue";
import Footer from "./Footer.vue";
import ProfileContent from './Profile/ProfileContent.vue'



export default {

  components: {
    Header,
    LeftDrawer,
    Footer,
    ProfileContent
  } ,

  data() {
    return {
      userId : this.$cookie.getCookie("USER_ID"),
      updateMsg:[],
    }
  },
  methods: {
    updateListOfDeliveryItems(deliveryAccountsArray)
    {
       this.updateMsg=deliveryAccountsArray;
       this.$refs.form.getDeliveryAccount()
    }
  },


};
</script>

<style>
</style>