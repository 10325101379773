<template>
  <div id="donut" v-if="this.noticeSources?.data?.length>0">
       <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
        :legend="chartOptions.legend"></apexchart>
  </div>
  <div v-else>
    <h5>Notice source is empty</h5>
  </div>
</template>

<script>
import {GET_NOTICE_SOURCES,LIST_DELIVERY_ACCOUNTS} from "@/store/actions.type";
import { mapGetters } from "vuex";
export default {
  props:["startDate","endDate"],
  watch:
  {
    startDate(value)
    {
      this.lowerDate = value;
        if(this.endDate != this.upperDate )
          {
            this.dateChange = true;
            this.upperDate = this.endDate;
          }
        else
        {
          this.dateChange = false;
        }       

      // if(value != "" && this.endDate != "")
      // {
        this.getDeliveryAccountList();
      // }
    },
    endDate(value)
    {
      this.upperDate = value;
      // if(this.startDate != "" && value != "")
      // {
        this.dateChange ? 
          this.dateChange = false
        :
          this.getDeliveryAccountList();
      }
    // }
  },
  computed: {
    ...mapGetters(["currentUser","noticeSources"]),
    },
    beforeMount(){
        
         this.lowerDate = this.startDate;
         this.upperDate = this.endDate;
         this.getDeliveryAccountList();
        
    },
    methods:{
      getDeliveryAccountList() {
      let deliveryIdList;
      let detailsbody = {
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };
      detailsbody.userdetails_id ?
      this.$store
        .dispatch(LIST_DELIVERY_ACCOUNTS, detailsbody)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.deliveryAccounts = data.data;
            this.userCompanyId = data.companyId;
            
            deliveryIdList= data.data.map((item) => item.Deliveryaccount_id);
           
            this.getNoticeSource(deliveryIdList)
           
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
        :
        this.getNoticeSource();
    },
        getNoticeSource(deliveryIdList){
          
          let dateStart = "", dateEnd = "";
          if(this.lowerDate != "" && this.upperDate != "")
          {
            dateStart = this.lowerDate
            dateEnd = this.upperDate
          }
          
        let body = {
          userRole: this.currentUser.userRole,
          companyId: 
            this.currentUser.userRole=='Super Admin'? 0 : this.currentUser.companyId,
          deliveryId:
            this.currentUser.userRole=='Super Admin'? [] : deliveryIdList,
          startDate: dateStart,
          endDate: dateEnd
        };
        
        this.$store
          .dispatch(GET_NOTICE_SOURCES, body)
          .then(() => {
            this.chartOptions = {labels:this.noticeSources.data.map((item)=> item.companyName)}
            this.series = this.noticeSources.data.map((item)=> parseInt(item.totalCount))
          })
          .catch(() => {
            this.chartOptions = []
            this.series = []
          });
        }
    },

    data(){
    return {
        dateChange:false,
        lowerDate:"",
        upperDate:"",
        selectedIndex:'',
        noticeSource: [],
        chartOptions: {
        labels: [],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        dataLabels: {
        enabled: true
        },
        chart: {
        events: {
         
        }
        }
        },
       series: [],
    }
}
}
</script>

<style>

</style>