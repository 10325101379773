<template>
  <div id="wrapper">
    <LeftDrawer  @reload-onebox="refreshOnNotification()" />
    <Loading :active="isLoading" :can-cancel="true"></Loading>
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header @notification-alert="refreshOnNotification()" />
        <div id="content" style="min-height: calc(100vh - 168px);">
          <div class="container-fluid">

            <div class="d-flex justify-content-between">
              <h3 class="text-dark mb-4">Onebox</h3>

                                                           
                <a v-if=" currentUser.userRole == 'Super Admin' ? false : isSendNotice "
                class="sendnotice mb-4 p-2 nav-link" v-on:click="showSendNotice"
                ><em class="icon ion-ios-email-outline p-1"></em>&nbsp;&nbsp;<span>Send Notice</span>
                </a>
            </div>



            <div class="card shadow" id="tableNotices">
              
              <div class="card-header theme-area-head py-3 ">
                <p class="text-white m-0 font-weight-bold">Notice Info</p>
               
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-nowrap">
                    <div>
                      <div
                        id="dataTable_length"
                        class="dataTables_length"
                        aria-controls="dataTable"
                      >
                        <label
                          >Show&nbsp;<select
                            @change="onLimitChange($event)"
                            v-model="limit"
                            class="form-control form-control-sm custom-select custom-select-sm"
                          >
                            <option value="10" selected="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option></select
                          >&nbsp;</label
                        >
                        <label
                          ><select
                            @change="onListChange($event)"
                            v-model="listFilterKey"
                            class="form-control form-control-sm custom-select custom-select-sm"
                          >
                            <option value="all" selected="All">All</option>
                            <option value="received">Received</option>
                            <option value="send">Send</option></select
                          >&nbsp;</label
                        >
                      </div>
                      <div
                        style="display: flex;
                          align-content: center;
                          align-items: end;
                          gap: 11px;"
                      >
                        <div>
                          Start Date
                          <datepicker
                            v-model="dateLower"
                            :upperLimit="dateUpper"

                          />
                        </div>
                        <div>
                          End Date
                          <datepicker
                            v-model="dateUpper"
                            :lowerLimit="dateLower"
                          />
                        </div>
                        <div>
                          
                          <button
                            style="margin-left: 0px;
                                      margin-bottom: 0px;
                                      "
                            class="btn theme-btn text-light btn-sm d-none d-sm-inline-block"
                            v-if="dateLower || dateUpper"
                            @click="clearFilter()"
                          >
                            Clear
                          </button>
                          <button
                            style="margin-left: 0px;
                                    margin-bottom: 0px;
                                    margin-right: 0px;"
                            class="btn theme-btn text-light btn-sm d-none d-sm-inline-block"
                            v-if="dateLower && dateUpper"
                             @click="searchOnDate()"

                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="text-md-right dataTables_filter"
                      id="dataTable_filter"
                    >
                      <label
                        ><input
                          type="search"
                          class="form-control form-control-sm"
                          aria-controls="dataTable"
                          placeholder="Search"
                          v-model="searchKeyWord"
                          @change="searcNotice"
                      /></label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="oneBoxList != null && oneBoxList?.length > 0"
                  class="table-responsive table mt-2"
                  id="dataTable"
                  role="grid"
                  aria-describedby="dataTable_info"
                >
                  <table
                    class="table my-0 table-light table-bordered table-hover"
                    id="dataTable"
                  >
                 <caption> </caption>
                    <thead class="theme-tbl-head text-light">
                      <tr>

                        <th id="fav">Fav</th>
                        <th id="in-out" class="table-cell-adjust">Inbound / Outbound</th>
                        <th id="frm">From</th>
                        <th id="to">To</th>
                        <th id="sent-rec">Sent / Received Date</th>
                        <th id="lastRe">Last Read By</th>
                        <th id="lastRead">Last Read On</th>
                        <th id="lastDwn">Last Downloaded On</th>
                        <th id="lastDwnBy">Last Downloaded By</th>
                        <th id="prvw" class="table-cell-adjust">Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="text-grey"
                        v-bind:class="{
                          'text-grey': item.inboundOutboundStatus == 'inbound',
                        }"
                        v-for="item in oneBoxList"
                        v-bind:key="item.id"
                      >
                       
                        <td  class="table-cell-adjust align-middle">
                          <button class="bookmarker" @click="toggleBookmark(item)">
                             <em v-if="!item.flagged" class="far fa-bookmark"></em>
                            <em   v-else class="fas fa-bookmark"></em>
                          </button>
                        </td>
                        <td class="table-cell-adjust align-middle">
                          <em
                            v-if="item.inboundOutboundStatus == 'outbound'"
                            class="fas fa-arrow-up"
                          ></em>
                          <em v-else class="fas fa-arrow-down"></em>
                        </td>
                        <td class="align-middle">
                          <span v-if="item.FromDeliveryAccount.accountAlias">{{
                            item.FromDeliveryAccount.accountAlias +
                              " - "
                          }}</span>
                          <span>{{

                              item.FromDeliveryAccount.accountId
                          }}</span>
                        </td>
                        <td class="align-middle">
                          <span v-if="item.ToDeliveryAccount.accountAlias">{{
                            item.ToDeliveryAccount.accountAlias +
                              " - "

                          }}</span>
                          <span>{{

                              item.ToDeliveryAccount.accountId
                          }}</span>
                        </td>
                        <td class="align-middle">
                          {{
                            moment(item?.createdAt).format(
                              "YYYY, MMM - DD hh:mm A"
                            )
                          }}
                        </td>
                        <td v-if="item.lastreadby" class="align-middle">
                          {{ item.lastreadby }}
                        </td>
                        <td class="align-middle" v-else>Unread</td>
                        <td class="align-middle" v-if="item.lastread">
                          {{
                            moment(item?.lastread)?.format(
                              "YYYY, MMM - DD hh:mm A"
                            )
                          }}
                        </td>
                        <td class="align-middle" v-else>Unread</td>
                        <td
                          class="align-middle"
                          v-if="item.lastdownload"
                        >
                          {{
                            moment(item?.lastdownload)?.format(
                              "YYYY, MMM - DD hh:mm A"
                            )
                          }}
                        </td>
                        <td class="align-middle" v-else>
                          <em class="fa fa-cloud-download"></em>&nbsp; Not
                          downloaded<br />
                        </td>
                        <td
                          class="align-middle"
                          v-if="item.downloadedby"
                        >
                          {{ item.downloadedby }}
                        </td>
                        <td class="align-middle" v-else>
                          <em class="fa fa-cloud-download"></em>&nbsp; Not
                          downloaded<br />
                        </td>
                        <td
                          class="table-cell-adjust align-middle clickable-Span"
                          style="cursor: pointer"
                        >
                          <img
                            v-if="item.downloadStatus == true"
                            class="table-icons-view"
                            @click="getNoticeFullDetails(item)"
                            src="@/assets/img/oneNotice/view.png"
                            alt="view"
                          />
                          <img
                            v-else
                            @click="getNoticeFullDetails(item)"
                            class="table-icons"
                            src="@/assets/img/oneNotice/file.png"
                            alt="file"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else-if="searchKeyWord == ''" class="noData">
                  Onebox is empty!
                </div>
                <div v-else class="noData">
                  No search results for {{ searchKeyWord }}
                </div>
                <div class="row">
                  <div
                    class="col-md-6 align-self-center"
                    v-if="oneBoxList?.length > 0"
                  >
                    <p
                      id="dataTable_info"
                      class="dataTables_info"
                      role="status"
                      aria-live="polite"
                      v-if="oneBoxList"
                    >
                      Showing
                      {{ oneBoxList?.length > 0 ? offset + 1 : offset }} to
                      {{ offset + oneBoxList?.length }} of {{ totalCount }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <nav
                      class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                    >
                      <ul class="pagination">
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            v-for="pageNumber in pages"
                            :key="pageNumber"
                            @click="next((page = pageNumber))"
                          >
                            {{ pageNumber }}
                          </button>
                        </li>
                       
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <!-- notice details -->
            <div class="card shadow noticeDetailsPage"  id="myModal">
              
              <div class="card-header theme-area-head py-3 ">
                <p class="text-white m-0 font-weight-bold" v-if="selectedNoticeitem != null">Notice Info
                   {{
                                  selectedNoticeitem.FromDeliveryAccount.accountAlias +
                                    " - " +
                                    selectedNoticeitem.FromDeliveryAccount.accountId
                                }}
                </p>

              </div>
              <div class="card-body">
                <div class="row">
                    <div  style="width:100%">
                          <!-- Modal content -->
                          <div >
                            <div >
                              <div>
                                <!-- {{
                                  selectedNoticeitem.FromDeliveryAccount.accountAlias +
                                    " - " +
                                    selectedNoticeitem.FromDeliveryAccount.accountId
                                }} -->
                              </div>

                            </div>

                            <div class="modal-body">
                              <div style="display:flow-root">
                                 <div style="display: flex;justify-content: space-between;"  v-if="showItems">


                                   <button
                                    @click="closeModel()"
                                    style="background: white;border: 0;"
                                  >
                                    <i
                                      class="fa fa-arrow-left"
                                      aria-hidden="true"
                                    ></i>

                                    Back
                                  </button>
                                <button
                                  v-if="showItems && isDownloadNotice"
                                  class="btn btn-primary btn-sm d-none d-sm-inline-block"

                                  @click="startDownload(downloadId)"
                                >
                                  <i
                                    class="fa fa-download  text-white-50"
                                    aria-hidden="true"
                                  ></i>
                                  Download All
                                </button>
                              </div>
                                <div style="float:left">
                                  <button
                                    v-if="!showItems"
                                    @click="backOnModel()"
                                    style="background: white;border: 0;margin-bottom: 12px; margin-bottom: 25px;"
                                  >
                                    <i
                                      class="fa fa-arrow-left"
                                      aria-hidden="true"
                                    ></i>

                                    Back
                                  </button>
                                </div>
                                <!-- <div style="float:right">
                                  <button
                                    class="btn btn-primary btn-sm d-none d-sm-inline-block"
                                    @click="startDownload(downloadId)"
                                  >
                                    <i
                                      class="fa fa-download  text-white-50"
                                      aria-hidden="true"
                                    ></i>
                                    Download All
                                  </button>
                                </div> -->
                              </div>
                              <div v-if="showItems">
                                <div
                                  class=""
                                  v-for="selectedNotice in noticeItemDetails"
                                  v-bind:key="selectedNotice.notice_fileId"
                                >
                                  
                                  <div style="display: flex;align-items: center;">
                                  <div
                                    @click="openFile(selectedNotice)"
                                    class="file-details-name"
                                  >
                                    <div class="file-icon">
                                      <i
                                        class="fa fa-file-pdf-o file-icon-pdf"
                                        aria-hidden="true"
                                        v-if="selectedNotice.filetype == 'pdf'"
                                      ></i>

                                      <i
                                        class="fa fa-file-image-o  file-icon-image"
                                        aria-hidden="true"
                                        v-else-if="
                                          selectedNotice.filetype == 'png' ||
                                            selectedNotice.filetype == 'jpg' ||
                                            selectedNotice.filetype == 'jpeg'
                                        "
                                      ></i>

                                      <i
                                        class="fa fa-file file-icon-doc"
                                        aria-hidden="true"
                                        v-else
                                      ></i>
                                    </div>
                                    <div class="file-name">
                                      {{ selectedNotice.Notice_file.path }}
                                    </div>
                                  </div>
                                  <div>
                                    <button v-if="isDownloadNotice" style="margin-bottom: 0px !important;" class="btn btn-primary btn-sm d-none d-sm-inline-block"
                                     @click="downloadSingleFile(selectedNotice)"> <i
                                    class="fa fa-download  text-white-50"
                                    aria-hidden="true"
                                  ></i></button></div>
                                  </div>
                                  <div class="read-download-details">
                                    <div
                                      class="download List"
                                      v-if="selectedNotice.downloads.length > 0"
                                    >
                                      <h6>Downloaded By</h6>

                                      <table
                                        class="table my-0 table-light table-bordered table-hover"
                                      >
                                      <caption> </caption>
                                        <thead class="thead-dark">
                                          <tr>
                                            <th id="name">
                                              Name
                                            </th>
                                            <th id="dwnOn">
                                              Downloaded on
                                            </th>
                                          </tr>
                                        </thead>
                                        <tr
                                          class="text-grey"
                                          v-for="downloadListItem in selectedNotice.downloads"
                                          v-bind:key="
                                            downloadListItem.firstname
                                          "
                                        >
                                          <td>
                                            {{ downloadListItem.firstname }}
                                            {{ downloadListItem.lastname }}
                                          </td>
                                          <td>
                                            {{
                                              moment(
                                                downloadListItem.downaloadedat
                                              ).format("YYYY, MMM - DD hh:mm A")
                                            }}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div
                                      class="Read List"
                                      v-if="selectedNotice.reads.length > 0"
                                    >
                                      <h6>Read By</h6>
                                      <table
                                        class="table my-0 table-light table-bordered table-hover"
                                      >
                                      <caption> </caption>
                                        <thead class="thead-dark">
                                          <tr>
                                            <th id="name">
                                              Name
                                            </th>
                                            <th id="readOn">
                                              Read on
                                            </th>
                                          </tr>
                                        </thead>
                                        <tr
                                          class="text-grey"
                                          v-for="readListItem in selectedNotice.reads"
                                          v-bind:key="readListItem.firstname"
                                        >
                                          <td>
                                            {{ readListItem.firstname }}
                                            {{ readListItem.lastname }}
                                          </td>
                                          <td>
                                            {{
                                              moment(
                                                readListItem.downaloadedat
                                              ).format("YYYY, MMM - DD hh:mm A")
                                            }}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                 
                                  <hr />
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="selectedNoticetype == 'pdf'">
                                  <div>{{ pageNumer }} / {{ pageCount }}</div>
                                  <div>
                                    <button
                                      class="btn btn-primary btn-sm d-none d-sm-inline-block"
                                      @click="pdfPrevPage()"
                                    >
                                      <i
                                        class="fa fa-arrow-left  text-white-50"
                                        aria-hidden="true"
                                      ></i>
                                      Prev
                                    </button>

                                    <button
                                      class="btn btn-primary btn-sm d-none d-sm-inline-block"
                                      @click="pdfNextPage()"
                                    >
                                      Next
                                      <i
                                        class="fa fa-arrow-right  text-white-50"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  <pdf
                                    :src="src"
                                    :page="pageNumer"
                                    num-pages
                                    page-loaded="pageNumer"
                                  ></pdf>
                                </div>
                                <div v-else-if="selectedNoticetype == 'image'">
                                  <img
                                    :src="fileImgSrc"
                                    alt=""
                                    style="width: 100%;height: auto;}"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header";
import Loading from "vue3-loading-overlay";
import LeftDrawer from "../LeftDrawer.vue";
import Footer from "../Footer";
import { mapGetters } from "vuex";
import moment from "moment";

import Datepicker from "vue3-datepicker";
import pdf from "vue3-pdf";

import {
  GET_ONE_BOX_LIST,
  DOWNLOAD_NOTICE,
  ADD_FILE_READ,
  BOOKMARK_NOTICE,
  ADD_FILE_DOWNLOAD,
  LISTUSER_PERMISSIONS,
  LIST_DELIVERY_ACCOUNTS,
  DOWNLOAD_SINGLE_NOTICE,
  ONE_BOX_NOTICE_DETAILS,
} from "@/store/actions.type";

export default {
  name: "Onebox",

  components: {
    Header,
    LeftDrawer,
    Footer,
    Loading,
    pdf,
    Datepicker,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  beforeMount() {
    this.getOneBoxList(this.offset);
    this.getPermissions();
  },

  mounted() {
    this.refreshOnNotification();
    this.dateLower = "";
    this.dateUpper = "";

  },
  data() {
    return {
      isLoading: false,
      oneBoxList: null,
      deliveryId: null,
      limit: 10,
      offset: 0,
      totalCount: "",
      pages: [],
      page: 1,
      searchKeyWord: "",
      isDownloadNotice: false,
      isSendNotice: false,
      listFilterKey: "all",
      currentPage: 1,
      pageCount: 0,
      pageNumer: 1,
      src: undefined,
      showItems: true,

      openModelStatus:false,

      selectedfileGroup: undefined,
      selectedNoticetype: undefined,
      fileImgSrc: undefined,
      downloadId: undefined,
      noticeItemDetails: undefined,
      selectedNoticeitem : null,
      dateUpper: Date(),
      dateLower: Date(),
        dateLowerSearch: "",
        dateUpperSearch: "",
    };
  },
  methods: {
    showSendNotice()
    {
      this.$router.push({ name: "SendNotice" });
    },
    toggleBookmark(oneboxItem)
    {
      let body = {

        userId: this.$cookie.getCookie("USER_ID"),
        noticeId : oneboxItem.id

      };
     
      this.$store
        .dispatch(BOOKMARK_NOTICE, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
           this.getOneBoxList(this.offset);
        this.getPermissions();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    clearFilter() {
      this.dateUpper = "";
      this.dateLower = "";
      this.dateUpperSearch = "";
      this.dateLowerSearch = "";
      this.getOneBoxList(0)
    },
searchOnDate() {
     this.dateUpperSearch =this.convertDatePickerTimeToMySQLTime(this.dateUpper,0);
      this.dateLowerSearch =this.convertDatePickerTimeToMySQLTime(this.dateLower,0);
      let flagMessage = this.checkValidDate(this.dateLowerSearch,this.dateUpperSearch)
      if(flagMessage == "PROCEED" )
      {
        this.dateUpperSearch = this.convertDatePickerTimeToMySQLTime(this.dateUpper,1);
        this.getOneBoxList(0)
      }
      else
      {
      this.$toast.show(flagMessage, {
              type: "error ",
              position: "top-right",
            });
      }
    },
    convertDatePickerTimeToMySQLTime(str,endDate) {

        let date = new Date(str);
        if(endDate)
        {
          date.setDate(date.getDate()+1)
        }
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let seconds = ("0" + date.getSeconds()).slice(-2);

        var mySQLDate = [date.getFullYear(), month, day].join("-");
        var mySQLTime = [hours, minutes, seconds].join(":");
        return [mySQLDate, mySQLTime].join(" ");
    },
    checkValidDate(start,end)
    {
      let proceedMessage = "PROCEED"
      let today = new Date();
      if(start =="" || end == "")
      {
        proceedMessage = "Invalid Date!"
        return proceedMessage
      }
      let startDate = new Date(start)
      let endDate = new Date(end)
      if(startDate.getFullYear() != endDate.getFullYear())
      {
        proceedMessage = "Please Choose Date-range within an year!"
      }
      else if(today.getFullYear() < startDate.getFullYear())
      {
        proceedMessage = "No data available in the chosen date-range!"
      }
      return proceedMessage;
    },
    backOnModel() {
      this.showItems = true;
    },
    openFile(item) {

      if (item.filetype == "pdf" || item.filetype == "PDF") {
        this.selectedNoticetype = "pdf";
        this.addURL(item.url);
         this.showItems = false;
         if( this.selectedfileGroup.inboundOutboundStatus !="outbound")
         {  this.setReadStatus([item.filehistory_id]);}
      } else if (
        item.filetype == "png" ||
        item.filetype == "jpg" ||
        item.filetype == "jpeg"||
         item.filetype == "PNG" ||
        item.filetype == "JPG" ||
        item.filetype == "JPEG"
      ) {
        this.selectedNoticetype = "image";
        this.fileImgSrc = item.url;
         this.showItems = false;
         if( this.selectedfileGroup.inboundOutboundStatus !="outbound")
           {this.setReadStatus([item.filehistory_id]);}
      }
      else{
        this.$toast.show(
          "Only PDF and image can be previewed",
          {
            type: "warning ",
            position: "top-right",
          }
        );
      }

    },
    downloadSingleFile(file)
    {
     
      if (
        this.currentUser.planDetails.downloadNotice ||
        this.isDownloadNotice
      ) {
        this.isLoading = true;
        let isSendr = false;
        if (this.selectedfileGroup.inboundOutboundStatus == "outbound") {
          isSendr = true;
        }
        let body = {
          Notice_fileid:  file.filehistory_id,
          userdetails_id:this.$cookie.getCookie("userdetails_id"),
          sender:isSendr,

        };
       
        this.$store
          .dispatch(DOWNLOAD_SINGLE_NOTICE, body)
          .then((data) => {
            this.isLoading = false;
            let blob = new Blob([data], { type: "application/zip" }),
              url = window.URL.createObjectURL(blob);
            window.open(url);
            if( this.selectedfileGroup.inboundOutboundStatus !="outbound")
           { this.setReadStatus( [file.filehistory_id]);
            this.setDownloadStatus( [file.filehistory_id]);}
          })
          .catch(() => {
            //intentional empty- catch
          });
      } else {
        this.$toast.show(
          "Your plan doesn't have permission to download notice",
          {
            type: "error ",
            position: "top-right",
          }
        );
      }
    },
    setReadStatus(fileid) {
      let body = {
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
        file_id: fileid,
      };

      this.$store
        .dispatch(ADD_FILE_READ, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } 
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    setDownloadStatus(fileid) {
      let body = {
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
        file_id: fileid,
      };

      this.$store
        .dispatch(ADD_FILE_DOWNLOAD, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } 
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    pdfNextPage() {
      
      if (this.pageCount > this.pageNumer) {
        this.pageNumer = this.pageNumer + 1;
      }
    },
    addURL(url) {
      this.isLoading = true;
      this.src = pdf.createLoadingTask(url);
      this.src.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
        this.isLoading = false;
        
      });
    },
    pdfPrevPage() {
      if (this.pageNumer > 1) {
        this.pageNumer = this.pageNumer - 1;
      }
    },
    next(page, isNext) {
      let currentPage = 1;
      if (isNext) {
        currentPage = page + 1;
      } else {
        currentPage = page;
      }
      this.offset = (currentPage - 1) * this.limit;
      this.getOneBoxList(this.offset);
    },
    refreshOnNotification() {
      
        this.getOneBoxList(this.offset);
        this.getPermissions();
       
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalCount / this.limit);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    onLimitChange(event) {
      this.limit = 0;
      this.limit = event.target.value;
       this.setPages();
      this.getOneBoxList(0);
    },
    onListChange(event) {
     
      this.listFilterKey = event.target.value;
      this.getOneBoxList(0);
    },

    moment: function(date) {
      return moment(date);
    },

    getPermissions: function() {
      let body = {
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };
      
      this.$store
        .dispatch(LISTUSER_PERMISSIONS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            
            data.data.map(item =>
               {
                 if(item.permissionType == 'Notice Settings')
                 {
                   this.isSendNotice =  true;
                 }
                 if(item.permissionType == 'Attachment Settings')
                 {
                   this.isDownloadNotice =  true;
                 }
               })
              
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    startDownload(item) {
     
      if (
        this.currentUser.planDetails.downloadNotice ||
        this.isDownloadNotice
      ) {
        this.isLoading = true;
        let isSendr = false;
        if (item.inboundOutboundStatus == "outbound") {
          isSendr = true;
        }

        let body = {
          Notice_historyid:  this.selectedfileGroup.id,
userdetails_id:this.$cookie.getCookie("userdetails_id"),
sender:isSendr,

        };

        this.$store
          .dispatch(DOWNLOAD_NOTICE, body)
          .then((data) => {
            this.isLoading = false;
            let blob = new Blob([data], { type: "application/zip" }),
              url = window.URL.createObjectURL(blob);
            window.open(url);
            
            if( this.selectedfileGroup.inboundOutboundStatus !="outbound")
            {
              this.setReadStatus(this.noticeItemDetails.map(item =>  item.filehistory_id));
              this.setDownloadStatus(this.noticeItemDetails.map(item =>  item.filehistory_id));
              this.getOneBoxList(this.offset)
            }
          })
          .catch(() => {
           
//intentional - catch error
          });
      } else {
        this.$toast.show(
          "Your plan doesn't have permission to download notice",
          {
            type: "error ",
            position: "top-right",
          }
        );
      }
    },
    searcNotice() {
      // if there is no search keyword call onebox listing
      if (this.searchKeyWord == "") {
        this.getOneBoxList(0);
        return;
      }
      this.isLoading = true;

      // For corporate admins give company id , leave deliveryAccountId blank
      // For users give deliveryAccountId and leave companyId blank
      let idList = this.deliveryAccounts?.map((item) => item.Deliveryaccount_id);

      let body = {
        companyId:
          this.currentUser.userRole == "User"
            ? this.currentUser.companyId
            : this.currentUser.userId,
        deliveryId: idList,
        search: this.searchKeyWord,
        startDate: this.dateLowerSearch,
        endDate: this.dateUpperSearch,
        limit: this.limit, // should be page count
        offset: 0, // should be the page number
        userRole : this.currentUser.userRole=='Super Admin' ? 'super admin' : ""
      };
      this.$store
        .dispatch(GET_ONE_BOX_LIST, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.oneBoxList = data.data;
            this.totalCount = data.totalCount;
            this.setPages();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    openModel(item) {
      let modal = document.getElementById("myModal");
      let tableNotices =document.getElementById("tableNotices");
      // Get the <span> element that closes the modal

      // When the user clicks the button, open the modal
      
      modal.style.display = "block";
      tableNotices.style.display = "none"
      this.selectedfileGroup = item.notices;
      this.downloadId = item;
      this.getNoticeFullDetails(item.id);
    },

    getNoticeFullDetails(item) {
      
       this.selectedNoticeitem=item;
        
      let modal = document.getElementById("myModal");
 let tableNotices =document.getElementById("tableNotices");
      let body = {
        filefistory_id: item.id,
      };

      this.isLoading = true;
      this.$store
        .dispatch(ONE_BOX_NOTICE_DETAILS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
           
            modal.style.display = "block";
          tableNotices.style.display ="none"
            this.selectedfileGroup = item;
            
            this.downloadId = item;
            this.noticeItemDetails = data.data;
             
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    closeModel() {
      let modal = document.getElementById("myModal");
       let tableNotices =document.getElementById("tableNotices");
      modal.style.display = "none";
      tableNotices.style.display = "block";
      this.showItems = true;
      this.openModelStatus = false;
    },
    getOneBoxList(offset) {
      let company_Id;
      let deliveryIdList;

      if (this.$cookie.getCookie("COMPANY_ID")) {
        company_Id = this.$cookie.getCookie("COMPANY_ID");
      } else {
        company_Id = this.$cookie.getCookie("USER_ID");
      }
      let detailsbody = {
        companyId: company_Id,
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };

      this.$store
        .dispatch(LIST_DELIVERY_ACCOUNTS, detailsbody)
        .then((data) => {
         
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.deliveryAccounts = data.data;
            this.userCompanyId = data.companyId;
            deliveryIdList = data.data.map((item) => item.Deliveryaccount_id);
            
            this.getListItemsOneBox(offset, deliveryIdList);
            
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getListItemsOneBox(offset, deliveryIdList) {
      let body = {
        filter: this.listFilterKey,
        search: "",

        deliveryId: deliveryIdList,
        limit: this.limit,
        offset: offset,
          startDate: this.dateLowerSearch,
        endDate: this.dateUpperSearch,
        userRole : this.currentUser.userRole=='Super Admin' ? 'super admin' : ""
      };
      
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_BOX_LIST, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.offset=offset;
            this.oneBoxList = data.data;
            this.totalCount = data.totalCount;
            this.setPages();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.sendnotice
{
  border-radius: 6px;
background-color: #27588D;
color: #fff;
}
.sendnotice:hover
{
  background-color: #00A5B1;
  cursor: pointer;
}
.v3dp__datepicker {
  .v3dp__input_wrapper {
    input {
      width: 138px !important;
      border: 1px solid #d1d3e2 !important;
      border-radius: 1px !important;
      color: #626262 !important;
      font-size: 16px !important;
      padding-left: 39px !important;
    }
  }
}
.dataTables_length {
  gap: 56px;
  display: flex;
}
.read-download-details {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 35px;
  font-size: 12px;
}
h6 {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}

.file-list-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 180px));
  grid-gap: 1rem;
}
.file-details-name {
  border: 1px solid rgb(233, 236, 236);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  width: fit-content;
  flex-direction: row;
  grid-gap: 1rem;
  align-items: center;
  .file-name {
    font-size: 11px;
  }
  .file-icon {
    font-size: 24px;

    .file-icon-pdf {
      color: indianred;
    }
    .file-icon-image {
      color: lightskyblue;
    }
    .file-icon-doc {
      color: darkseagreen;
    }
  }
}
.file-details-name:hover {
  border: 1px solid rgb(0, 0, 0);
  .file-name {
    font-size: 11px;
  }
  .file-icon {
    font-size: 24px;
    .file-icon-pdf {
      color: red;
    }
    .file-icon-image {
      color: dodgerblue;
    }
    .file-icon-doc {
      color: forestgreen;
    }
  }
}

.table-icons {
  width: 24px;
}
.noData {
  justify-content: center;
  height: 100px;
  display: flex;
  align-items: center;
}
.table-icons-view {
  width: 28px;
}
.table-cell-adjust {
  text-align: center;
}
table.table-bordered {
  border: 1px rgb(233, 236, 236);
  margin-top: 20px;
  text-align: center;
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgb(233, 236, 236);
}
table.table-bordered > tbody > tr > td {
  border: 1px solid rgb(233, 236, 236);
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
button.page-link {
  display: inline-block;
}

/*  */
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.noticeDetailsPage{
display: none;
}
/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;

}
/*  */

.bookmarker
{
  color: #4e73df;
border: 0px;
background: white;
cursor: pointer;
:hover
{
  background: rgba(0,0,0,.075);
}
}
</style>
