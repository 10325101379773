<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <div class="col-lg-7">
                  <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                  </div>
                  <div class="p-3">
                    <div class="text-center">
                      <h4 class="text-dark mb-4">Welcome Back!</h4>
                    </div>
                    <form
                      id="loginForm"
                      v-if="!userChoice"
                      class="user"
                      @submit.prevent="submitForm"
                      novalidate
                    >
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          v-bind:class="{
                            'is-invalid': !isEmailValid() && submitted,
                          }"
                          type="email"
                          name="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                          v-on:change="onChange"
                          v-model="email"
                        />

                        <p>
                          <small
                            class="text-danger"
                            v-if="!isEmailValid() && submitted"
                            >Valid email id is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group">
                        <div style="position: relative">
                          <input
                            class="form-control form-control-user"
                            :class="{ 'is-invalid': !password && submitted }"
                            :type="showPassword ? 'text' : 'password'"
                            maxlength="20"
                            name="password"
                            placeholder="Password"
                            v-on:change="onChange"
                            v-model="password"
                          />
                          <em
                            v-show="!showPassword && password"
                            @click="togglePasswordVisibility"
                            class="fas fa-eye fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                          <em
                            v-show="showPassword && password"
                            @click="togglePasswordVisibility"
                            class="fas fa-eye-slash fa-sm fa-fw text-black-400"
                            style="
                              position: absolute;
                              right: 20px;
                              top: 50%;
                              transform: translate(0, -50%);
                            "
                          ></em>
                        </div>
                        <p>
                          <small
                            class="text-danger"
                            v-if="!password && submitted"
                            >Password is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group">
                        <div class="custom-control custom-checkbox small">
                          <div class="form-check startalign">
                            <input
                              class="form-check-input custom-control-input"
                              type="checkbox"
                              id="formCheck-1"
                              v-model="checked"
                            />
                            <label
                              class="form-check-label custom-control-label"
                              for="formCheck-1"
                              >Remember Me</label
                            >
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary btn-block text-white btn-user mx-0"
                        type="submit"
                        :disabled="submitStatus === 'PENDING'"
                      >
                        Login
                      </button>

                      <hr />
                    </form>
                    <div id="userChoice" v-if="userChoice">
                      <div class="form-group" v-if="companies.length > 1">
                        <select
                          id="selectCompany"
                          class="form-control"
                          v-model="selected"
                        >
                          <option :selected="true" disabled>
                            Select Company Name
                          </option>
                          <option
                            v-for="company in companies"
                            v-bind:key="company.companyId"
                            v-bind:value="company.companyId"
                          >
                            {{ company.companyName }}
                          </option>
                        </select>
                      </div>
                      <button
                        class="btn btn-primary btn-block mx-0"
                        @click="companySelect"
                      >
                        Proceed
                      </button>
                    </div>
                    <hr />
                    <div class="text-center">
                      <router-link
                        :to="{ name: 'ForgotPassword' }"
                        class="small"
                        >Forgot Password?</router-link
                      >
                    </div>
                    <div class="text-center">
                      <router-link to="/createAccount" class="small"
                        >Create an Account!</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LOGIN,
  SET_USER,
  SET_DELVERY_ACCOUNT_ITEM,
  GET_DELIVERY_ACCOUNTS,
  SET_ALL_DELIVERY_ACCOUNT_ITEM,
  SET_TEMP_USER,
} from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import firebaseMessaging from "../plugins/firebase";
import jwtService from "../api/jwt.service";

export default {
  name: "Login",
  components: {
    Loading,
  },

  created() {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          firebaseMessaging.onBackgroundMessage(function (payload) {
            console.log("Message is received", payload);
            // Customize notification here
          });
        }
      });
    } catch (err) {
      // intentional - catch error
    }
  },

  beforeMount() {
    if (this.$cookie.isCookieAvailable("EMAIL")) {
      this.checked = this.$cookie.isCookieAvailable("EMAIL");
      this.email = this.$cookie.getCookie("EMAIL");
      // Need to encrypte it
      this.password = this.$cookie.getCookie("PASSWORD");
    } else {
      this.email = "";
      this.password = "";
    }
  },
  methods: {
    handleBlur(key) {
      this.$v[key].$dirty = true;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    isEmailValid: function () {
      const emailRegex = /\S+@\S+\.\S+/;
      return this.email == ""
        ? false
        : emailRegex.test(this.email)
        ? true
        : false;
    },

    submitForm() {
      this.isLoading = true;
      this.submitted = true;
      //if already logged-in
      if (jwtService.getToken()) {
        this.$router.replace({ name: "Onebox" });
      }

      if (!this.password || !this.isEmailValid()) {
        this.submitStatus = "ERROR";
        this.isLoading = false;
      } else {
        // Store to cookie
        if (this.checked) {
          this.$cookie.setCookie("EMAIL", this.email);
          this.$cookie.setCookie("PASSWORD", this.password);
        } else {
          this.$cookie.removeCookie("EMAIL", {
            path: "/",
            domain: "",
          });
          this.$cookie.removeCookie("PASSWORD", {
            path: "/",
            domain: "",
          });
        }

        this.onLogin(this.email, this.password, this.fcmToken);
      }
    },
    onCreateAccount() {
      this.$v.$reset();

      this.$router.replace({ name: "Dashboard" });
    },

    onChange(e) {
      //if already logged-in
      if (jwtService.getToken()) {
        this.$router.replace({ name: "Dashboard" });
      }
      if (e.target.name == "email") {
        this.email = e.target.value;
      } else if (e.target.name == "password") {
        this.password = e.target.value;
      }
    },
    onLogin(email, password) {
      this.isLoading = true;

      firebaseMessaging
        .getToken({
          vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
        })
        .then((currentToken) => {
          if (currentToken) {
            this.continueLogin(email, password, currentToken); // Continue with the login process using currentToken
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            this.continueLogin(email, password, null); // Continue with the login process without the token
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token.", err);
          this.continueLogin(email, password, null); // Continue with the login process even if token retrieval fails
        });
    },
    continueLogin(email, password, registrationToken) {
      this.$store
        .dispatch(LOGIN, { email, password, registrationToken })
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.tempData = data.data;

            this.$cookie.setCookie(
              "ADDRESS",
              this.checkDataItem(data.data.address)
            );
            this.$cookie.setCookie(
              "PHONE",
              this.checkDataItem(data.data.phone)
            );
            this.$cookie.setCookie("CITY", this.checkDataItem(data.data.city));
            this.$cookie.setCookie(
              "COUNTRY",
              this.checkDataItem(data.data.country)
            );

            if (data.data.companyaccounts.length) {
              if (data.data.companyaccounts.length > 1) {
                // populate companies dropdown if multiple companies are associated
                this.companies = data.data.companyaccounts;
                this.tempData.userId = data.data.userId;
                this.tempData.twofactorEnable = data.data.twofactorEnable;

                this.userChoice = true;
                this.submitStatus = "PENDING";
              } else {
                this.tempData.userId = data.data.userId;
                this.tempData.userdetails_id =
                  data.data.companyaccounts[0].userdetails_id;
                this.tempData.companyName =
                  data.data.companyaccounts[0].companyName;
                this.tempData.companyId =
                  data.data.companyaccounts[0].companyId;
                this.tempData.companyAlias =
                  data.data.companyaccounts[0].companyAlias;
                this.tempData.authToken =
                  data.data.companyaccounts[0].authToken;

                this.tempData.userRole = this.getUserRole(
                  data.data.companyaccounts[0].roleId
                );
                this.tempData.roleId = data.data.companyaccounts[0].roleId;

                this.tempData.planDetails =
                  data.data.companyaccounts[0].planDetails;

                if (
                  data.data.companyaccounts[0].planDetails.noticeCount === -1
                ) {
                  this.tempData.planDetails.noticeCount = "Unlimited";
                }

                this.tempData.twofactStatus =
                  data.data.companyaccounts[0].twofactStatus;

                this.$store.dispatch(SET_USER, this.tempData);
                this.$store.dispatch(SET_TEMP_USER, this.tempData);

                this.$store.dispatch(SET_DELVERY_ACCOUNT_ITEM, {
                  Deliveryaccount_id: "",
                  Deliveryaccount: "",
                  Deliveryaccount_Alias: "All",
                });

                //this.$cookie.setCookie("ID_TOKEN_KEY", data.data.companyaccounts[0].authToken);

                this.allowLogin(this.tempData);
              }
            } else {
              //check user role here to search for SUPER ADMIN
              if (data.data.userRole == 1) {
                //SUPER ADMIN
                this.tempData.userId = data.data.userId;
                this.tempData.companyId = "";

                this.tempData.authToken = data.data.authToken;
                this.tempData.userRole = "Super Admin";

                this.$store.dispatch(SET_USER, this.tempData);

                this.$store.dispatch(SET_DELVERY_ACCOUNT_ITEM, {
                  Deliveryaccount_id: "",
                  Deliveryaccount: "",
                  Deliveryaccount_Alias: "All",
                });

                //this.$cookie.setCookie("ID_TOKEN_KEY", data.data.authToken);
                //2FA of superadmin is Always Active!
                this.tempData.twofactStatus = "active";
                this.allowLogin(this.tempData);
              } else {
                //either company or user account is deactivated
                this.$toast.show("Access Denied! Please Contact Admin!", {
                  type: "error ",
                  position: "top-right",
                });
              }
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    checkDataItem(dataItem) {
      return dataItem ? dataItem : "";
    },
    getUserRole(userRoleId) {
      if (userRoleId == 1) {
        return "Super Admin";
      } else if (userRoleId == 2) {
        return "Corporate Admin";
      } else {
        return "User";
      }
    },
    allowLogin(data) {
      this.$cookie.setCookie(
        "PLAN-PRICE",
        data.planDetails ? data.planDetails.price : ""
      );
      this.$cookie.setCookie(
        "COMPANY_ID",
        data.companyId ? data.companyId : ""
      );
      this.$cookie.setCookie(
        "COMPANY_NAME",
        data.companyName ? data.companyName : ""
      );
      this.$cookie.setCookie(
        "COMPANY_ALIAS",
        data.companyAlias ? data.companyAlias : ""
      );
      this.$cookie.setCookie("ROLE", data.userRole);
      this.$cookie.setCookie("USER_ID", data.userId);
      this.$cookie.setCookie(
        "userdetails_id",
        data.userdetails_id ? data.userdetails_id : ""
      );
      this.$cookie.setCookie("ID_TOKEN_KEY", data.authToken);

      if (data.twofactStatus == "active") {
        this.$router.replace({ name: "TwoFactorAuth" });
      } else {
        this.$router.replace({ name: "Dashboard" });
        this.getDeliveryAccount();
      }
    },
    companySelect() {
      let selectCompany = document.getElementById("selectCompany");
      if (selectCompany.value != "Select Company Name") {
        this.companies.filter((item) => item.companyId == selectCompany.value);

        this.submitStatus = "SUBMITTED";
        this.userChoice = false;
        let companyChooseData = this.companies.filter(
          (item) => item.companyId == selectCompany.value
        );

        this.tempData.twofactStatus = companyChooseData[0].twofactStatus;
        this.tempData.userdetails_id = companyChooseData[0].userdetails_id;
        this.tempData.companyName = companyChooseData[0].companyName;
        this.tempData.companyId = companyChooseData[0].companyId;
        this.tempData.companyAlias = companyChooseData[0].companyAlias;
        this.tempData.authToken = companyChooseData[0].authToken;

        this.tempData.planDetails = companyChooseData[0].planDetails;

        if (companyChooseData[0].planDetails.noticeCount === -1) {
          this.tempData.planDetails.noticeCount = "Unlimited";
        }

        companyChooseData[0].roleId == 2
          ? (this.tempData.userRole = "Corporate Admin")
          : (this.tempData.userRole = "User");
        this.tempData.roleId = companyChooseData[0].roleId;

        this.$store.dispatch(SET_USER, this.tempData);
        this.$store.dispatch(SET_DELVERY_ACCOUNT_ITEM, {
          Deliveryaccount_id: "",
          Deliveryaccount: "",
          Deliveryaccount_Alias: "All",
        });
        this.allowLogin(this.tempData);
      } else {
        selectCompany.focus();
      }
    },
    getDeliveryAccount() {
      let body = {
        companyId: this.$cookie.getCookie("COMPANY_ID"),
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };
      this.$store
        .dispatch(GET_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          if (Array.isArray(data.data)) {
            this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);
          }
        })
        .catch(() => {
          //intentional empty
        });
    },
  },
  computed: {
    users() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      checked: false,
      isLoading: false,
      fullPage: true,
      email: null,
      password: null,
      showPassword: false,
      submitted: false,
      submitStatus: null,
      fcmToken: "",
      selected: "Select Company Name",
      companies: [],
      tempData: null,
      userChoice: false,
    };
  },
  validations: {},
};
</script>

<style scoped>
.backdrop {
  height: 100vh;
}
</style>
