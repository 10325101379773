export const FETCH_END = "setArticles";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_ARTICLE = "setArticle";
export const SET_AUTH = "setUser";
export const SET_DELVERY_ACCOUNT = "setDeliveryAccount";
export const SET_ALL_DELIVERY_ACCOUNT = "setAllDeliveryAccount";
export const TEMP_USER = "tempUser";
export const SET_COMMENTS = "setComments";
export const SET_ERROR = "setError";
export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_ACCOUNT_TYPES = "updateAccountTypes";
export const RESET_STATE = "resetModuleState";
export const UPDATE_DELIVERY_ACCOUNTS = "updateDeliveryAccounts";
export const UPDATE_CONTACT_DETAILS = "updateContactDetails"
export const UPDATE_CORPORATE_USERS = "updateCorporateUsers"
export const SET_CORPORATE_USERS = "setCorporateUsers"
export const APPEND_CORPORATE_USER = "appendCorporateUser"
export const SET_ACCOUNT_IDS = "setAccountIds"
export const UPDATE_PROFILE_IMAGE = "updateProfileImage"
export const LIST_PERMISSIONS = "listPermission"
export const SELECTED_USER = "selectedUser"
export const SET_SUBSCRIPTIONS_TIER = "setSubscriptionTiers"
export const NOTICE_SOURCES = "noticeSources"
export const SET_NOTIFICATIONS = "setNotifications";
export const PROFILE_IMAGE = "profileImage"
export const SET_COUPONS = "setCoupons"