<template>
  <div id="wrapper">
    <left-drawer />
     <Loading :active="isLoading" :can-cancel="true"></Loading>
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />      
          <div class="container-fluid" id="TableSorterCard">

            <h3 class="text-dark mb-0 pt-2 pb-4">Payment Transactions</h3>

            <div class="row table-topper align-items-center">
              <div class="col-8 pt-2 pb-2">
                    <div
                        style="display: flex;
                          align-content: center;
                          align-items: end;
                          gap: 11px;"
                      >
                        <div>
                          Start Date
                          <datepicker
                            v-model="dateLower"
                            :upperLimit="dateUpper"

                          />
                        </div>
                        <div>
                          End Date
                          <datepicker
                            v-model="dateUpper"
                            :lowerLimit="dateLower"
                          />
                        </div>
                        <div>
                          
                          <button
                            style="margin-left: 0px;
                                      margin-bottom: 0px;
                                      "
                            class="btn theme-btn text-light btn-sm d-none d-sm-inline-block"
                            v-if="dateLower || dateUpper"
                            @click="clearFilter()"
                          >
                            Clear
                          </button>
                          <button
                            style="margin-left: 0px;
                                    margin-bottom: 0px;
                                    margin-right: 0px;"
                            class="btn  theme-btn text-light  btn-sm d-none d-sm-inline-block"
                            v-if="dateLower && dateUpper"
                             @click="searchOnDate()"

                          >
                            Search
                          </button>
                        </div>
                    </div>
              </div>
              <div
                class="col-4 text-right"
                style="margin: 0px; padding: 5px 10px"
              >
                <input type="search" 
                v-model="searchKeyWord"
                placeholder="Search by Firstname"
                @change="searchPayment"/><a
                  href="#"
                  data-toggle="modal"
                  data-target="#tablehelpModal"
                  ><i
                    class="fa fa-search"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Custom Sort Details"
                    aria-hidden="true"
                    style="padding: 5px 15px; margin: 2px"
                  ></i
                ></a>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table class="table table tablesorter" id="ipi-table">
                    <caption> </caption>
                    <thead class="theme-tbl-head text-light">
                      <tr class="text-center">
                        <th id="name">Full Name</th>
                        <th id="email" class="sorter-false">Account Email</th>
                        <th id="date" class="filter-false">Creation Date</th>
                        <th id="plan" class="filter-false sorter-false">
                          Plan Name
                        </th>
                        <th id="amt">Payment Amount</th>
                        <th id="mthd">Payment Method</th>
                        <th id="tId">Transaction Id</th>
                        <th id="note">Notes</th>
                        <th id="inv">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in accountList" v-bind:key="item.id">
                        <td class="text-center">{{ item.User.firstName +" "+ item.User.lastName }}</td>
                        <td class="text-center">{{ item.User.email }}</td>
                        <td class="text-center">
                          {{
                            moment(item.createdAt).format(
                              "YYYY, MMM - DD hh:mm A"
                            )
                          }}
                        </td>
                        <td class="text-center">{{ item.plan }}</td>
                        <td class="text-center">{{ item.amount/100 }}</td>
                        <td class="text-center">{{ item.method }}</td>
                        <td class="text-center">{{ item.transactionId }}</td>
                        <td class="text-center">{{ item.notes ? 
                                                   item.notes :
                                                   item.paymentStatus 
                                                  }}</td>
                        <td class="text-center">
                         
                            <a class="btn theme-btn text-light btn-sm btn-theme border-0"
                                v-if="item.receipt_url"
                                role="button"
                                v-on:click="invoice(item.receipt_url)"
                            >View Invoice</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="accountList && accountList.length">
                  <div class="col-md-6 align-self-center">
                    <p
                      id="dataTable_info"
                      class="textPadding"
                      role="status"
                      aria-live="polite"
                      v-if="accountList"
                
                    >
                      Showing {{offset+1}} to {{offset + accountList.length}} of {{totalCount}}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <nav
                      class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers textPadding"
                    >
                      <ul class="pagination">
            
               <li class="page-item">
					<button type="button" class="page-link" 
          v-for="pageNumber in pages" :key="pageNumber"
           @click="next(page = pageNumber)"> 
          {{pageNumber}} 
          </button>
				</li>
        
                </ul> 
                    </nav>
                  </div>
                </div>
                <div class="row pl-4" v-else>
                   No Results found!
                </div>
              </div>
            </div>
            <div class="row pt-2 pb-4">
              <!-- spacing between fixed footer and the page content -->
            </div>
          </div>  
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import LeftDrawer from "./LeftDrawer.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Datepicker from "vue3-datepicker";
import moment from "moment";

import { LIST_PAYMENTS } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
export default {
  components: {
    LeftDrawer,
    Header,
    Footer,   
    Loading,
    Datepicker
  },
  beforeMount() {
    this.getPaymentsList(0,"");
  },

  data() {
    return {
      isLoading: false,
      accountList: null,

      dateUpper: "",
      dateLower: "",
      dateLowerSearch: "",
      dateUpperSearch: "",

      totalCount: "",
      limit: 10,
      offset: 0,
      pages:[],
      page:1,
      searchKeyWord:'',
      showCorporateProfile: false,
      selectedCorporateId: "",
      selectedCompany: "",
    };
  },
  methods: {
    invoice(url)
    {
      window.open(url,"_blank")
    },
      searchPayment(){  
      if (this.searchKeyWord == "") {
        this.getPaymentsList(0,"");
      }else{
          this.getPaymentsList(0,this.searchKeyWord)
      }

      },
    next(page, isNext){
      let currentPage = 1
      if(isNext){
      currentPage = page+1
      }else{
      currentPage = page
      }
      this.offset = (currentPage-1)*10 
      this.getPaymentsList(this.offset,this.searchKeyWord==''?'':this.searchKeyWord)
    },
    setPages () {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalCount / this.limit);
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index);
      }
		},
    moment: function (date) {
      return moment(date);
    },

     getPaymentsList(offset, searchWord) {
      let body = {
        search:searchWord,
        limit: this.limit,
        offset: offset,
        startDate:this.dateLowerSearch,
        endDate:this.dateUpperSearch
      };
      this.isLoading = true
      this.$store
        .dispatch(LIST_PAYMENTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.accountList = data.data.resp;
            this.totalCount = data.data.totalcount;
            this.setPages()
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    searchOnDate()
    {
      this.dateUpperSearch =this.convertDatePickerTimeToMySQLTime(this.dateUpper,0);
      this.dateLowerSearch =this.convertDatePickerTimeToMySQLTime(this.dateLower,0);
      let flagMessage = this.checkValidDate(this.dateLowerSearch,this.dateUpperSearch) 
      if(flagMessage == "PROCEED")
      {
        this.dateUpperSearch =this.convertDatePickerTimeToMySQLTime(this.dateUpper,1);
        this.getPaymentsList(0,this.searchKeyWord); 
      }
      else
      {
        this.dateUpperSearch = '';
        this.dateLowerSearch = '';
        this.$toast.show(flagMessage, {
              type: "error ",
              position: "top-right",
            })
      }
    },
    clearFilter()
    {
      this.dateUpper = "";
      this.dateLower = "";
      this.dateUpperSearch = "";
      this.dateLowerSearch = "";
      this.getPaymentsList(0,this.searchKeyWord)
    },
    convertDatePickerTimeToMySQLTime(str,endDate) {

        let date = new Date(str);

        if(endDate)
        {
          date.setDate(date.getDate()+1)
        }
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        

        var mySQLDate = [date.getFullYear(), month, day].join("-");
        
        return mySQLDate
    },
    checkValidDate(start,end)
    {
      let proceedMessage = "PROCEED"
      let today = new Date();
      if(start =="" || end == "")
      {
        proceedMessage = "Invalid Date!"
        return proceedMessage
      }
      let startDate = new Date(start)
      let endDate = new Date(end)
      if(startDate.getFullYear() != endDate.getFullYear())
      {
        proceedMessage = "Please Choose Date-range within an year!"
      }
      else if(today.getFullYear() < startDate.getFullYear())
      {
        proceedMessage = "No data available in the chosen date-range!"
      }
      else if(today < startDate)
      {
        proceedMessage = "No data available in the chosen date-range!"
      }
      return proceedMessage;
    }
  },
};
</script>

<style scoped>
.breadScrum {
  cursor: pointer;
}
button.page-link {
	display: inline-block;
}
.textPadding {
    padding: 10px;
}
.tableBackground{
    background: white;
}
</style>