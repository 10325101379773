<template>
  <div id="wrapper">
    <left-drawer />
      <Loading :active="isLoading"></Loading>
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
        <div class="container-fluid">
          <h3 class="text-dark mb-4">Profile: Super Administrator</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a class="breadScrum" @click="showCorporateList"
                  >Super Administrator</a
                >
              </li>
              <li
                v-if="selectedCompany"
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ selectedCompany }}
              </li>
            </ol>
          </nav>
        </div>
        <div
          v-if="!showCorporateProfile"
          class="container d-flex flex-grow-1 flex-fill p-0"
        >
          <div class="card" id="TableSorterCard">
            <div class="row table-topper align-items-center">
              <div
                class="col-4 text-left"
                style="margin: 0px; padding: 5px 15px; display: none"
              >
                <button
                  class="btn btn-primary btn-sm reset"
                  type="button"
                  style="padding: 5px; margin: 2px"
                >
                  Reset Filters</button
                ><button
                  class="btn btn-warning btn-sm"
                  id="zoom_in"
                  type="button"
                  zoomclick="ChangeZoomLevel(-10);"
                  style="padding: 5px; margin: 2px"
                >
                  <em class="fa fa-search-plus"></em></button
                ><button
                  class="btn btn-warning btn-sm"
                  id="zoom_out"
                  type="button"
                  zoomclick="ChangeZoomLevel(-10);"
                  style="padding: 5px; margin: 2px"
                >
                  <em class="fa fa-search-minus"></em>
                </button>
              </div>
              <div
                class="col-4 text-center"
                style="margin: 0px; padding: 5px 10px; display:none"
              >
                <h6 id="counter">
                  Showing: <strong id="rowCount">ALL</strong>&nbsp;Row(s)
                </h6>
              </div>
              <div
                class="col-12 text-right"
                style="margin: 0px; padding: 5px 10px"
              >
              <label for="">Search: &nbsp;</label>
                <input type="search"
                v-model="searchKeyWord"
                placeholder="Enter search term"
                @change="searchAccount" /><a
                  href="#"
                  data-toggle="modal"
                  data-target="#tablehelpModal"
                  >
                  <i
                    class="fa fa-search"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Custom Sort Details"
                    aria-hidden="true"
                    style="padding: 5px 15px; margin: 2px;"
                  ></i>
                  </a>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table tablesorter" id="ipi-table">
                    <!-- thead-dark -->
                    <caption> </caption>
                    <thead class="theme-tbl-head text-light">
                      <tr class="text-center d-flex">
                        <th id="acNo" class="col-2">Account Number</th>
                        <th id="email" class="sorter-false col-2">
                          Account Email
                        </th>
                        <th id="cDate" class="filter-false col-2">
                          Creation Date
                        </th>
                        <th id="adName" class="filter-false sorter-false col-2">
                          Administrator Name
                        </th>
                        <th id="acType" class="col-1">Account Type</th>
                        <th id="acUsers" class="col-1">Active Users</th>
                        <th id="adProf" class="filter-false sorter-false col-1">
                          Administrator profile update
                        </th>
                        <th id="acStatus" class="filter-false sorter-false col-1">
                          Account Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="d-flex"
                        v-for="item in accountList"
                        v-bind:key="item.companyId"
                      >
                      <!-- accountNumber -->
                        <td class="col-2">{{ item.admin_uniqueId }}</td>
                        <!-- accountEmail -->
                        <td class="col-2">{{ item.admin_Email }}</td>
                        <td class="col-2">
                          {{
                            moment(item.createdAt).format(
                              "YYYY, MMM - DD hh:mm A"
                            )
                          }}
                        </td>
                        <!-- accountName -->
                        <td class="col-2">{{ item.companyName }}</td>
                        <td class="col-1">{{ item.planName }}</td>
                        <td class="col-1">{{ item.userCount }}</td>
                        <td class="text-center col-1">
                          <!-- accountId, accountName btn-primary-->
                          <a
                            class="btn theme-btn text-light btn-sm"
                            role="button"
                            v-on:click="
                              onCorporateProfileShow(
                                item.companyId,
                                item.companyName
                              )
                            "
                            >Update</a
                          >
                        </td>
                        <td class="text-center col-1">
                          <!-- accountId btn-primary-->
                          <a
                            class="btn theme-btn text-light btn-sm"
                            role="button"
                            v-on:click="
                              updateAccountStatus(
                                item.companyId,
                                item.accountStatus
                              )
                            "
                            >{{
                              item.accountStatus == "active" ? "Disable" : "Enable"
                            }}</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row p-2">
                  <div class="col-md-6 align-self-center">
                    <p
                      id="dataTable_info"
                      class="dataTables_info"
                      role="status"
                      aria-live="polite"
                      v-if="accountList?.length>0"
                    >
                      Showing {{ offset + 1 }} to
                      {{ offset + accountList.length }} of {{ totalCount }}
                    </p>
                    <p v-else-if="searchKeyWord!=''">No search results for {{searchKeyWord}} </p>
                    <p v-else-if="accountList?.length==0 && searchKeyWord==''">No Data Available</p>
                  </div>
                  <div class="col-md-6">
                    <nav
                      class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                    >
                      <ul class="pagination">
                        <!-- <li class="page-item disabled">
                          <a class="page-link" href="#" aria-label="Previous"
                            ><span aria-hidden="true">«</span></a
                          >
                        </li> -->
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            v-for="pageNumber in pages"
                            :key="pageNumber"
                            @click="next((page = pageNumber))"
                          >
                            {{ pageNumber }}
                          </button>
                        </li>
                        <!-- <li class="page-item">
                          <button
                            type="button"
                            @click="next(page++, true)"
                            v-if="page < pages.length"
                            class="page-link"
                          >
                            Next
                          </button>
                        </li> -->
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="modal fade"
              role="dialog"
              tabindex="-1"
              id="tablehelpModal"
              aria-labeledby="tablehelpModal"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">Table Filtering Options</h4>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width: 1%">Priority</th>
                            <th style="width: 9%">Operator</th>
                            <th style="width: 30%">Description</th>
                            <th style="width: 60%">Examples</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <code>|</code>&nbsp;or&nbsp;&nbsp;<code>OR</code
                              ><br />
                            </td>
                            <td>
                              Logical "or" (Vertical bar). Filter the column for
                              content that matches text from either side of the
                              bar.<br />
                            </td>
                            <td>
                              <code>&lt;20 | &gt;40</code>&nbsp;(matches a
                              column cell with either "&lt;20" or "&gt;40")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <code>&amp;&amp;</code>&nbsp;or <code>AND</code
                              ><br />
                            </td>
                            <td>
                              Logical "and". Filter the column for content that
                              matches text from either side of the operator.<br />
                            </td>
                            <td>
                              <code>&gt;20 &amp;&amp; &gt;40</code
                              >&nbsp;(matches a column cell that contains both
                              "&gt;20" and "&lt;40")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td><code>/\d/</code><br /></td>
                            <td>
                              Add any regex to the query to use in the query
                              ("mig" flags can be
                              included&nbsp;<code>/\w/mig</code>)<br />
                            </td>
                            <td>
                              <code>/b[aeiou]g/i</code>&nbsp;(finds "bag",
                              "beg", "BIG", "Bug",
                              etc);<code>&gt;/r$/</code>&nbsp;(matches text that
                              ends with an "r")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td><code>&lt; &lt;= &gt;= &gt;</code><br /></td>
                            <td>
                              Find alphabetical or numerical values less than or
                              greater than or equal to the filtered query .<br />
                            </td>
                            <td>
                              <code>&lt;=10</code>&nbsp;(find values greater
                              than or equal to 10)<br />
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              <code>!</code>&nbsp;or&nbsp;<code>!=</code><br />
                            </td>
                            <td>
                              Not operator, or not exactly match. Filter the
                              column with content that&nbsp;<strong
                                >do not</strong
                              >&nbsp;match the query. Include an equal
                              (<code>=</code>), single (<code>'</code>) or
                              double quote (<code>"</code>) to
                              exactly&nbsp;<em>not</em>&nbsp;match a filter.<br /><br /><br />
                            </td>
                            <td>
                              <code>!e</code>&nbsp;(find text that doesn't
                              contain an "e");<code>!"ELISA"</code>&nbsp;(find
                              content that does not exactly match "ELISA")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              <code>"</code>&nbsp;or&nbsp;<code>=</code><br />
                            </td>
                            <td>
                              To exactly match the search query, add a quote,
                              apostrophe or equal sign to the beginning and/or
                              end of the query<br />
                            </td>
                            <td>
                              <code>abc"</code
                              >&nbsp;or&nbsp;<code>abc=</code>&nbsp;(exactly
                              match "abc")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>
                              <code>-</code>&nbsp;or <code>to</code><br />
                            </td>
                            <td>
                              Find a range of values. Make sure there is a space
                              before and after the dash (or the word "to").<br />
                            </td>
                            <td>
                              <code>10 - 30</code>&nbsp;or&nbsp;<code
                                >10 to 30</code
                              >&nbsp;(match values between 10 and 30)<br />
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td><code>?</code><br /></td>
                            <td>
                              Wildcard for a single, non-space character.<br />
                            </td>
                            <td>
                              <code>S?c</code>&nbsp;(finds "Sec" and "Soc", but
                              not "Seec")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td><code>*</code><br /></td>
                            <td>
                              Wildcard for zero or more non-space characters.<br />
                            </td>
                            <td>
                              <code>B*k</code>&nbsp;(matches "Black" and
                              "Book")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td><code>~</code><br /></td>
                            <td>
                              Perform a fuzzy search (matches sequential
                              characters) by adding a tilde to the beginning of
                              the query<br />
                            </td>
                            <td>
                              <code>~bee</code>&nbsp;(matches "Bruce Lee" and
                              "Brenda Dexter"),
                              or&nbsp;<code>~piano</code>&nbsp;(matches "Philip
                              Aaron Wong")<br />
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>text<br /></td>
                            <td>
                              Any text entered in the filter
                              will&nbsp;<strong>match</strong>&nbsp;text found
                              within the column<br />
                            </td>
                            <td>
                              <code>abc</code>&nbsp;(finds "abc", "abcd",
                              "abcde", etc);<code>SEC</code>&nbsp;(finds "SEC"
                              and "Analytical SEC")<br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-danger"
                      type="button"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <ProfileContent
          v-if="showCorporateProfile"
          v-bind:profile-id="selectedCorporateId"
          v-bind:from-super-admin="true"
        />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import LeftDrawer from "./LeftDrawer.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import ProfileContent from "./Profile/ProfileContent.vue";
import moment from "moment";
import Loading from "vue3-loading-overlay";
import { GET_ACCOUNTS, UPDATE_ACCOUNT_STATUS } from "@/store/actions.type";
export default {
  components: {
    LeftDrawer,
    Header,
    Footer,
    ProfileContent,
    Loading
  },
  beforeMount() {
    this.getAccountsList(0,"");
  },

  data() {
    return {
      isLoading: false,
      accountList: [],
      totalCount: "",
      limit: 10,
      offset: 0,
      pages: [],
      page: 1,
      searchKeyWord:'',
      showCorporateProfile: false,
      selectedCorporateId: "",
      selectedCompany: "",
    };
  },
  methods: {
    next(page, isNext) {
      let currentPage = 1;
      if (isNext) {
        currentPage = page + 1;
      } else {
        currentPage = page;
      }
      this.offset = (currentPage - 1) * 10;
      this.getAccountsList(this.offset,this.searchKeyWord==""?'':this.searchKeyWord);
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalCount / this.limit);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    onLimitChange(event) {
      this.limit = 0;
      this.limit = event.target.value;
      this.getAccountsList(0,"");
    },
    moment: function(date) {
      return moment(date);
    },

    showCorporateList() {
      this.showCorporateProfile = false;
      this.selectedCorporateId = "";
      this.selectedCompany = "";
    },
    onCorporateProfileShow(id, name) {
      this.showCorporateProfile = true;
      this.selectedCorporateId = id;
      this.selectedCompany = name;
    },

    updateAccountStatus(id, status) {
      let body = {
        companyId: id,
        accountStatus: status == "active" ? "inactive" : "active",
      };
      this.$store
        .dispatch(UPDATE_ACCOUNT_STATUS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.getAccountsList(this.offset,"");
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    searchAccount(){
        if (this.searchKeyWord == "") {
          this.getAccountsList(0,"");
        }else{
          this.getAccountsList(0,this.searchKeyWord)
      }
    },

    getAccountsList(offset,searchKey) {
      let body = {
        searchKey: searchKey,
        limit: this.limit,
        offset: offset,
      };
      this.isLoading = true
      this.$store
        .dispatch(GET_ACCOUNTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.accountList = data.data;
            this.totalCount = data.totalCount;
            this.setPages();
          }
        })
        .catch((error) => {
          this.accountList = error?.data;
          this.totalCount = error.totalCount;
          this.setPages();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.breadScrum {
  cursor: pointer;
}
button.page-link {
  display: inline-block;
}
</style>
