import axios from "axios";
import JwtService from "@/api/jwt.service";
import { API_URL } from "@/api/config";
import encrypter from "object-encrypter";
import router from "../router/index";

var engine = encrypter(process.env.VUE_APP_EK, { ttl: true });

export const Service = axios.create({
  baseURL: API_URL,
  timeout: 25000,
});

export const UploadService = axios.create({
  baseURL: API_URL,
  timeout: 1000000,
});

const handleRequest = async (req) => {
  let token = await JwtService.getToken();
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
};

const handleResponse = (response) => {
  return Promise.resolve(response.data);
};

const handleError = (error) => {
  let parsed_error = Object.assign({}, error);
  if (parsed_error?.response?.status === 401) {
    JwtService.destroyToken();
    router.push({ name: "Login" });
  }
  return Promise.reject(parsed_error?.response?.data);
};

Service.interceptors.request.use(handleRequest);
Service.interceptors.response.use(handleResponse, handleError);

UploadService.interceptors.request.use(handleRequest);
UploadService.interceptors.response.use(handleResponse, handleError);

export const post = (resource, params) => {
  if (params) {
    const hex = engine.encrypt(params, 100000);
    return Service.post(`${resource}`, { data: hex });
  } else {
    return Service.post(`${resource}`);
  }
};

export const postSendNotice = (resource, params) => {
  if (params) {
    return Service.post(`${resource}`, { data: params });
  } else {
    return Service.post(`${resource}`);
  }
};

export const postForResponseTypeBlob = (resource, params) => {
  if (params) {
    const hex = engine.encrypt(params, 100000);
    return Service.post(`${resource}`, { data: hex }, { responseType: "blob" });
  } else {
    return Service.post(`${resource}`);
  }
};
