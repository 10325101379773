import {
  SEND_NOTICE,
  CHECK_ACCOUNT,
  ADD_FILE_DOWNLOAD,
  ADD_FILE_READ,
  BOOKMARK_NOTICE,
  DOWNLOAD_SINGLE_NOTICE,
  DOWNLOAD_NOTICE,
  SEARCH_DELIVERY_ACCOUNT,
  LIST_ALL_FAVOURITES,
  ADD_FAVOURITE_ACCOUNT,
} from "../actions.type";
import { SET_ERROR } from "../mutations.type";
import { UploadService, postForResponseTypeBlob, post } from "@/api/service";
//Service
const state = {
  errors: null,
  accountTypes: "",
};

const actions = {
  [SEND_NOTICE](context, body) {
    return new Promise((resolve, reject) => {
      UploadService.post("v2/sendNotice", body)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEARCH_DELIVERY_ACCOUNT](context, body) {
    return new Promise((resolve, reject) => {
      post("v2/searchDeliveryAccount", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
  [CHECK_ACCOUNT](context, body) {
    return new Promise((resolve, reject) => {
      post("v3/checkValidAccountID", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_NOTICE](context, body) {
    return new Promise((resolve, reject) => {
      postForResponseTypeBlob("v2/downloadNotice", body)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [BOOKMARK_NOTICE](context, body) {
    return new Promise((resolve, reject) => {
      postForResponseTypeBlob("v2/bookmarkNotice", body)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_SINGLE_NOTICE](context, body) {
    return new Promise((resolve, reject) => {
      postForResponseTypeBlob("v2/singledownloadNotice", body)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_FILE_DOWNLOAD](context, body) {
    return new Promise((resolve, reject) => {
      post("v2/add_filedownload", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_FILE_READ](context, body) {
    return new Promise((resolve, reject) => {
      post("v2/add_fileread", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
  [LIST_ALL_FAVOURITES](context, body) {
    return new Promise((resolve, reject) => {
      post("v2/listAllFavorites", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
  [ADD_FAVOURITE_ACCOUNT](context, body) {
    return new Promise((resolve, reject) => {
      post("v2/addFavoriteAccount", body)
        .then((data) => {
          checkStatus(data, resolve, reject);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
};
function checkStatus(data, resolve, reject) {
  if (data.status == "failure") {
    reject(data);
  } else resolve(data);
}
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
};
