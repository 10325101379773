<template>
  <nav
    class="navbar  align-items-start sidebar side  accordion  p-0"
    v-bind:class="{ toggled : isOpen }"
  >
    <div class="container-fluid d-flex flex-column p-0">
      <a
        class="navbar-brand text-left d-flex justify-content-center align-items-center sidebar-brand m-0"
        href="#"
        style="background: #ffffff"
      >
        <div class="sidebar-brand-icon rotate-n-15"></div>
        <div class="sidebar-brand-text mx-1"></div>
        <img
          class="img-fluid d-flex d-xl-flex justify-content-center align-items-center align-content-center ml-auto justify-content-xl-center align-items-xl-center"
          src="@/assets/img/oneNotice/oneNoticeLogo.png"
          v-on:click="showDashboard"
          alt="oneNoticeLogo"
      /></a>
      <hr class="sidebar-divider my-0" />
      <ul class="nav navbar-nav text-light" id="accordionSidebar">
        <li class="nav-item">
         
          <a class="nav-link text-light clickable-Span" v-on:click="showDashboard"
            ><em class="fas fa-tachometer-alt"></em><span> Dashboard</span></a
          >
        </li>
        <li class="nav-item"></li>
        <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? false: true" class="nav-link text-light clickable-Span" v-on:click="showOnebox"
            ><em class="la la-inbox"></em><span> Onebox</span></a
          >
        </li>
        <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? true: false" class="nav-link text-light clickable-Span" v-on:click="showSuperAdminView"
            ><em class="fa fa-users" ></em><span> Super Admin View</span></a
          >
        </li>
         <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? true: false" class="nav-link text-light clickable-Span" v-on:click="showSubcriptionTiers"
            ><em class="fa fa-sitemap"></em><span> Subscription Tiers</span></a
          >
        </li>
        <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? true: false" class="nav-link text-light clickable-Span" v-on:click="showPaymentTranscations"
            ><em class="fa fa-cc-stripe"></em><span> Payment Transactions</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-light clickable-Span" v-on:click="showProfile"
            ><em class="fas fa-user"></em><span> Profile</span></a
          >
        </li>
        <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? true: false" class="nav-link text-light clickable-Span" v-on:click="showCoupons"
            ><em class="fas fa-tags"></em><span> Coupons</span></a
          >
        </li>
        
         <li class="nav-item">
          <a v-if="currentUser.userRole == 'Super Admin' ? false: true" class="nav-link text-light clickable-Span" v-on:click="ChangePassword"
            ><em class="fas fa-key"></em><span> Change Password</span></a
          >
        </li>

      </ul>
      <div class="text-center d-none d-md-inline">
        <button
          class="btn rounded-circle border text-light"
          id="sidebarToggle"
          type="button"
          @click="doToggle"
        ></button>
      </div>
    </div>
  </nav>
</template>

<script>
import { LISTUSER_PERMISSIONS } from "@/store/actions.type";
import { mapGetters } from "vuex";
export default {
  name: "LeftDrawer",
  beforeMount() {
    this.getPermissions();
  },
  computed: {
    ...mapGetters(["currentUser","listPermission"]),
  },
  data(){
    return {
      isSendNotice : false,
      isOpen: true
    }
  },
  methods: {
    doToggle: function(){
        this.isOpen = !this.isOpen
    },
     showSendNotice: function () {
      this.$router.push({ name: "SendNotice" });
    },
    showSuperAdminView(){
      this.$router.push({ name: "SuperAdminView" });
    },
    showSubcriptionTiers() {
      this.$router.push({ name: "SubscriptionTiers" });
    },
    showPaymentTranscations(){
      this.$router.push({ name: "PaymentDetails" });
    },
    isSuperAdmin(){
        return this.currentUser.userRole =="Super Admin"
    },
    showProfile: function () {
      this.$router.push({ name: "Profile" });
    },
    showCoupons: function(){
      this.$router.push({ name: "Coupons" })
    },
    
    showDashboard: function () {
      this.$router.push({ name: "Dashboard" });
    },
    showOnebox: function () {

      if(this.$route.name == "Onebox")
      {
        
        this.$emit("reload-onebox")
      }
      else
      {
        this.$router.push({ name: "Onebox" });
      }
      
    },
    ChangePassword: function () {
      this.$router.push({ name: "ChangePassword" });
    },
     getPermissions: function () {
       let body = {
  userdetails_id:this.$cookie.getCookie("userdetails_id")
};
      this.$store
        .dispatch(LISTUSER_PERMISSIONS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {

               data.data.map(item =>
               {
                 if(item.permissionType == 'Notice Settings')
                 {
                   this.isSendNotice =  true;
                 }
               })
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.clickable-Span{
  cursor: pointer;
}
.side
{
  background-color: #27588D;
}
.nav-link
{
  text-transform: uppercase;
}
.nav-link:hover
{
  background-color: #00A5B1;
  color: #fff !important;
}
</style>