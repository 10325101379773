<template>
  <div id="wrapper">
    <Loading :active="isLoading" :can-cancel="true"> </Loading>
    <left-drawer />

    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
        <div id="myModal" class="modal">
          <div class="modal-content">
            <div class="modal-body">
              <div class="virus-alert">
                <div class="virus-alert-icon">
                  <em class="fas fa-exclamation-triangle"></em>
                </div>
                <div class="virus-alert-text">
                  <h2>Warning</h2>
                  <h4>Virus Detected</h4>
                  <p>One of the file contains virus</p>
                </div>
                <div class="virus-alert-button">
                  <button @click="closeModel()">CLOSE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid h-80">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <div class="card w-60 p-3" style="width: 700px">
                <div class="image-box justify-content-center">
                  <img
                    class="img-fluid"
                    style="width: 350px; margin: 18px"
                    src="../assets/img/oneNotice/oneNoticeLogo.png"
                    alt="oneNoticeLogo"
                  />
                </div>
                <div class="p-4">
                  <div class="col-11 text-center mx-auto">
                    <div class="form-group">
                      <div
                        class="row"
                        v-if="
                          selectedDeliveryAccount.Deliveryaccount_Alias == 'All'
                        "
                        style="margin-left: 4.25rem"
                      >
                        <div class="row">
                          <div class="col">
                            <div
                              class="d-flex justify-content-end align-items-right"
                            >
                              <label
                                class="text-center"
                                for="couponCode"
                                style="margin-right: -1px margin-left: 6px;"
                                >From Account : &nbsp;&nbsp;&nbsp;</label
                              >
                            </div>
                          </div>
                          <div class="col">
                            <input
                              class="form-control"
                              v-if="!deliveryAccounts"
                              type="text"
                              id="couponCode"
                              v-bind:value="fromAccountId"
                              disabled="true"
                            />
                            <select
                              v-else
                              class="form-control"
                              v-model="fromAccountId"
                              style="margin-left: -24px; width: 180px"
                            >
                              <option
                                v-for="(accounts, position) in deliveryAccounts"
                                v-bind:key="position"
                                v-bind:value="accounts.Deliveryaccount"
                              >
                                {{ accounts.Deliveryaccount }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div
                          class="d-flex justify-content-end align-items-center"
                          style="padding-left: 79px"
                        >
                          <label class="text-center" for="couponCode"
                            >From Account : &nbsp;&nbsp;&nbsp;</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-center"
                          style="padding-left: 4px"
                        >
                          <label class="text-center" for="couponCode">{{
                            selectedDeliveryAccount.Deliveryaccount
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <div
                            class="d-flex justify-content-end align-items-center"
                          >
                            <label
                              class="text-center"
                              for="couponCode"
                              style="margin-right: -33px"
                              >Account ID : &nbsp;&nbsp;&nbsp;</label
                            >
                          </div>
                        </div>
                        <div class="coljustify-content-center">
                          <div class="search-accounts">
                            <input
                              type="text"
                              id="search"
                              placeholder="Type here..."
                              v-model="searchTerm"
                              v-on:keyup="findDeliveryAccount"
                              class="form-control"
                              v-bind:class="{ 'text-danger': !validAccount }"
                              @change="validateAccount"
                              style="width: 180px; margin-left: 22px"
                            />
                            <ul
                              v-if="searchCountries.length && showSearchList"
                              class="list-search-items"
                            >
                              <li
                                v-for="item in searchCountries"
                                :key="item"
                                @click="selectAccount(item)"
                                class="list"
                              >
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex justify-content-center">
                            <a
                              class="favorites-link"
                              href="#"
                              @click="openModal"
                              >View Favorites</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <div
                            class="p-1 toCompany"
                            v-if="toAccounts.length > 0"
                          >
                            <p>To Accounts</p>
                            <div
                              class="d-flex justify-content-center flex-wrap"
                            >
                              <div
                                class="chip border border-info m-1 pl-2"
                                v-for="(toAccount, index) in toAccounts"
                                v-bind:key="index"
                              >
                                <button class="custom-button">
                                  <em
                                    v-if="!this.checkAccountIsinFav(toAccount)"
                                    class="far fa-heart fa-sm fa-fw mr-1 text-white-400"
                                    @click="
                                      addOrRemoveFavAccount(toAccount, true)
                                    "
                                  ></em>
                                  <em
                                    v-else
                                    class="fas fa-heart fa-sm fa-fw mr-1 text-white-400"
                                    @click="
                                      addOrRemoveFavAccount(toAccount, false)
                                    "
                                  ></em>
                                </button>

                                <span class="align-middle">{{
                                  toAccount.accountId
                                }}</span>
                                -
                                <span class="align-middle">{{
                                  toAccount.companyName
                                }}</span>

                                <button
                                  type="button"
                                  class="btn border-0 btn-sm removeAcc p-1"
                                  @click="removeFromToAccounts(toAccount)"
                                >
                                  <strong>X</strong>
                                </button>
                                <span v-if="isLoading">
                                  <!-- Show the loader when the addOrRemoveFavAccount function is triggered -->
                                  <Loading
                                    :active="isLoading"
                                    :can-cancel="true"
                                  ></Loading>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col text-left">
                    <div class="form-group files color">
                      <input
                        type="file"
                        @change="uploadFile"
                        multiple
                        name="files"
                        ref="inputFile"
                        accept=".xls,.xlsx,image/*,.pdf,.docx,.doc,.zip,.txt,.text"
                        style="width: 70px"
                      />
                    </div>
                    <p style="text-align: center">
                      Note: Each file must be less than 25MB of size.
                    </p>
                    <div class="mb-3">
                      <div
                        class="align-middle my-3 border border-info p-3"
                        v-for="(file, index) in files"
                        v-bind:key="index"
                      >
                        <span class="text-primary align-middle">{{
                          file.name
                        }}</span>
                        -
                        <span class="text-secondary mx-2 align-middle">{{
                          bytesToSize(file.size)
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm remove"
                          @click="removeFromFiles(file)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>

                    <a
                      class="btn theme-btn text-light d-flex justify-content-center align-items-center"
                      role="button"
                      style="margin: 0 auto; width: 121px"
                      @click="handleSubmit"
                      >Submit</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showModal" class="fav-modal">
            <div class="fav-modal-content">
              <span class="close" @click="closeModal">&times;</span>
              <h2 style="padding-bottom: 20px">Favourite ID</h2>
              <!-- Add your modal content here -->
              <div class="search-container">
                <input
                  type="text"
                  v-model="searchTermFav"
                  placeholder="Search Here..."
                  @keyup.enter="searchFavourites"
                />
              </div>
              <div
                class="d-flex justify-content-center flex-wrap"
                style="padding: 13px"
              >
                <div
                  class="chip border border-info m-1 pl-2"
                  v-for="(favAccount, index) in filteredFavList"
                  v-bind:key="index"
                  @click="validateFavAccount(favAccount)"
                  v-on:keyup="findDeliveryAccount"
                  role="button"
                  :class="{
                    'hover-effect': allreadyAddedListInFav.includes(
                      favAccount.accountId
                    ),
                  }"
                  style="padding-right: 12px"
                >
                  <span class="align-middle">{{ favAccount.accountId }}</span>
                  -
                  <span class="align-middle">{{
                    favAccount.Companydetail.companyName
                  }}</span>
                  <span v-if="isLoading">
                    <!-- Show the loader when the chip is clicked -->
                    <Loading :active="isLoading" :can-cancel="true"> </Loading>
                  </span>
                </div>
              </div>
            </div>
            <div class="toast" v-if="showToast">
              {{ toastMessage }}
            </div>
          </div>
          <favourite :showModal="true" @close="closeModal" />
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import LeftDrawer from "./LeftDrawer.vue";
import Footer from "./Footer";
import { mapGetters } from "vuex";
import Loading from "vue3-loading-overlay";
import encrypter from "object-encrypter";

var engine = encrypter(process.env.VUE_APP_EK, { ttl: true });
import {
  SEND_NOTICE,
  CHECK_ACCOUNT,
  GET_DELIVERY_ACCOUNTS,
  SET_ALL_DELIVERY_ACCOUNT_ITEM,
  SEARCH_DELIVERY_ACCOUNT,
  LIST_ALL_FAVOURITES,
  ADD_FAVOURITE_ACCOUNT,
} from "@/store/actions.type";

export default {
  name: "SendNotice",
  components: {
    Header,
    Loading,
    LeftDrawer,
    Footer,
  },
  data() {
    return {
      base64Files: [],
      files: [],
      isLoading: false,
      toAccountId: "",
      validAccount: true,
      accountName: "",
      fromAccountId: "",
      noticeToCompany: "",
      userCompanyId: "",
      showModal: false,
      filteredCountries: null,

      toAccounts: [],
      favList: [],
      countries: null,
      searchTerm: "",
      searchTermFav: "",
      searchCountries: [],
      selectCountry: undefined,
      selectedCountry: undefined,
      allreadyAddedListInFav: [],

      showSearchList: false,
      showToast: false,
      toastMessage: "",
    };
  },

  beforeMount() {
    if (!(this.currentUser?.planDetails?.price == 0)) {
      this.getDeliveryAccounts();
    }
    this.searchDeleiveryAccount();
    this.listFavourites();
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "deliveryAccounts",
      "selectedDeliveryAccount",
    ]),
    filteredFavList() {
      if (!this.searchTermFav) {
        return this.favList;
      }

      const searchTermLowerCase = this.searchTermFav
        .toLowerCase()
        .replace(/ /g, "");
      return this.favList.filter((favAccount) => {
        const accountIdLowerCase = favAccount.accountId.toLowerCase();
        const companyNameLowerCase =
          favAccount.Companydetail.companyName.toLowerCase();
        const fullName = accountIdLowerCase + "-" + companyNameLowerCase;
        return (
          accountIdLowerCase.includes(searchTermLowerCase) ||
          companyNameLowerCase.includes(searchTermLowerCase) ||
          fullName.includes(searchTermLowerCase)
        );
      });
    },
  },

  methods: {
    findDeliveryAccount() {
      let matches = 0;
      if (this.searchTerm === "") {
        this.searchCountries = [];
      } else {
        this.searchCountries = this.countries.filter((country) => {
          if (
            country.includes(this.searchTerm.toUpperCase()) &&
            !this.searchedItems.includes(country) && // Check if the item is not already searched
            matches < 10
          ) {
            matches++;
            return country;
          }
        });

        if (!this.showSearchList) {
          this.showSearchList = true;
        }
      }
    },

    searchFavourites() {
      const searchTerm = this.searchTermFav.toLowerCase();

      this.favList.forEach((favAccount) => {
        const accountId = favAccount.accountId.toLowerCase();
        const companyName = favAccount.Companydetail.companyName.toLowerCase();
        favAccount.visible =
          accountId.includes(searchTerm) || companyName.includes(searchTerm);
      });
    },
    selectAccount(country) {
      this.showSearchList = false;
      this.selectCountry = country;
      this.selectedCountry = country;
      this.searchTerm = country;
      this.validateAccount();
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      // this.favList = [];
      this.showModal = false;
    },

    addToAccountsAfterValidation(favAccount) {
      let included = false,
        account = {
          accountId: favAccount,
          companyId: this.favNoticeToCompany,
          companyName: this.favAccountName,
        };

      included = this.checkIfAccountAdded(account.accountId);

      if (!included) {
        this.toAccounts.push(account);
      }

      this.favAccount = "";
    },

    searchDeleiveryAccount(key) {
      let body = {
        Userdetails_id: this.$cookie.getCookie("userdetails_id"),
        searchKey: key || "",
      };

      this.$store
        .dispatch(SEARCH_DELIVERY_ACCOUNT, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            let allAccountIDs = data.data.map((item) => {
              return item.fk_deliveryaccount_id.accountId;
            });

            this.countries = allAccountIDs;

            return allAccountIDs;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDeliveryAccounts: function () {
      let body = {
        companyId: this.$cookie.getCookie("COMPANY_ID"),
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };

      this.$store
        .dispatch(GET_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.deliveryAccounts = data.data;
            this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);

            this.userCompanyId = data.companyId;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    uploadFile(event) {
      let allowedExts = [
        "doc",
        "docx",
        "png",
        "jpg",
        "jpeg",
        "txt",
        "text",
        "xls",
        "xlsx",
        "pdf",
        "zip",
      ];
      let allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/zip",
        "application/x-zip-compressed",
      ];
      Array.from(event.target.files)?.forEach((item) => {
        let itemExtension = item.name.split(".").pop();
        //checking the file extension😌
        if (allowedExts.includes(`${itemExtension.toLowerCase()}`)) {
          //double-checking file_type🤨
          allowedTypes.includes(item.type.toLowerCase())
            ? this.files.push(item)
            : //if extension and file_type does not match
              this.$toast.show("Corrupted Document : " + `${item.name}`, {
                type: "error ",
                position: "top-right",
              });
        } else {
          //extension mismatch
          this.$toast.show("Invalid Document : " + `${item.name}`, {
            type: "error ",
            position: "top-right",
          });
        }
      });
    },
    removeFromFiles(item) {
      this.files = this.files.filter((value) => {
        return value !== item;
      });
      if (this.files == 0) {
        this.$refs.inputFile.value = "";
      }
    },
    sender() {
      if (this.currentUser?.planDetails?.price == 0) {
        this.fromAccountId = this.currentUser.uniqueId;
      } else if (
        this.currentUser?.planDetails?.price == 0 &&
        this.currentUser?.userRole != "Coporate Admin"
      ) {
        this.fromAccountId = this.currentUser.deliveryAccountId;
      }
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    checkAccountValidity(accountId, companyid) {
      const body = { accountId, companyid: companyid.toString() };
      this.$store
        .dispatch(CHECK_ACCOUNT, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            let included = false,
              account = {
                accountId: accountId,
                companyId: data.companyId,
                companyName: data.companyName,
              };

            included = this.checkIfAccountAdded(account.accountId);
            if (!included) {
              this.toAccounts.push(account);
              this.allreadyAddedListInFav.push(account.accountId);
            }
            this.searchTerm = "";
            this.validAccount = true;
            this.accountName = data.companyName;
            this.noticeToCompany = data.companyId;
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          error.message.message
            ? this.$toast.show(
                "Can't send notices to Accounts of your own company!",
                {
                  type: "error ",
                  position: "top-right",
                }
              )
            : this.$toast.show(error.message, {
                type: "error ",
                position: "top-right",
              });
          this.validAccount = false;
        });
    },

    validateFavAccount(favAccount) {
      this.isLoading = true;
      this.checkAccountValidity(
        favAccount.accountId,
        this.$cookie.getCookie("COMPANY_ID")
      );
    },
    hideToast() {
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = "";
      }, 3000); // Adjust the duration as needed
    },

    validateAccount() {
      if (this.searchTerm.length >= 5) {
        this.isLoading = true;
        const accountId = this.searchTerm;
        const companyId = this.$cookie.getCookie("COMPANY_ID");

        // Check if the account is already added
        if (this.checkIfAccountAdded(accountId)) {
          this.isLoading = false;
          this.validAccount = false;
          this.$toast.show(`Account ID is already added.`, {
            type: "error",
            position: "top-right",
          });
          this.hideToast();

          return;
        }

        // Perform the validation logic for the account
        this.checkAccountValidity(accountId, companyId);
      }
    },

    listFavourites() {
      this.isLoading = false;
      this.$store
        .dispatch(LIST_ALL_FAVOURITES)
        .then((data) => {
          if (data.length == 0) {
            this.$toast.show("Favourite List is empty!", {
              type: "success ",
              position: "top-right",
            });
          } else {
            data.forEach((favItem) => {
              this.favList.push(favItem?.DeliveryAccount);
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    checkAccountIsinFav(account) {
      let filteredItem = this.favList.filter((item) => {
        return item?.accountId == account.accountId;
      });
      return filteredItem?.length > 0;
    },
    addOrRemoveFavAccount(toAccount, bool) {
      const { accountId } = toAccount;
      this.isLoading = true;
      const body = { accountId, favoriteFlag: bool };
      this.$store
        .dispatch(ADD_FAVOURITE_ACCOUNT, body)
        .then((data) => {
          if (data?.data?.favoriteFlag == true) {
            this.favList.push({
              ...toAccount,
              Companydetail: { companyName: toAccount.companyName },
            });
            this.showToastMessage(true); // Show toast message for adding to favorite list
          } else {
            this.favList = this.favList.filter((item) => {
              return item.accountId != toAccount.accountId;
            });
            this.showToastMessage(false); // Show toast message for removing from favorite list
          }
        })
        .catch(() => {
          // Handle error if needed
        })
        .finally(() => {
          this.isLoading = false; // Set isLoading to false once the operation is completed
        });
    },
    showToastMessage(isAdding) {
      const message = isAdding
        ? "Added to Favorite List"
        : "Removed from Favorite List";
      this.$toast.show(message, {
        type: "success",
        position: "top-right",
      });
    },
    checkIfAccountAdded(toAccountId) {
      let added = false;
      this.toAccounts.map((toAcc) => {
        if (toAcc.accountId == toAccountId) {
          added = true;
        }
      });
      return added;
    },
    removeFromToAccounts(account) {
      this.allreadyAddedListInFav = this.allreadyAddedListInFav.filter(
        (item) => item != account.accountId
      );
      let toAccount = [];
      toAccount = this.toAccounts.filter(
        (acc) => acc.accountId != account.accountId
      );
      this.toAccounts = toAccount;
    },
    sendNotice() {
      if (this.files.length <= this.currentUser.planDetails.attachmentCount) {
        this.isLoading = true;

        let body = {
          userdetails_id: this.currentUser.userdetails_id,
          noticeFromAccountId:
            this.selectedDeliveryAccount.Deliveryaccount_Alias == "All"
              ? this.fromAccountId
              : this.selectedDeliveryAccount.Deliveryaccount,
          deliveryAccounts: JSON.stringify(this.toAccounts),

          noticeFromCompany: this.currentUser.companyId,
        };
        var hex = engine.encrypt(body, 1000000);
        let formData = new FormData();
        formData.append("data", hex);

        for (const i of Object.keys(this.files)) {
          formData.append("noticeFiles", this.files[i]);
        }

        this.$store
          .dispatch(SEND_NOTICE, formData)
          .then((data) => {
            this.isLoading = false;
            if (data.status == "failure") {
              this.checkMessage(data.message);
              this.$toast.show(data.message, {
                type: "error ",
                position: "top-right",
              });
            } else {
              this.$router.push({ name: "Onebox" });
              this.$toast.show(data.message, {
                type: "success ",
                position: "top-right",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast.show(error.message, {
              type: "error ",
              position: "top-right",
            });
            if (error.message == "virus found") {
              this.openModel();
            }
          });
      } else {
        this.$toast.show(
          "You can attach a maximum of " +
            this.currentUser.planDetails.attachmentCount +
            " notices as per the current plan",
          {
            type: "error ",
            position: "top-right",
          }
        );
      }
    },
    checkMessage(msg) {
      if (msg == "virus found") {
        this.openModel();
      }
    },
    handleSubmit() {
      if (this.toAccounts.length > 0) {
        this.fileAndAccountIDChecks();
      } else {
        // Kindly use a valid Account ID for sending notice.
        this.$toast.show("Please Type in To-Account(s)", {
          type: "error ",
          position: "top-right",
        });
      }
    },
    fileAndAccountIDChecks() {
      if (this.files.length != 0) {
        if (this.sizeCheck() && this.fromAccountCheck()) {
          this.sendNotice();
        }
      } else {
        this.$toast.show("Please attach a file to send!", {
          type: "error ",
          position: "top-right",
        });
      }
    },
    sizeCheck() {
      let sizeOk = true;
      for (var i = 0; i < this.files.length; i++) {
        // file size less than 26,214,400 bytes -25MB-
        if (this.files[i].size > 25 * 1024 * 1024) {
          this.$toast.show("Please attach files with size less than 25 MB", {
            type: "error ",
            position: "top-right",
          });
          sizeOk = false;
          return;
        }
      }
      return sizeOk;
    },
    fromAccountCheck() {
      if (
        this.fromAccountId == "" &&
        this.selectedDeliveryAccount.Deliveryaccount_id == ""
      ) {
        this.$toast.show("Please Select From-AccountId", {
          type: "error ",
          position: "top-right",
        });
        return false;
      }
      return true;
    },
    openModel() {
      let modal = document.getElementById("myModal");
      modal.style.display = "block";
    },
    closeModel() {
      let modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
  },
};
</script>

<style scoped lang="scss">
.chip {
  border-radius: 20px;

  background-color: transparent;
  color: black;
  font-size: 12pt;
}
.hover-effect {
  /* Add your hover styles here */
  background-color: lightgray;
  cursor: pointer;
}
.toCompany {
  max-width: 750px;
}

.favorites-link {
  color: blue;
  margin-left: -10px;
  cursor: pointer;
  /* Adjust the margin as needed */
}
.list-search-items {
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  z-index: 11;
  overflow: auto;
  margin-top: 5px;
  margin-left: 20px;
  list-style-type: none;
  position: absolute;
  background: white;
  width: 32%;
  .list {
    padding: 4px 0px;
  }
}
.remove {
  margin-top: auto;
  margin-bottom: auto;
}
.removeAcc {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  font-size: 8pt;
  background-color: transparent;
  color: rgb(34, 25, 163);
}
.labelColor {
  color: green;
}

/* The Modal (background) */
.virus-alert {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
  .virus-alert-icon {
    font-size: 60px;
    color: red;
  }
  .virus-alert-text {
    color: red;
  }
  .virus-alert-button {
    button {
      border: 2px solid red;
      background: white;
      color: red;
      padding: 6px 19px;
      border-radius: 3px;
      :hover {
        border: 2px solid red;
        background: red;
        color: white;
        padding: 6px 19px;
        border-radius: 3px;
      }
    }
    button:hover {
      background: red;
      color: white;
    }
  }
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 41%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.custom-button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

.custom-button .fa-heart {
  color: rgb(184, 21, 21);
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

input[type="file"] {
  color: transparent;
  padding-left: 245px;
}

.fav-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #eaecf4;

  z-index: 99;
}

.fav-modal-content {
  background-color: white;
  margin: 6% auto;
  padding: 20px;
  width: 60%;
  height: 80%;
  max-width: 500px;
  border-radius: 14px;
  box-shadow: -3px 3px 7px 1px #b7b9cc;
  overflow: auto;
}

.close {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}
.search-container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.search-container input[type="text"] {
  flex-grow: 1;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 455px;
  height: 52px;
}
</style>
