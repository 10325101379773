<template>
    <div class="footer-container">
         <div class="footer-image">
          <img src=@/assets/img/oneNotice/oneNoticeLogo1.png alt="">
        </div>
        <div class="footer-text  w-75">
           <span>Copyright © onenotice 2021</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
<style scoped  lang="scss">

.footer-container
{
    display: flex;
    padding: 8px;
    background: #978F8B;
    color: white;
    width: 100%;
    height: auto;
    margin-top: 30px;
    position:fixed;
    bottom:0;
    .footer-text
    {
        justify-self: center;
        text-align: center;

    }
     .footer-image
    {
        justify-self: start;
        img{
            width: 120px;
        }
    }
}

</style>