<template>
  <div id="wrapper">
    <left-drawer />
     <Loading :active="isLoading" :can-cancel="true"></Loading>
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
        <div id="content">
          <div class="container-fluid">
            <h3 class="text-dark mb-4">Activity Logs</h3>
            <div class="card shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-nowrap">
                    <div
                      id="dataTable_length"
                      class="dataTables_length"
                      aria-controls="dataTable"
                    >
                      <label
                        >Show&nbsp;<select @change="onLimitChange($event)" v-model="limit"
                          class="form-control form-control-sm custom-select custom-select-sm"
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option></select
                        >&nbsp;</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="text-md-right dataTables_filter"
                      id="dataTable_filter"
                    >
                      <label
                        ><input
                          type="search"
                          class="form-control form-control-sm"
                          aria-controls="dataTable"
                          placeholder="Search"
                          v-model="searchKeyWord"
                          @change="searchLog"
                      /></label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="activityLogs != null && activityLogs.length > 0"
                  class="table-responsive table mt-2"
                  id="dataTable"
                  role="grid"
                  aria-describedby="dataTable_info"
                >
                  <table
                    class="table my-0 table-light table-bordered table-hover"
                    id="dataTable"
                  >
                  <caption> </caption>
                    <thead class="thead-dark">
                      <tr>
                        <th id="name">Name</th>
                        <th id="msg">Message</th>
                        <th id="act">Action</th>
                        <th id="stat">Status</th>
                        <th id="typ">Type</th>
                        <th id="modDate">Modified Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-grey"
                        v-for="item in activityLogs"
                        v-bind:key="item.id"
                        >
                        <td>{{ item.firstName +" "+ item.lastName}}</td>
                        <td>
                          {{ item.message}}
                        </td>
                        <td>
                          {{ item.action }}
                        </td>
                        <td>
                          {{ item.status }}
                        </td>
                       <td>
                          {{ item.type }}
                        </td>
                        <td>
                          {{ moment(item?.createdAt)?.format("YYYY, MMM - DD hh:mm A") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-md-6 align-self-center">
                    <p
                      id="dataTable_info"
                      class="dataTables_info"
                      role="status"
                      aria-live="polite"
                      v-if="activityLogs.length > 0"
                    >
                      Showing {{offset+1}} to {{offset + activityLogs.length}} of {{totalCount}}
                    </p>
                     <p class="align-self-center" v-else-if="searchKeyWord!=''">No search results for {{searchKeyWord}} </p>
                  </div>
                  <div class="col-md-6">
                    <nav
                      class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                    >
                      <ul class="pagination">
                        <!-- <li class="page-item disabled">
                          <a class="page-link" href="#" aria-label="Previous"
                            ><span aria-hidden="true">«</span></a
                          >
                        </li> -->
               <li class="page-item">
					<button type="button" class="page-link"
          v-for="pageNumber in pages" :key="pageNumber"
           @click="next(page = pageNumber)">
          {{pageNumber}}
          </button>
				</li>
        <!-- <li class="page-item">
					<button type="button" @click="next(page++,true)" v-if="page < pages.length" class="page-link"> Next </button>
				</li> -->
                        <!-- <li class="page-item active">
                          <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">3</a>
                        </li> -->
                        <!-- <li class="page-item">
                          <a class="page-link" href="#" aria-label="Next"
                            ><span aria-hidden="true">»</span></a
                          >
                        </li> -->
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>


<script>
import Header from "../views/Header";
import Loading from "vue3-loading-overlay";
import LeftDrawer from "../views/LeftDrawer.vue";
import Footer from "../views/Footer";
import { mapGetters } from "vuex";
import moment from "moment";
import {
  ACTIVITY_LOG,
} from "@/store/actions.type";

export default {
  name: "Activity Log",
  components: {
    Header,
    LeftDrawer,
    Footer,
    Loading,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  beforeMount() {
    this.getActivityLogs(this.offset,"");
  },
  data() {
    return {
      isLoading: false,
      activityLogs: [],
      deliveryId: null,
      limit:  25,
      offset: 0,
      totalCount: "",
      pages: [],
      page: 1,
      searchKeyWord: "",
    };
  },
  methods: {
    next(page, isNext){
      let currentPage = 1
      if(isNext){
      currentPage = page+1
      }else{
      currentPage = page
      }
      this.offset = (currentPage-1)*this.limit
      this.getActivityLogs(this.offset,this.searchKeyWord==''?'':this.searchKeyWord)
    },
    setPages () {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalCount / this.limit);
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index);
      }
		},
    onLimitChange(event){
      this.limit = 0
      this.limit = event.target.value
      this.setPages();
      this.getActivityLogs(0,"");
    },
    moment: function (date) {
      return moment(date);
    },
    searchLog(){
   // if there is no search keyword call listing
      if (this.searchKeyWord == "") {
        this.getActivityLogs(0,"");
        return;
      }else{
        this.getActivityLogs(0,this.searchKeyWord);
      }
    },
    getActivityLogs(offset,searchKey) {
      let body = {
        searchString: searchKey,
        limit: this.limit,
        offset: offset,
        userRole: this.currentUser.userRole
      };
      this.isLoading = true
      this.$store
        .dispatch(ACTIVITY_LOG, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.offset=offset;
            this.activityLogs = data.data;
            this.totalCount = data.totalCount;
            this.setPages();
          }
        })
        .catch((error) => {
          this.activityLogs = error.data;
          this.totalCount = error.totalCount;
          this.setPages();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.table-icons {
  width: 24px;
}
.noData {
  justify-content: center;
  height: 100px;
  display: flex;
  align-items: center;
}
.table-icons-view {
  width: 28px;
}
.table-cell-adjust {
  text-align: center;
}
table.table-bordered{
    border:1px  rgb(233, 236, 236);
    margin-top:20px;
    text-align: center;
  }
table.table-bordered > thead > tr > th{
    border:1px solid rgb(233, 236, 236);
}
table.table-bordered > tbody > tr > td{
    border:1px solid rgb(233, 236, 236);
}
button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
}
button.page-link {
	display: inline-block;
}
</style>