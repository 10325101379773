import { GET_ONE_BOX_LIST, SEARCH_NOTICE, GET_DASHBOARD_ONE_BOX, ADD_FILE_READ, LIST_DELIVERY_ACCOUNTS, ONE_BOX_NOTICE_DETAILS } from "../actions.type";
import { SET_ERROR, SET_ACCOUNT_IDS } from "../mutations.type";
import { post } from "@/api/service";

const state = {
    errors: null,
    accountTypes: '',
};

const actions = {
    [GET_ONE_BOX_LIST](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/oneBoxList", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [ONE_BOX_NOTICE_DETAILS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/oneBoxfiles", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [ADD_FILE_READ](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/add_fileread", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [GET_DASHBOARD_ONE_BOX](context, body) {
        return new Promise((resolve, reject) => {
            //oneBoxDashBoard
            post("v2/oneBoxList", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },

    [SEARCH_NOTICE](context, body) {
        return new Promise((resolve, reject) => {
            post("searchNotice", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [LIST_DELIVERY_ACCOUNTS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/listDeliveryAccounts", body)
                .then((data) => {
                    if (data.status == "success") {
                        if (data.data) {
                            context.commit(SET_ACCOUNT_IDS, data.data)
                        }
                        resolve(data);
                    } else {
                        reject(data)
                    }
                })
                .catch((response) => {
                    context.commit(SET_ACCOUNT_IDS, response.data)
                    reject(response)
                });
        })
    },
};
function checkStatus(data,resolve,reject)
{
    if (data.status == 'failure') {
        reject(data)
    } else
        resolve(data);
}
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
};