const ID_TOKEN_KEY = "ID_TOKEN_KEY";
import { VueCookieNext } from 'vue-cookie-next'
export const getToken = () => {
  return VueCookieNext.getCookie(ID_TOKEN_KEY);
};

export const saveToken = token => {
  VueCookieNext.setCookie(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  VueCookieNext.removeCookie(ID_TOKEN_KEY);
  VueCookieNext.removeCookie("ADDRESS");
  VueCookieNext.removeCookie("CITY");
  VueCookieNext.removeCookie("PHONE");
  VueCookieNext.removeCookie("COMPANY_ID");
  VueCookieNext.removeCookie("COMPANY_NAME");
  VueCookieNext.removeCookie("COUNTRY");
  VueCookieNext.removeCookie("ROLE");
  VueCookieNext.removeCookie("USER_ID");
  VueCookieNext.removeCookie("COMPANY_ALIAS");
};

export default { getToken, saveToken, destroyToken };