<template>
  <div class="card shadow">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <div class="card-header theme-area-head py-3" style="border-radius: 5px 5px 0 0">
      <p class="text-white m-0 font-weight-bold">Contact Settings</p>
    </div>
    <div class="card-body">
      <form @submit.prevent="submitForm" novalidate>
        <div class="form-group">
          <label for="address"
            ><strong>Work Address / Desk Location</strong></label
          ><input
            class="form-control"
            type="text"
            placeholder="Address"
            name="address"
            v-model="address"
          />
          <p>
            <small class="text-danger" v-if="!address && submitted"
              >Work Address / Desk Location is required</small
            >
          </p>
        </div>
        <div class="form-group" v-if="this.$cookie.getCookie('ROLE') == 'Corporate Admin'">
          <label for="companyAlias"
            ><strong>Company Alias</strong></label
          ><input
            class="form-control"
            type="text"
            placeholder="Company Alias"
            name="companyAlias"
            v-model="companyAlias"
            :disabled="this.$cookie.getCookie('ROLE') != 'Corporate Admin'"
          />
         
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="city"><strong>Work Phone</strong></label
              ><input
                class="form-control"
                type="text"
                placeholder="(XXX) XXX-XXXX"
                name="phone"
                v-model="phone"
              />
              <p>
                <small class="text-danger" v-if="!phone && submitted"
                  >Work Phone is required</small
                >
                <small class="text-danger" v-if="phone && !isValidPhone() && submitted"
                  >Valid Phone is required</small
                >
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="city"><strong>City</strong></label
              ><input
                class="form-control"
                type="text"
                placeholder="City"
                name="city"
                v-model="city"
              />
              <p>
                <small class="text-danger" v-if="!city && submitted"
                  >City is required</small
                >
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="country"><strong>State</strong></label
              ><input
                class="form-control"
                type="text"
                placeholder="State"
                name="country"
                v-model="country"
              />
              <p>
                <small class="text-danger" v-if="!country && submitted"
                  >State is required</small
                >
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <!-- btn-primary -->
          <button class="btn theme-btn text-light btn-sm" type="submit">
            Save&nbsp;Settings
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue3-loading-overlay";
import { UPDATE_CONTACT,GET_CONTACT_DETAILS } from "@/store/actions.type";
export default {
  name: "ContactSettings",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["getContactInfo", "currentUser","getSelectedUser"]),
  },
  
  props: ["company-id","super-admin", "selected-user"],
  beforeMount() {
    this.getContactDetails()
    
  },
  
  data() {
    return {
      isLoading: false,
      fullPage: true,
      address: "",
      phone: "",
      city: "",
      country: "",
      companyAlias: "",
      submitted: false,
    };
  },
  methods: {
    onChange(e) {
      
      if (e.target.name == "address") {
        this.address = e.target.value;
      } else if (e.target.name == "phone") {
        this.phone = e.target.value;
      } else if (e.target.name == "city") {
        this.city = e.target.value;
      } else if (e.target.name == "country") {
        this.country = e.target.value;
      } 
      else if(e.target.name == "companyAlias"){
        this.companyAlias = e.target.value;
      }
    },
    getContactDetails()
    {
    this.isLoading = true;
    let body;
    this.superAdmin ?
    body = 
    {
      companyId : this.selectedUser.companyId,
      userId : this.selectedUser.user_id
    }
    :
    body =
    {
      companyId : this.currentUser.companyId ? this.currentUser.companyId : 0,
      userId: this.currentUser.userId
    };
    this.$store.dispatch(GET_CONTACT_DETAILS,body)
    .then((data)=>
    {
      this.isLoading = false;
      
       if (data.status == "Failure") {
         this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
       }
       else
       {
          this.address = data.data.userContactDetails.address
          this.phone = data.data.userContactDetails.phone
          this.city = data.data.userContactDetails.city
          this.country = data.data.userContactDetails.country
          this.companyAlias = data.data.companyDetails ? 
                              data.data.companyDetails.companyAlias : '';
           if(!this.superAdmin){
            this.$cookie.setCookie("ADDRESS", this.address);
            this.$cookie.setCookie("PHONE", this.phone);
            this.$cookie.setCookie("CITY", this.city);
            this.$cookie.setCookie("COUNTRY", this.country);
            this.$cookie.setCookie("COMPANY_ALIAS",this.companyAlias)
            }             
       }
      
    })
    .catch(()=>
    {
      this.isLoading = false;
      this.address = ''
      this.phone = ''
      this.city = ''
      this.country = ''
      this.companyAlias = ''
    })
    },
    submitForm() {
      this.isLoading = true;
      this.submitted = true;
      
      if (
        !this.address ||
        !this.phone ||
        !this.city ||
        !this.country ||
        !this.isValidPhone()
      ) {
        this.isLoading = false;
      } else {
        this.updateContact(this.address, this.phone, this.city, this.country,this.companyAlias);
      }
    },

    isValidPhone() {
      var phoneno = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      return phoneno.test(this.phone) ? true : false;
    },
    isNumber(number){
       const regex = new RegExp(/[^0-9]/, 'g');
      return (number.match(regex) === null)
    },
    updateContact(address, phone, city, country,companyAlias) {
      if (this.isNumber(address)) {
        this.isLoading = false;
         this.$toast.show("Address cannot be a number", {
                    type: "error ",
                    position: "top-right",
                  });
      }else if (this.isNumber(city)) {
        this.isLoading = false;
        this.$toast.show("City cannot be a number", {
                    type: "error ",
                    position: "top-right",
                  });
      }else if (this.isNumber(country)) {
        this.isLoading = false;
        this.$toast.show("State cannot be a number", {
                    type: "error ",
                    position: "top-right",
                  });
      }else{
        if(companyAlias)
        {
         if(this.isNumber(companyAlias))
          {
            this.isLoading = false;
            this.$toast.show("Company Alias cannot be a number", {
                    type: "error ",
                    position: "top-right",
                  });
          }
          else
          {
            this.saveContactDetails(address, phone, city, country, companyAlias);
          }
        }
        else
        {
          this.saveContactDetails(address, phone, city, country, companyAlias);
        }
      
      }
    },
    saveContactDetails(address,phone,city,country,companyAlias)
    {
      let body ;
      companyAlias !=null ? 
      body = {
        userId: this.superAdmin?this.selectedUser.user_id: this.currentUser.userId,
        address: address,
        phone: phone,
        city: city,
        country: country
        ,companyAlias: companyAlias,
        companyId: this.getCompanyId()
      }
      :
       body = {
        userId: this.superAdmin?this.selectedUser.user_id: this.currentUser.userId,
        address: address,
        phone: phone,
        city: city,
        country: country,
        companyId: this.getCompanyId() 
      };
      this.$store
        .dispatch(UPDATE_CONTACT, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            if(!this.superAdmin){
            this.$cookie.setCookie("ADDRESS", this.address);
            this.$cookie.setCookie("PHONE", this.phone);
            this.$cookie.setCookie("CITY", this.city);
            this.$cookie.setCookie("COUNTRY", this.country);
            this.$cookie.setCookie("COMPANY_ALIAS",this.companyAlias)
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getCompanyId()
    {
      return this.$cookie.getCookie("COMPANY_ID") ?
                    this.$cookie.getCookie("COMPANY_ID")
                    :
                    this.selectedUser.companyId ?
                      this.selectedUser.companyId : 0
    }
  },
};
</script>