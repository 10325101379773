<template>
  <div id="wrapper">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <left-drawer />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
        <div class="container">
          <div class="d-flex justify-content-between pb-2">
            <h3 class="text-dark mb-0 pt-1">Subscription Tiers</h3>

            <button
              type="button"
              class="btn theme-btn text-white"
              v-on:click="onAddTier()"
            >
              Add New
            </button>
          </div>
          <table class="table table-bordered tableColor">
            <caption>
              {{
                getAccountTiers?.length == 0
                  ? "No Data"
                  : "List of Subscription Tiers"
              }}
            </caption>
            <!-- thead-dark  -->
            <thead class="theme-tbl-head text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Plan Name</th>
                <th scope="col">Users Count</th>
                <th scope="col">Notice Count</th>
                <th scope="col">Price</th>
                <th scope="col">Delivery Accounts</th>
                <th scope="col">Attachment Counts</th>
                <th scope="col">Trial Period</th>
                <th scope="col">Edit</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getAccountTiers" v-bind:key="item.id">
                <th class="align-middle" scope="row">{{ index + 1 }}</th>
                <td class="align-middle">{{ item.planName }}</td>
                <td class="align-middle">{{ item.userCount }}</td>
                <td class="align-middle">
                  {{ item.noticeCount === -1 ? "Unlimited" : item.noticeCount }}
                </td>
                <td class="align-middle">${{ item.price / 100 }}</td>
                <td class="align-middle">{{ item.deliveryAccounts }}</td>
                <td class="align-middle">{{ item.attachmentCount }}</td>
                <td class="align-middle">{{ item.trialPeriod }}</td>
                <td
                  class="align-middle"
                  role="button"
                  v-on:click="editTierClicked(item)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row pt-1 pb-2">
            <!-- spacing between fixed footer and the page content -->
          </div>
        </div>
        <transition name="modal" v-if="showModal" v-on:colse-modal="modalHide">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modal-header theme-area-head">
                  <!-- text-primary small -->
                  <h6 class="text-light card-subtitle  font-weight-bold mb-3">
                    {{
                      isEdit
                        ? "Edit Subscription Tier"
                        : "Add New Subscription Tier"
                    }}
                  </h6>
                  <slot name="header">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      v-on:click="modalHide"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </slot>
                </div>

                <div class="modal-body">
                  <slot name="body">
                    <EditSubscriptionTiers
                      v-bind:user="selectedUser"
                      v-bind:edit="isEdit"
                    />
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import LeftDrawer from "./LeftDrawer.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Loading from "vue3-loading-overlay";
import EditSubscriptionTiers from "./EditSubscriptionTiers";
import { LIST_SUBSCRIPTIONS_TIER } from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  components: {
    LeftDrawer,
    Header,
    Footer,
    Loading,
    EditSubscriptionTiers,
  },
  beforeMount() {
    this.isLoading = true;
    this.listSubscriptionTiers();
  },
  computed: {
    ...mapGetters(["getAccountTiers"]),
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      accountTypes: "",
      showModal: false,
      selectedUser: "",
    };
  },
  methods: {
    editTierClicked(user) {
      this.selectedUser = user;
      this.isEdit = true;
      this.showModal = true;
    },
    onAddTier() {
      this.showModal = true;
      this.isEdit = false;
    },
    modalHide: function() {
      this.showModal = false;
    },
    listSubscriptionTiers: function() {
      this.$store
        .dispatch(LIST_SUBSCRIPTIONS_TIER)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.table td {
  text-align: center;
}
.table th {
  text-align: center;
}
.tableColor {
  background: white;
}
</style>
