<template>
<div id="chart">
      <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>

import {GET_NOTICE_PER_MONTH} from "@/store/actions.type";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
   name: 'AccountStatics',
   props: ["companyId","userRole","deliveryId","startDate","endDate"],
   watch: {
    companyId(value) {
      if(value != "")
      {
        this.getNoticePerMonth(
              value,
              this.userRole ? this.userRole : this.currentUser.userRole,
              this.deliveryId,
              this.startDate,
              this.endDate
                            );
      }
      
    },
    deliveryId(value){

      if(value != '')
      {
        this.getNoticePerMonth(
              this.companyId ? this.companyId : this.currentUser.companyId,
              this.userRole ? this.userRole : this.currentUser.userRole,
              value,
              this.startDate,
              this.endDate
                            );
      }
      
    },
    startDate(value)
    {
      this.lowerDate = value
      
       if( this.endDate != this.upperDate )
          {
            this.dateChange = true;
            this.upperDate = this.endDate;
          }
        else
        {
          this.dateChange = false;
        } 

        this.getNoticePerMonth(
              this.companyId ? this.companyId : this.currentUser.companyId,
              this.userRole ? this.userRole : this.currentUser.userRole,
              this.deliveryId,
              value,
              this.endDate
                        );
       
    },
    endDate(value)
    {
      this.upperDate = value;
      
         this.dateChange ? 
          this.dateChange = false
        :
        this.getNoticePerMonth(
              this.companyId ? this.companyId : this.currentUser.companyId,
              this.userRole ? this.userRole : this.currentUser.userRole,
              this.deliveryId,
              this.startDate,
              value
                            );
      
    }
    },
    beforeMount(){
      this.lowerDate = this.startDate;
      this.upperDate = this.endDate;
      
      this.getNoticePerMonth(
        this.companyId =="" ? this.currentUser.companyId : this.companyId,
        this.userRole =="" ? this.currentUser.userRole : this.userRole,
        this.deliveryId,
        this.startDate,
        this.endDate
      
      )
      
    },
    computed: {
    ...mapGetters(["currentUser"]),
    },
   methods: {
     getNoticePerMonth(value, userRole,deliveryId,startDate,endDate){
          let dateLower ="", dateUpper ="",body;
          if(startDate != "" && endDate != "")
          {   //making sure both dates are not empty
            dateLower = startDate;
            dateUpper = endDate;
             body = {
            companyId: userRole=='Super Admin' ? 0 : value,
            userRole: userRole,
            selectedDeliveryId: userRole=='Super Admin' ? [] : deliveryId,
            startDate: dateLower,
            endDate: dateUpper
                    };
          }
          else
          { 
             body = {
            companyId: userRole=='Super Admin' ? 0 : value,
            userRole: userRole,
            selectedDeliveryId: userRole=='Super Admin' ? [] : deliveryId,
          
                    };
            
          }        
        
        this.$store
          .dispatch(GET_NOTICE_PER_MONTH, body)
          .then((data) => {
            
            if(userRole!="Super Admin"){
            this.series = [{
              name: "Received",
              data: this.sortMonths(data.receivedData).map((item)=> item.count)
          },
          {
              name: "Send",
              data: this.sortMonths(data.sendData).map((item)=> item.count)
          } ]
            }else{
               this.series = [
            {
              name: "Notices",
              data: this.sortMonths(data.sendData).map((item)=> item.count)
            } ]
            }
          this.chartOptions = {xaxis:{
            categories:this.sortMonths(data.receivedData).map((item)=> item.month)}}
          })
          .catch(() => {
            //intentional- catch
          });
     },
     sortMonths(rData) {
       return rData.sort((a, b) => {
              return moment(a.month,"currentFormat").format("MM") - moment(b.month,"currentFormat").format("MM")
            });
     }
   },
   data:function() {
   return {
          dateChange:false,
          lowerDate:"",
          upperDate:"",
          send:'',
          received: '',
          series: [],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: [],
            }
          },

   }
        }
}
</script>