<template>
    <div id="wrapper">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <left-drawer />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
            <div class="container">
                <div class="d-flex justify-content-between">
                  <h3 class="text-dark mb-0">Coupons</h3>
            <button
              type="button"
              class="btn theme-btn text-white"
              v-on:click="onNewCoupon()"
            >
              Add New Coupon
            </button>
          </div>
          <table class="table table-bordered tableColor" v-if="getCoupons">
            <caption>
              
            </caption>
            <!-- thead-dark  -->
            <thead class="theme-tbl-head text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Coupon ID</th>
                <th scope="col">Promo Code</th>
                <th scope="col">Duration</th>
                <th scope="col">Duration in months</th>
                <th scope="col">Discount</th>
                <!-- <th scope="col">Created At</th>
                <th scope="col">Updated At</th> -->
                <!-- {{ coupon.coupondetails.premocodes }} -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(coupon, index) in getCoupons" v-bind:key="coupon.id">
                <th class="align-middle" scope="row">{{ index + 1 }}</th>
                <td class="align-middle">{{ coupon.code }}</td>
                <td class="align-middle">
                          <div class="d-flex justify-content-center flex-wrap">
                              <div
                                class="chip border border-info m-1 p-1"
                                v-for="premo in coupon.coupondetails.premocodes"
                                v-bind:key="premo"
                              >
                                <span class="align-middle">{{
                                  premo.premocode.toUpperCase()
                                }}</span>
                              </div>
                            </div>
                    <!-- <p
                    class="my-0 promo"
                    v-for="premo in coupon.coupondetails.premocodes"
                    v-bind:key="premo">{{ premo.premocode }}
                    </p> -->

                </td>
                <td class="align-middle">{{ coupon.coupondetails.duration }}</td>
                <td class="align-middle">{{ coupon.coupondetails.duration_in_months }}</td>
                <td class="align-middle">{{ coupon.coupondetails.percent_off }}%</td>
                <td
                  class="align-middle"
                  role="button"
                  v-on:click="deleteCouponClicked(coupon)"
                >
                <em class="fas fa-trash"></em>
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg> -->
                </td>
              </tr>
            </tbody>
          </table>
            <div v-else>
                <p>No Coupons Available!</p>
            </div>
            <!--  -->
            <div class="row">
                  <div
                    class="col-md-6 align-self-center"
                    v-if="getCoupons?.length > 0"
                  >
                    <!-- <p
                      id="dataTable_info"
                      class="dataTables_info"
                      role="status"
                      aria-live="polite"
                      v-if="getCoupons"
                    >
                      Showing
                      {{ getCoupons?.length > 0 ? offset + 1 : offset }} to
                      {{ offset + getCoupons?.length }} of {{ totalCount }}
                    </p> -->
                  </div>
                  <div class="col-md-6">
                    <nav
                      class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                    >
                      <ul class="pagination">
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            v-for="pageNumber in pages"
                            :key="pageNumber"
                            @click="next((page = pageNumber))"
                          >
                            {{ pageNumber }}
                          </button>
                        </li>
                        <!-- <li class="page-item">
                          <button
                            type="button"
                            @click="next(page++, true)"
                            v-if="page < pages?.length"
                            class="page-link"
                          >
                            Next
                          </button>
                        </li> -->
                      </ul>
                    </nav>
                  </div>
                </div>
            <!--  -->
            <div class="row pt-0 pb-4 justify-content-center">
              <div class="form-row p-2" v-if="getCoupons?.length > 0">
                <div class="col">
                    <label for="" class="p-2 theme-area-head text-light w-100 lbl">Add Promo Code</label>
                    <input type="text" class="form-control mx-auto my-1"
                    placeholder="Coupon ID"
                    name="coupon"
                    v-model="couponCode"
                    @change="onChange"
                    >
                    <!-- <label for="">Coupon Code</label> -->
                    <input type="text" class="form-control mx-auto my-1"
                    placeholder="Promo Code"
                    name="promo"
                    v-model="promoCode"
                    @change="onChange"
                    >
                     <a
                      class="btn theme-btn text-light btn-block mx-auto my-2"
                      role="button"
                      @click="addPromo()"
                      >Apply Promo Code</a>
                    <!-- <button class="btn btn-primary mx-auto my-0 m-1">Add</button> -->
                </div>
            </div>
            </div>
            <div class="row pt-1 pb-1">
              <!-- spacing between fixed footer and the page content -->
            </div>
            </div>
            <transition name="modal" v-if="showModal" v-on:colse-modal="modalHide">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modal-header theme-area-head">
                  <!-- text-primary small -->
                  <h6
                    class="text-light card-subtitle  font-weight-bold mb-3"
                  >
                    {{
                      isEdit
                        ? "Edit Coupon"
                        : "Add New Coupon"
                    }}
                  </h6>
                  <slot name="header">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      v-on:click="modalHide"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </slot>
                </div>

                <div class="modal-body">
                  <slot name="body">
                    <EditCoupon
                      v-bind:user="selectedUser"
                      v-bind:edit="isEdit"
                    />
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <Footer />
      </div>
    </div>
    </div>
</template>

<script>
import LeftDrawer from "./LeftDrawer.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Loading from "vue3-loading-overlay";
import EditCoupon from './EditCoupon'
import { mapGetters } from "vuex";
import { LIST_COUPONS,DELETE_COUPON, ADD_PROMO_CODE } from "@/store/actions.type";
export default {
  components: {
    LeftDrawer,
    Header,
    Footer,
    Loading,
    EditCoupon
  },
  beforeMount() {
    this.isLoading = true;
    this.listCoupons();
  },
  // watch:{
  //   showModal(value)
  //   {
  //     if(value == false)
  //     {

  //     }
  //   }
  // },
  computed: {
    ...mapGetters(["getCoupons","couponCount"]),
  },
  methods:{
      modalHide: function() {
      this.showModal = false;
        },
      listCoupons()
      {
          let body = {
              offset: this.offset,
              limit : this.limit,
              search: ''
          }
        this.$store
        .dispatch(LIST_COUPONS,body)
        .then(data=>
        {
            this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
          else
          {
             this.totalCount = this.couponCount;
             this.setPages();
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
      },
      onNewCoupon()
      {
        this.showModal = true;
        this.isEdit = false;
      },
      deleteCouponClicked(coupon)
      {
          this.isLoading = true;
          let body = {
             coupon: coupon.code
          }
        this.$store
        .dispatch(DELETE_COUPON,body)
        .then(data=>
        {
             this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.isLoading = true
             this.$store
              .dispatch(LIST_COUPONS,{offset:0, limit:20, search:''})
              .then(data=>
              {
                if (data.status == "Failure") {
                  this.isLoading = false;
                    this.$toast.show(data.message, {
                    type: "error ",
                    position: "top-right",
                    });
                    }
                    else
                    {
                      this.isLoading = false;
                      this.totalCount = this.couponCount;
                      this.setPages();
                    }
              })
              .catch(()=>
              {
                this.isLoading = false;
              })
          }
        })
        .catch(()=>
        {
            this.isLoading = false;
        })
      },
      onChange(e)
      {
        if(e.target.name == "coupon")
        {
          this.couponCode = e.target.value
        }
        else if(e.target.name == "promo")
        {
          this.promoCode = e.target.value
        }
      },
      checkNumber(value)
        {
            let pattern = /^[0-9]+$/
            return pattern.test(value)
        },
      addPromo()
      {
        let specialPattern =  /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/
        if(!this.couponCode.trim())
        {
          this.$toast.show("Coupon Code is required!", {
              type: "error ",
              position: "top-right",
            })
        }
        else if(this.checkNumber(this.couponCode))
        {
          this.$toast.show("Invalid Coupon Code!", {
              type: "error ",
              position: "top-right",
            })
        }
        else if(specialPattern.test(this.couponCode))
        {
          this.$toast.show("Invalid Coupon Code!", {
              type: "error ",
              position: "top-right",
            })
        }
        else if(/\s/g.test(this.couponCode.trim()))
        {
           this.$toast.show("Coupon Code can't contain white space!", {
              type: "error ",
              position: "top-right",
            })
        }
        else if(!this.promoCode.trim())
        {
          this.$toast.show("Promo Code is required", {
              type: "error ",
              position: "top-right",
            });
        }
        else if(specialPattern.test(this.promoCode))
        {
          this.$toast.show("Promo Code can't contain special Characters", {
              type: "error ",
              position: "top-right",
            });
        }
        else if(/\s/g.test(this.promoCode.trim()))
        {
           this.$toast.show("Promo Code can't contain white space!", {
              type: "error ",
              position: "top-right",
            })
        }
        else
        {
          let body =
          {
            coupon: this.couponCode.trim(),
            premocode: this.promoCode.trim()
          }
          this.$store.dispatch(ADD_PROMO_CODE,body)
          .then((data)=>
          {
             this.isLoading = false;
            if (data.status == "Failure") {
              this.$toast.show(data.message, {
                type: "error ",
                position: "top-right",
              });
            }
            else
            {
              this.couponCode =''
              this.promoCode = ''
              this.isLoading = true
              this.listCouponsOnAddingPromo()
            }
          })
          .catch((err)=>
          {
            this.isLoading = false;
            this.$toast.show(err.message, {
                                type: "error ",
                                position: "top-right",
                                });
          })
        }
      },
      listCouponsOnAddingPromo()
      {
        this.$store
                .dispatch(LIST_COUPONS,{offset:0, limit:20, search:''})
                .then(data=>
                {
                  this.isLoading = false;
                  if (data.status == "Failure") {
                      this.$toast.show(data.message, {
                      type: "error ",
                      position: "top-right",
                      });
                      }
                      else
                      {
                        this.totalCount = this.couponCount;
                        this.setPages();
                      }
                })
                .catch(()=>
                {
                  this.isLoading = false;
                  this.$toast.show("Oops..Something went wrong!", {
                                type: "error ",
                                position: "top-right",
                                });
                })
      },
      setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalCount / this.limit);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    next(page, isNext) {
      let currentPage = 1;
      if (isNext) {
        currentPage = page + 1;
      } else {
        currentPage = page;
      }
      this.offset = (currentPage - 1) * this.limit;
      this.isLoading = true
      this.listCoupons();
    }
  },
  data(){
      return {
          isLoading: false,
          fullPage: true,
          isEdit: false,
          showModal: false,

          totalCount: 0,
            limit: 20,
            offset: 0,
            pages:[],
            page:1,
            promoCode:'',
            couponCode:''
      }
  }
}
</script>

<style scoped>
.table-icons-view {
  width: 28px;
}
.table-cell-adjust {
  text-align: center;
}
table.table-bordered {
  border: 1px rgb(233, 236, 236);
  margin-top: 20px;
  text-align: center;
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgb(233, 236, 236);
}
table.table-bordered > tbody > tr > td {
  border: 1px solid rgb(233, 236, 236);
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
button.page-link {
  display: inline-block;
}
.lbl
{
  border-radius: 15px 15px 0 0;
  text-align: center;
}
.chip
{
  border-radius: 20px;
  color: white;
  background-color: rgb(30, 135, 230);
  /* background-color: #00a5b1bd; */
  font-size: 10pt;
}
.promo
{
    border: 1px solid grey;
    background-color: aqua;
    color: brown;
    border-radius: 20px;
    flex-basis: 24.333333%;
    margin: 1px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.table td {
  text-align: center;
}
.table th {
  text-align: center;
}
.tableColor {
  background: white;
}
</style>