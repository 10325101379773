import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import ApiService from "./api/api.service";
import { VuelidatePlugin } from "@vuelidate/core";
import VueApexCharts from "vue3-apexcharts";
import "./assets/fonts/fontawesome-all.min.css";
import "./assets/fonts/font-awesome.min.css";
import "./assets/fonts/ionicons.min.css";
import "./assets/fonts/line-awesome.min.css";
import "./assets/fonts/material-icons.min.css";
import "./assets/fonts/fontawesome5-overrides.min.css";
import "./assets/css/styles.min.css";
import Toaster from "@meforma/vue-toaster";
import moment from "moment";
import { VueCookieNext } from "vue-cookie-next";
import firebaseMessaging from "./plugins/firebase";
import "./registerServiceWorker";
import { jsPDF } from "jspdf";
import PrimeVue from "primevue/config";
import ClickOutside from "./click-outside";

const app = createApp(App);
app.config.globalProperties.$messaging = firebaseMessaging;
app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.use(Toaster);
app.use(moment);
app.use(jsPDF);
app.use(VuelidatePlugin);
app.use(VueApexCharts);
app.use(VueCookieNext);
app.use(PrimeVue);
app.config.globalProperties.$messaging = firebaseMessaging;
app.directive("click-outside", ClickOutside);

app.mount("#app");
ApiService.init();
// set default config
VueCookieNext.config({ expire: "7d" });
