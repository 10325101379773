import { createRouter, createWebHistory } from "vue-router";
import UserManual from "../views/UserManual.vue";
import TermsAndConditions from "../views/Profile/TermsAndConditions.vue";
import PrivacyPolicy from "../views/Profile/PrivacyPolicy.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword";
import ActivityLog from "../views/ActivityLog";
import CreateAccount from "../views/CreateAccount";
import Dashboard from "../views/DashBoard";
import ResetPassword from "../views/ResetPassword";
import NotFound from "../views/NotFound";
import ChooseAccountTypes from "../views/ChooseAccountTypes";

import Profile from "../views/Profile";
import Onebox from "../views/onebox/Onebox";
import SendNotice from "../views/SendNotice";
import ChangePassword from "../views/ChangePassword";
import SuperAdminView from "../views/SuperAdminView";
import SubscriptionTiers from "../views/SubcriptionTiers";
import PaymentDetails from "../views/PaymentDetails";
import Report from "../views/Report";
import jwtService from "../api/jwt.service";
import TwoFactorAuth from "../views/TwoFactorAuth";
import Coupons from "../views/Coupons";
const routes = [
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/",
    name: "Login",
    beforeEnter: authGuard,
    component: Login,
  },
  {
    path: "/userManual",
    name: "UserManual",
    component: UserManual,
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    // props: true,
    props: (route) => ({
      selectedDeliveryId: route.query.selectedDeliveryId,
      userRole: route.query.userRole,
      companyId: route.query.companyId,
      userId: route.query.userId,
      fromDate: route.query.fromDate,
      toDate: route.query.toDate,
    }),
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/activityLog",
    name: "ActivityLog",
    component: ActivityLog,
  },

  {
    path: "/createAccount",
    name: "CreateAccount",
    component: CreateAccount,
  },
  // {
  //     path: '/createCustomer',
  //     name: 'CreateCustomer',
  //     component: CreateCustomer
  // }
  // ,
  {
    path: "/accountTypes",
    name: "ChooseAccountTypes",
    component: ChooseAccountTypes,
  },
  // {
  //     path: '/payment',
  //     name: 'Payment',
  //     component: Payment,
  //     props: true
  // },
  {
    path: "/coupon",
    name: "Coupons",
    component: Coupons,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/subscriptionTiers",
    name: "SubscriptionTiers",
    component: SubscriptionTiers,
  },
  {
    path: "/paymentDetails",
    name: "PaymentDetails",
    component: PaymentDetails,
  },
  {
    path: "/superAdminView",
    name: "SuperAdminView",
    component: SuperAdminView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },

  {
    path: "/onebox",
    name: "Onebox",
    component: Onebox,
  },
  {
    path: "/sendNotice",
    name: "SendNotice",
    component: SendNotice,
  },
  {
    path: "/verification",
    name: "TwoFactorAuth",
    component: TwoFactorAuth,
  },
  //catch 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

function authGuard(to, from, next) {
  if (jwtService.getToken()) {
    next("/dashboard");
  } else {
    next();
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
