<template>
  <div>
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <h2
      v-if="corporateUsers.length > 0"
      class="text-primary card-subtitle small font-weight-bold mb-3"
    >
      Existing user profiles
    </h2>

    <div class="col" v-for="user in corporateUsers" v-bind:key="user.id">
      <div class="form-group">
        <label class="w-100 m-0" for="email"
          ><strong>{{ user.firstName }} {{ user.lastName }}</strong></label
        >
        <label for="existing-user-email" style="width: 100%">
          <em
            ><strong> {{ user.email }} </strong></em
          >
        </label>

        <button
          v-if="currentUser.userRole != 'User'"
          class="btn theme-btn text-light btn-sm my-2"
          v-on:click="onUpdateClick(user)"
          :disabled="isEnable(user)"
          type="submit"
        >
          Update
        </button>

        <button
          v-if="currentUser.userRole != 'User'"
          class="btn theme-btn text-light btn-sm my-2"
          v-on:click="onResetPasswordClick(user)"
          :disabled="isEnable(user)"
          type="submit"
        >
          Reset Password
        </button>

        <button
          v-if="currentUser.userRole != 'User'"
          class="btn theme-btn text-light btn-sm my-2"
          v-on:click="onActivateDeactivateClick(user)"
          type="submit"
        >
          {{ user.userStatus == "inactive" ? "Activate" : "Deactivate" }}
        </button>
      </div>
    </div>
    <transition name="modal" v-if="showModal" v-on:colse-modal="modalHide">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  v-on:click="modalHide"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <EditUser
                  v-bind:user="selectedUser"
                  v-bind:super-admin="superAdmin"
                  v-bind:company-admin="companyAdminId"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="resetModal"
      v-if="showResetModal"
      v-on:colse-modal="modalResetHide"
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  v-on:click="modalResetHide"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <ResetPassword v-bind:user="resetUser" />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Loading from "vue3-loading-overlay";
import EditUser from "./EditUser";
import ResetPassword from "./ResetPassword";
import { GET_CORPORATE_USERS, ACTIVATE_DEACTIVATE } from "@/store/actions.type";
import { mapGetters } from "vuex";
export default {
  name: "ExistingUserProfiles",
  components: {
    Loading,
    EditUser,
    ResetPassword,
  },
  computed: {
    ...mapGetters(["corporateUsers", "currentUser"]),
  },
  props: ["company-id", "super-admin", "company-admin-id"],
  beforeMount() {
    this.isLoading = true;
    this.getCorporateUsers();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      showModal: false,
      showResetModal: false,
      selectedUser: "",
      resetUser: "",
    };
  },
  methods: {
    isEnable(user) {
      return user.userStatus == "inactive" || user.userStatus == "CA_inactive";
    },
    modalHide: function () {
      this.showModal = false;
      this.selectedUser = "";
    },
    modalResetHide: function () {
      this.showResetModal = false;
      this.resetUser = "";
    },
    getCorporateUsers: function () {
      let body = {
        companyId: this.superAdmin
          ? this.companyId
          : this.$cookie.getCookie("COMPANY_ID"),
      };

      this.$store
        .dispatch(GET_CORPORATE_USERS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onUpdateClick(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    onResetPasswordClick(user) {
      this.resetUser = user;
      this.showResetModal = true;
    },
    onActivateDeactivateClick(user) {
      let body = {
        userId: user.id,
        companyId: user.companyId,
        userStatus: user.userStatus == "active" ? "inactive" : "active",
      };

      this.$store
        .dispatch(ACTIVATE_DEACTIVATE, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.getCorporateUsers();
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch((response) => {
          this.isLoading = false;
          this.$toast.show(response.data.message, {
            type: "error ",
            position: "top-right",
          });
        });
    },

    scrollToElement() {
      const el = document.getElementById("new-user-section");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
