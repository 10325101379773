<template>
  <div>
     <div class="row">
    <div class=".col-6 .col-sm-4 mx-3" v-for="(item, index) in permissionsList" v-bind:key="index">
      <h2
        class="text-primary card-subtitle small font-weight-bold"
        style="margin-top: 10px; padding: 3px; font-size: 16px"
      >
        {{ item.permissionType }}
      </h2>
      
      <div   class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          :id="item.id"
          :value="item.id"
          v-model="selectedPermissions"
          :disabled="isFreeAccount"
          checked=""
        /><label
          class="form-check-label"
          for="formCheck-7"
          style="padding: 3px"
          >{{ item.permissionName }}</label
        >
      </div>
    </div>
     </div>
     
    <button
      class="btn theme-btn text-light btn-sm"
      type="submit"
      style="margin-top: 20px"
      :disabled="isFreeAccount"
      v-on:click="submitChildclick"
    >
      Save&nbsp;Settings
    </button>
  </div>
</template>
<script>
import { GET_PERMISSIONS } from "@/store/actions.type";
export default {
  name: "Permissions",
  beforeMount() {
    this.isLoading = true;
    this.getPermissions();
  },
  props: ["existingPermissions"],
  mounted() {
    this.selectedPermissions= this.existingPermissions
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      selectedPermissions: [],
      permissionsList: "",
      isFreeAccount: this.$cookie.getCookie("PLAN-PRICE") == '0',
    };
  },
  methods: {
    submitChildclick() {
      this.$parent.submitInAddUsers(this.selectedPermissions);
       this.selectedPermissions = [];
    },
  getPermissions: function () {
      this.$store
        .dispatch(GET_PERMISSIONS)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "Failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.permissionsList = data.data;
            // For free account we need to restrict some permissions
            if(this.isFreeAccount) {
             this.selectedPermissions.push(this.permissionsList["Notice Settings"].permissions[0].id)
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

  },
};
</script>