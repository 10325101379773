<template>
  <div id="wrapper">
    <Loading :active="isLoading" :can-cancel="true"></Loading>
    <left-drawer />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header
          @change-delivery="changeDeliveryAccount()"
          @notification-alert="callDefaults()"
        />
        <div class="container-fluid">
          <div
            class="d-sm-flex justify-content-between align-items-center mb-4"
          >
            <h3 class="text-dark mb-0">
              Dashboard - Account:
              {{
                currentUser.companyName
                  ? currentUser.companyName
                  : currentUser.userRole
              }}
            </h3>
            <!-- btn-primary -->
            <a
              class="btn theme-special-btn text-white btn-sm d-none d-sm-inline-block"
              role="button"
              @click="downLoadPdf"
              ><em class="fas fa-download fa-sm text-white-50"></em
              >&nbsp;Generate Report</a
            >
          </div>
          <div class="row">
            <div class="col-md-7 mb-4">
              <div
                style="
                  display: flex;
                  align-content: center;
                  align-items: end;
                  gap: 11px;
                  flex-wrap: wrap;
                "
              >
                <div>
                  Start Date
                  <datepicker v-model="dateLower" :upperLimit="dateUpper" />
                </div>
                <div>
                  End Date
                  <datepicker v-model="dateUpper" :lowerLimit="dateLower" />
                </div>
                <div>
                  <button
                    style="margin-left: 0px; margin-bottom: 0px"
                    class="btn theme-btn text-white btn-sm d-none d-sm-inline-block"
                    v-if="dateLower || dateUpper"
                    @click="clearFilter()"
                  >
                    Clear
                  </button>
                  <button
                    style="
                      margin-left: 0px;
                      margin-bottom: 0px;
                      margin-right: 0px;
                    "
                    class="btn theme-btn text-white btn-sm d-none d-sm-inline-block"
                    v-if="dateLower && dateUpper"
                    @click="searchOnDate()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xl-3 mb-4">
              <div class="card shadow border-left-primary py-2">
                <div class="card-body">
                  <div class="row align-items-center no-gutters">
                    <div class="col mr-2">
                      <div
                        class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                        style="font-size: 16px"
                      >
                        <span style="font-size: 14px">
                          {{
                            currentUser.userRole != "Super Admin" || !isDefault
                              ? "NOTICES RECEIVED "
                              : "TOTAL NOTICES"
                          }}</span
                        >
                      </div>
                      <div class="text-dark font-weight-bold h5 mb-0">
                        <span>{{ receivedCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="currentUser.userRole != 'Super Admin' || !isDefault"
              class="col-md-6 col-xl-3 mb-4"
            >
              <div class="card shadow border-left-success py-2">
                <div class="card-body">
                  <div class="row align-items-center no-gutters">
                    <div class="col mr-2">
                      <div
                        class="text-uppercase text-success font-weight-bold text-xs mb-1"
                        style="color: rgb(78, 115, 223); font-size: 14px"
                      >
                        <span style="color: rgb(78, 115, 223)"
                          >NOTICES SENT</span
                        >
                      </div>
                      <div class="text-dark font-weight-bold h5 mb-0">
                        <span>{{ sentCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-xl-7">
              <div class="card shadow mb-4">
                <div
                  class="card-header theme-area-head d-flex justify-content-between align-items-center"
                >
                  <h6 class="text-white font-weight-bold m-0">
                    ACCOUNT STATISTICS
                  </h6>
                  <div class="dropdown no-arrow d-none">
                    <button
                      class="btn btn-link btn-sm dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                    >
                      <em class="fas fa-ellipsis-v text-gray-400"></em>
                    </button>
                    <div
                      class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                    >
                      <p class="text-center dropdown-header">
                        dropdown header:
                      </p>
                      <a class="dropdown-item" href="#">&nbsp;Action</a
                      ><a class="dropdown-item" href="#"
                        >&nbsp;Another action</a
                      >
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#"
                        >&nbsp;Something else here</a
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <AccountStatics
                    :companyId="selectedCompanyId"
                    :userRole="selectedUserRole"
                    :deliveryId="
                      accountCompanyId
                        ? [accountCompanyId]
                        : this.deliverAccountIDs
                    "
                    :startDate="dateLowerSearch"
                    :endDate="dateUpperSearch"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-5">
              <div class="card shadow mb-4">
                <div
                  class="card-header theme-area-head d-flex justify-content-between align-items-center"
                >
                  <h6 class="text-white font-weight-bold m-0">
                    NOTICE SOURCES
                  </h6>
                  <div class="dropdown no-arrow d-none">
                    <button
                      class="btn btn-link btn-sm dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                    >
                      <em class="fas fa-ellipsis-v text-gray-400"></em>
                    </button>
                    <div
                      class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                    >
                      <p class="text-center dropdown-header">
                        dropdown header:
                      </p>
                      <a class="dropdown-item" href="#">&nbsp;Action</a
                      ><a class="dropdown-item" href="#"
                        >&nbsp;Another action</a
                      >
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#"
                        >&nbsp;Something else here</a
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!-- graph click action -->

                  <NoticeSources
                    :startDate="dateLowerSearch"
                    :endDate="dateUpperSearch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mb-4">
              <div
                v-if="currentUser.userRole != 'User'"
                class="card shadow mb-4"
              >
                <div class="card-header theme-area-head py-3">
                  <h6 class="text-white font-weight-bold m-0">Active Users</h6>
                </div>
                <div v-if="activeUsers?.length != 0" class="card-body">
                  <div v-for="item in activeUsers" v-bind:key="item.id">
                    <h1
                      class="text-primary card-title small font-weight-bold"
                      style="font-size: 14px"
                    >
                      {{ item.name ? item.name : item.companyName }}
                    </h1>
                    <div class="progress mb-4" style="position: relative">
                      <div
                        class="progress-bar"
                        v-bind:class="[
                          item.percent < 10
                            ? 'bg-danger'
                            : item.percent > 10 && item.percent < 30
                            ? 'bg-warning'
                            : 'bg-success',
                        ]"
                        aria-valuenow="{{item.percent}}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-bind:style="{ width: item.percent + '%' }"
                      >
                        <!-- {{ item.percent }}% -->
                      </div>
                      <div
                        style="
                          padding-top: 8px;
                          padding-left: 15px;
                          position: absolute;
                        "
                      >
                        {{ item.percent != "NaN" ? item.percent + "%" : "" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" v-else-if="accountCompanyId != ''">
                  No active users under {{ accountCompanyId }}
                </div>
                <div class="card" v-else>No active users</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountStatics from "./charts/AccountStatics.vue";
import Header from "./Header.vue";
import Footer from "./Footer";

import Loading from "vue3-loading-overlay";
import NoticeSources from "./charts/NoticeSources";
import Datepicker from "vue3-datepicker";
import LeftDrawer from "./LeftDrawer.vue";
import {
  LIST_DELIVERY_ACCOUNTS,
  GET_NOTICE_COUNT,
  GET_ACTIVE_USERS,
} from "@/store/actions.type";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
export default {
  components: {
    AccountStatics,
    Header,
    LeftDrawer,
    NoticeSources,
    Loading,
    Footer,
    Datepicker,
  },
  computed: {
    ...mapGetters(["currentUser", "noticeSources", "selectedDeliveryAccount"]),
  },
  beforeMount() {
    this.getDeliveryAccounts();
  },

  data: function () {
    return {
      isDefault: true,
      isLoading: false,

      dateUpper: "",
      dateLower: "",
      dateLowerSearch: "",
      dateUpperSearch: "",

      userId: "",
      userRole: "",
      selectedCompanyId: "",
      accountCompanyId: "",
      selectedUserRole: "",
      sentCount: "",
      receivedCount: "",
      deliveryAccounts: "",
      deliverAccountIDs: [],
      activeUsers: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
    };
  },
  methods: {
    changeDeliveryAccount() {
      if (this.selectedDeliveryAccount.Deliveryaccount_Alias == "All") {
        this.allAccountDetails();
      } else {
        this.updateSeletedAccount();
      }
    },
    downLoadPdf() {
      if (
        (this.dateLowerSearch == "" && this.dateUpperSearch == "") ||
        this.dateLowerSearch != this.dateUpperSearch
      ) {
        let dateEnd = new Date(this.dateUpperSearch);
        dateEnd.setDate(dateEnd.getDate() - 1);

        let month = ("0" + (dateEnd.getMonth() + 1)).slice(-2);
        let day = ("0" + dateEnd.getDate()).slice(-2);
        let hours = ("0" + dateEnd.getHours()).slice(-2);
        let minutes = ("0" + dateEnd.getMinutes()).slice(-2);
        let seconds = ("0" + dateEnd.getSeconds()).slice(-2);
        var d = [dateEnd.getFullYear(), month, day].join("-");
        var t = [hours, minutes, seconds].join(":");
        dateEnd = [d, t].join(" ");

        this.$router.push({
          name: "Report",
          query: {
            selectedDeliveryId: this.accountCompanyId
              ? [this.accountCompanyId]
              : this.deliverAccountIDs,
            userRole: this.currentUser.userRole,
            companyId: this.currentUser.companyId,
            userId: this.userId,
            fromDate: this.dateLowerSearch,
            toDate: dateEnd,
          },
        });
      } else {
        this.$toast.show(
          "Please choose a particular 'Date Range' or 'Clear' the dates!",
          {
            type: "error ",
            position: "top-right",
          }
        );
      }
    },
    downloader() {
      domtoimage
        .toPng(document.getElementById("wrapper"))
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            format: [900, 1400],
          });
          doc.addImage(img, "JPEG", 20, 20);
          doc.save("onenotice_report.pdf");
        })
        .catch(() => {
          //intentional - catch error
        });
    },
    callDefaults() {
      this.getDeliveryAccounts();
      this.getActiveUsers(
        this.currentUser.companyId,
        this.currentUser.userRole,
        []
      );
    },
    allAccountDetails() {
      let deliveryAccount;
      this.accountCompanyId = "";
      deliveryAccount = this.deliverAccountIDs;

      this.getActiveUsers(
        this.selectedCompanyId == ""
          ? this.currentUser.companyId
          : this.selectedCompanyId,
        this.selectedUserRole == ""
          ? this.currentUser.userRole
          : this.selectedUserRole,
        deliveryAccount
      );

      this.getNoticeCount(this.currentUser.userRole, deliveryAccount);
    },
    deliveryAccountSelected(account) {
      let deliveryAccount;
      if (this.accountCompanyId == account.Deliveryaccount_id) {
        this.accountCompanyId = "";
        deliveryAccount = this.deliverAccountIDs;
      } else {
        this.accountCompanyId = account.Deliveryaccount_id;
        deliveryAccount = [account.Deliveryaccount_id];
      }

      this.getActiveUsers(
        this.selectedCompanyId == ""
          ? this.currentUser.companyId
          : this.selectedCompanyId,
        this.selectedUserRole == ""
          ? this.currentUser.userRole
          : this.selectedUserRole,
        deliveryAccount
      );
      this.getNoticeCount(this.currentUser.userRole, deliveryAccount);
    },
    updateSeletedAccount() {
      let deliveryAccount;
      if (
        this.accountCompanyId == this.selectedDeliveryAccount.Deliveryaccount_id
      ) {
        this.accountCompanyId = "";
        deliveryAccount = this.deliverAccountIDs;
      } else {
        this.accountCompanyId = this.selectedDeliveryAccount.Deliveryaccount_id;
        deliveryAccount = [this.selectedDeliveryAccount.Deliveryaccount_id];
      }

      this.getActiveUsers(
        this.selectedCompanyId == ""
          ? this.currentUser.companyId
          : this.selectedCompanyId,
        this.selectedUserRole == ""
          ? this.currentUser.userRole
          : this.selectedUserRole,
        deliveryAccount
      );
      this.getNoticeCount(this.currentUser.userRole, deliveryAccount);
    },
    onChildClick(value, userRole) {
      let deliveryAccount;
      if (value != "") {
        this.selectedUserRole = userRole;
        this.accountCompanyId
          ? (deliveryAccount = [this.accountCompanyId])
          : (deliveryAccount = this.deliverAccountIDs
              ? this.deliverAccountIDs
              : []);

        this.getNoticeCount(this.selectedUserRole, deliveryAccount);

        this.getActiveUsers(value, userRole, deliveryAccount);
        if (this.currentUser.userRole == "Super Admin") {
          this.getDeliveryAccounts();
        }
        this.isDefault = false;
      } else {
        this.callDefaults();
        this.isDefault = true;
        this.selectedCompanyId = "";
        this.selectedUserRole = "";
        this.accountCompanyId = "";
      }
    },
    barColor() {
      return "bg-warning";
    },

    getActiveUsers(value, userRole, deliveryId, userId) {
      let body = {
        userRole: userRole,
        companyId: userRole == "Super Admin" ? 0 : value,
        userId: userId,
        delivery_accountids: deliveryId,
        startDate: this.dateLowerSearch,
        endDate: this.dateUpperSearch,
      };

      this.isLoading = true;
      this.$store
        .dispatch(GET_ACTIVE_USERS, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.activeUsers = data.data;
            //for super-admin
            if (!this.activeUsers) {
              this.activeUsers = data;
            }

            this.isLoading = false;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.activeUsers = [];
        });
    },

    getNoticeCount(userRole, deliveryId) {
      let body = {
        userRole: userRole,
        deliveryId: userRole == "Super Admin" ? [] : deliveryId,

        startDate: this.dateLowerSearch,
        endDate: this.dateUpperSearch,
      };

      this.isLoading = true;

      this.$store
        .dispatch(GET_NOTICE_COUNT, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.sentCount = data.data.sent;
            this.receivedCount = data.data.received;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDeliveryAccounts() {
      let body = {
        userdetails_id: this.$cookie.getCookie("userdetails_id"),
      };
      this.isLoading = true;
      if (body.userdetails_id) {
        this.$store
          .dispatch(LIST_DELIVERY_ACCOUNTS, body)
          .then((data) => {
            this.isLoading = false;
            if (data.status == "failure") {
              this.$toast.show(data.message, {
                type: "error ",
                position: "top-right",
              });
            } else {
              this.isLoading = false;
              this.deliveryAccounts = data.data;
              this.deliverAccountIDs = data.data.map(
                (item) => item.Deliveryaccount_id
              );

              this.changeDeliveryAccount();
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.deliveryAccounts = [];
          });
      } else {
        this.isLoading = false;
        this.getNoticeCount(this.currentUser.userRole, []);

        this.getActiveUsers(
          this.currentUser.companyId,
          this.currentUser.userRole,
          []
        );
      }
    },
    searchOnDate() {
      let deliveryAccount;
      this.accountCompanyId
        ? (deliveryAccount = [this.accountCompanyId])
        : (deliveryAccount = this.deliverAccountIDs);

      this.dateUpperSearch = this.convertDatePickerTimeToMySQLTime(
        this.dateUpper,
        0
      );
      this.dateLowerSearch = this.convertDatePickerTimeToMySQLTime(
        this.dateLower,
        0
      );
      //check for valid date range selection
      let flagMessage = this.checkValidDate(
        this.dateLowerSearch,
        this.dateUpperSearch
      );
      if (flagMessage == "PROCEED") {
        this.dateUpperSearch = this.convertDatePickerTimeToMySQLTime(
          this.dateUpper,
          1
        );
        this.getNoticeCount(this.currentUser.userRole, deliveryAccount);
        this.getActiveUsers(
          this.currentUser.companyId,
          this.currentUser.userRole,
          deliveryAccount
        );
      } else {
        this.dateUpperSearch = "";
        this.dateLowerSearch = "";
        this.$toast.show(flagMessage, {
          type: "error ",
          position: "top-right",
        });
      }
    },
    clearFilter() {
      this.dateUpper = "";
      this.dateLower = "";
      this.dateUpperSearch = "";
      this.dateLowerSearch = "";
      let deliveryAccount;
      this.accountCompanyId
        ? (deliveryAccount = [this.accountCompanyId])
        : (deliveryAccount = this.deliverAccountIDs);
      this.getNoticeCount(this.currentUser.userRole, deliveryAccount);
      this.getActiveUsers(
        this.currentUser.companyId,
        this.currentUser.userRole,
        deliveryAccount
      );
    },
    convertDatePickerTimeToMySQLTime(str, endDate) {
      let date = new Date(str);
      if (endDate) {
        date.setDate(date.getDate() + 1);
      }
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      var mySQLDate = [date.getFullYear(), month, day].join("-");
      var mySQLTime = [hours, minutes, seconds].join(":");
      return [mySQLDate, mySQLTime].join(" ");
    },
    checkValidDate(start, end) {
      let proceedMessage = "PROCEED";
      let today = new Date();
      if (start == "" || end == "") {
        proceedMessage = "Invalid Date!";
        return proceedMessage;
      }
      let startDate = new Date(start);
      let endDate = new Date(end);
      if (startDate.getFullYear() != endDate.getFullYear()) {
        proceedMessage = "Please Choose Date-range within an year!";
      } else if (today.getFullYear() < startDate.getFullYear()) {
        proceedMessage = "No data available in the chosen date-range!";
      } else if (today < startDate) {
        proceedMessage = "No data available in the chosen date-range!";
      }
      return proceedMessage;
    },
  },
};
</script>

<style>
.backbox {
  background: rgb(166, 169, 170);
}
.card {
  padding: 20px;
}
</style>
