<template>
  <div class="backgroundColor"><h3>Privacy Policy</h3></div>
</template>

<script>
export default {

}
</script>

<style>

.backgroundColor{
  height:100vh; 
}

</style>