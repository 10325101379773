<template>
  <div id="new-user-section">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <h6
      class="text-white card-subtitle theme-area-head  font-weight-bold"
      style="margin-top: 2px;padding:15px;margin-bottom: 15px;border-radius:5px 5px 0 0"
    >
      New User Profile
    </h6>
    <div class="form-row">
      <div class="col">
        <input
          type="text"
          placeholder="First Name"
          class="form-control"
          name="firstName"
          v-model="firstName"
          @change="onChange"
        />
        <p>
          <small class="text-danger" v-if="!firstName && submitted"
            >First name is required</small
          >
        </p>
      </div>
      <div class="col">
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          @change="onChange"
          v-model="lastName"
          class="form-control"
        />
        <p>
          <small class="text-danger" v-if="!lastName && submitted"
            >Last name is required</small
          >
        </p>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col">
        <input
          type="email"
          placeholder="Email"
          name="email"
          v-model="email"
          @change="onChange"
          class="form-control"
        />
        <p>
          <small class="text-danger" v-if="!isEmailValid() && submitted"
            >Valid email id is required</small
          >
        </p>
      </div>

      
    </div>
    <div class="form-row mt-3">
      <div class="col">
        <div>
          <Multiselect
          id="deliveryAcc"
          v-model="accounts.options.Deliveryaccount"
          v-bind="accounts"
          @change="onSelect"
          noResultsText="All Selected!"
          ref="multiselect"
          />
        </div>

       
      </div>
    </div>
    
    <Permissions v-bind:existingPermissions="permissionIds" />
  </div>
</template>
<script>
import Permissions from "./Permissions";
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import {
  CREATE_CORPORATE_USER,
  GET_CORPORATE_USERS,
  GET_DELIVERY_ACCOUNTS,
  SET_ALL_DELIVERY_ACCOUNT_ITEM
} from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
export default {
  name: "AddUser",
  components: {
    Permissions,
    Loading,
    Multiselect,
  },
  computed: {
    ...mapGetters(["deliveryAccounts","currentUser"]),
  },
  props: ["company-id","super-admin","company-admin-id"],
  watch:
  {
    companyAdminId()
    {
      
      this.getDeliveryAccounts();
    }
  },
  created() {
    // set the initial value to be the same as the one in vuex
    this.accountId = this.deliveryAccounts[0]?.accountId;
  },
  beforeMount()
  {
    if ((!this.currentUser?.planDetails?.price == 0) || this.superAdmin ) {
      this.getDeliveryAccounts();
    }
  },
  data() {
    return {
      selected: "Account ID",
      isLoading: false,
      fullPage: true,
      firstName: "",
      lastName: "",
      email: "",
      accountId: "",
      submitted: false,
      permissionIds: [],
      accountsSelectedd: [],
      //for the multiselect tag
      accounts: {
        mode: "tags",
        placeholder: 'Select Account(s)',
        value: [""],
        closeOnSelect: false,
        createTag: true,
        //options object must be in "value", "label" format ; 'label' gets displayed as options
        //eg options:[  {value:'some value1',label:'text1'} , {value:'some value 2',label:'text2'}]
        options: []
      },
    };
  },
  methods: {
        getDeliveryAccounts: function() {
          let body = {
           
            userdetails_id:
                this.companyAdminId ?
                this.companyAdminId
                :
                this.$cookie.getCookie("userdetails_id")
          };
        
          this.$store
            .dispatch(GET_DELIVERY_ACCOUNTS, body)
            .then((data) => {
              this.isLoading = false;
              if (data.status == "Failure") {
                this.$toast.show(data.message, {
                  type: "error ",
                  position: "top-right",
                });
              } else {
                 this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);

                //populating multiselect dropdown
                this.accounts.options = data.data.map(account=>{
                  return{
                    "value":
                        {
                          "id":account.Deliveryaccount_id,
                          "accountId":account.Deliveryaccount,
                          "accountAlias":account.Deliveryaccount_Alias
                        },
                    "label":account.Deliveryaccount_Alias ?
                            account.Deliveryaccount +" - "+ account.Deliveryaccount_Alias
                            :
                            account.Deliveryaccount
                        }
                        })
              }
            })
            .catch(() => {
              this.isLoading = false;
            });
        },
        onSelect(optionValue)
        {
         this.accountsSelectedd = optionValue;
        },
    isEmailValid: function ()
    {
      const emailRegex = /\S+@\S+\.\S+/;
      return this.email == ""
        ? false
        : emailRegex.test(this.email)
        ? true
        : false;
    },
    onChange(e) {
      if (e.target.name == "firstName") {
        this.firstName = e.target.value;
      } else if (e.target.name == "lastName") {
        this.lastName = e.target.value;
      } else if (e.target.name == "email") {
        this.email = e.target.value;
      } else if (e.target.name == "accountId") {
        this.accountId = e.target.value;
      }
    },

    submitInAddUsers(permissions) {
      this.isLoading = true;
      this.submitted = true;

      let i,assignedDeliveryAccounts = [];
      let selectAccounts = document.getElementById("deliveryAcc");
      
      this.accountsSelectedd.forEach((optionValue) =>
      {
        if(optionValue)
        {
          i =
          {
            'id':optionValue.id,
            'value':optionValue.accountId,
            'accountAlias':optionValue.accountAlias
          }
          assignedDeliveryAccounts.push(i)
        }
      }
      )

      if (
        !this.firstName ||
        !this.lastName ||
        !this.isEmailValid() ||
        assignedDeliveryAccounts.length < 1
      ) {
        this.isLoading = false;
        if (assignedDeliveryAccounts.length < 1) {
          selectAccounts.focus();
        }
      } else {
        this.createNewUser(permissions, assignedDeliveryAccounts);
      }
    },
    createNewUser(permissions, accounts) {
      let body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        companyId: this.$cookie.getCookie("COMPANY_ID") ? this.$cookie.getCookie("COMPANY_ID") : this.companyId,
        deliveryAccountId: this.accountId,
        permissionIds: permissions,
        deliveryAccounts: accounts,
        userdetails_id:
        this.$cookie.getCookie("userdetails_id") ?
        this.$cookie.getCookie("userdetails_id") :
        this.companyAdminId
      };
      this.$store
        .dispatch(CREATE_CORPORATE_USER, body)
        .then((data) => {
          if (data.status == "failure") {
            this.isLoading = false;
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.accountsSelectedd = [];
            this.$refs.multiselect.clear();
            // Update the list
            let body = {
              companyId:
              
              this.$cookie.getCookie("COMPANY_ID") ? this.$cookie.getCookie("COMPANY_ID") : this.companyId,
            };
            this.$store
              .dispatch(GET_CORPORATE_USERS, body)
              .then((data) => {
                this.isLoading = false;
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.accountId = "";
                this.submitted = false;
                this.permissionIds = [];
                if (data.status == "Failure") {
                  this.$toast.show(data.message, {
                    type: "error ",
                    position: "top-right",
                  });
                }
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        })
        .catch((error) => {
          this.$toast.show(error.message, {
            type: "error ",
            position: "top-right",
          });
          this.isLoading = false;
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>