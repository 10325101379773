import { Service, post } from "@/api/service";
import {
    GET_DELIVERY_ACCOUNTS,
    UPDATE_CONTACT,
    SAVE_DELIVERY_ACCOUNTS,
    CREATE_CORPORATE_USER,
    LISTUSER_PERMISSIONS,
    GET_PERMISSIONS,
    GET_CORPORATE_USERS,
    EDIT_CORPORATE_USER,
    UPLOAD_PROFILE_IMAGE,
    CHANGE_PASSWORD,
    ACTIVATE_DEACTIVATE,
    RESET_USER_PASSWORD,
    GET_USER_DETAILS,
    UPDATE_ACCOUNT_LIMITS,
    UNREAD_NOTIFICATION_COUNT,
    UPDATE_NOTIFICATION,
    ACTIVITY_LOG,
    GET_IMAGE,
    VIEW_SUBSCRIPTION,
    UPDATE_2FA,
    GET_COMPANY_TWO_FACTOR,
    GET_CONTACT_DETAILS
} from "../actions.type";
import {
    UPDATE_CONTACT_DETAILS,
    SET_ERROR,
    UPDATE_CORPORATE_USERS,
    SET_CORPORATE_USERS,
    APPEND_CORPORATE_USER,
    SET_ACCOUNT_IDS,
    PURGE_AUTH,
    LIST_PERMISSIONS,
    SELECTED_USER,
    PROFILE_IMAGE
} from "../mutations.type";

const state = {
    errors: null,
    deliveryAccounts: '',
    contactDetails: {},
    coporateUsers: '',
    listPermissions: [],
    selectedUser: '',
    profileImage: ''
};

const getters = {
    deliveryAccounts(state) {
        return state.deliveryAccounts;
    },
    getContactInfo(state) {
        return state.contactDetails;
    },
    corporateUsers(state) {
        return state.coporateUsers;
    },
    listPermission(state) {
        return state.listPermissions;
    },
    getSelectedUser(state) {
        return state.selectedUser
    },
    getS3Image(state) {
        return state.profileImage
    }
};

const actions = {
    [GET_DELIVERY_ACCOUNTS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/listDeliveryAccounts", body)
                .then((data) => {
                    if (data.status == "success") {
                        if (data.data) {
                            context.commit(SET_ACCOUNT_IDS, data.data)
                        }
                        resolve(data);
                    } else {
                        reject(data)
                    }
                })
                .catch((response) => {
                    context.commit(SET_ACCOUNT_IDS, response.data)
                    reject(response)
                });
        })
    },
    [UPDATE_ACCOUNT_LIMITS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/addAdditionalAccounts", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [UNREAD_NOTIFICATION_COUNT](context, body) {

        return new Promise((resolve, reject) => {
            post("v2/unreadNotificationCount", body)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },
    [UPDATE_CONTACT](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/saveContactSettings", body)
                .then((data) => {
                    if (data.status == "success") {
                        context.commit(UPDATE_CONTACT_DETAILS, body)
                    }
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },
    [SAVE_DELIVERY_ACCOUNTS](context, body) {
        return new Promise((resolve, reject) => {
            post("saveDeliveryAccounts", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },

    [CREATE_CORPORATE_USER](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/createUser", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    
                    reject(response)
                });
        })
    },

    [EDIT_CORPORATE_USER](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/editCorporateUser", body)
                .then((data) => {
                    context.commit(UPDATE_CORPORATE_USERS, data);
                    resolve(data);
                })
                .catch((response) => {
                   
                    reject(response)
                });
        })
    },
    [ACTIVITY_LOG](context, body) {
        return new Promise((resolve, reject) => {
            post("activityLog", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [LISTUSER_PERMISSIONS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/list_userpermissions", body)
                .then((data) => {
                    context.commit(LIST_PERMISSIONS, data.data);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response);
                    reject(response)
                });
        })
    },
    [GET_CORPORATE_USERS](context, data) {
        return new Promise((resolve, reject) => {
            post("v2/listCorporateUsers", data)
                .then((data) => {
                    context.commit(SET_CORPORATE_USERS, data.data);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.errors);
                    reject(response)
                });
        })
    },
    [GET_IMAGE](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/getProfileImage", body)
                .then((data) => {
                    context.commit(PROFILE_IMAGE, data.data.profileImage);
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [CHANGE_PASSWORD](context, body) {
        return new Promise((resolve, reject) => {
            post("changepassword", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                  
                    reject(response)
                });
        })
    },
    [UPLOAD_PROFILE_IMAGE](context, body) {
        return new Promise((resolve, reject) => {
            Service.post("v2/saveProfileSettings", body)
                .then((data) => {
                    context.commit(PROFILE_IMAGE, data.data.profileImage);
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [ACTIVATE_DEACTIVATE](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/changeUserStatus", body)
                .then((data) => {
                    if (data.status == "success") {
                        resolve(data);
                    }
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },
    [RESET_USER_PASSWORD](context, body) {
        return new Promise((resolve, reject) => {
            post("resetPasswordUser", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [GET_USER_DETAILS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/getCorporateProfileDetails", body)
                .then((data) => {
                    if (data.status == "success") {
                        resolve(data);
                        context.commit(SELECTED_USER, data.data);
                    } else {
                        reject(data)
                    }
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },
    [UPDATE_NOTIFICATION](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/changeNotificationRead", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },
    [GET_PERMISSIONS](context) {
        return new Promise((resolve, reject) => {
            Service.get("v2/listPermissions")
                .then((data) => {
                    context.commit(LIST_PERMISSIONS, data.data);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response);
                    reject(response)
                });
        })
    },
    [VIEW_SUBSCRIPTION](context, body) {
        return new Promise((resolve, reject) => {
            Service.post("v2/create_customerportal", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },

    [GET_COMPANY_TWO_FACTOR](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/get_companytwofactor", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },
    [UPDATE_2FA](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/update2FA", body)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    
                    reject(response)
                });
        })
    },
    [GET_CONTACT_DETAILS](context,body)
    {
        return new Promise((resolve,reject) =>
        {
            post("v2/getContactSettings", body)
            .then((data)=>
            {
                resolve(data)
            })
            .catch((response)=>
            {
                reject(response)
            });
        })
    }


};
function checkStatus(data,resolve,reject)
{
    if (data.status == "success") {
        resolve(data);
    } else {
        reject(data)
    }
}
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [UPDATE_CONTACT_DETAILS](state, contact) {
        state.contactDetails = contact;
        state.errors = {};
    },
    [SELECTED_USER](state, user) {
        state.selectedUser = user;
    },
    [LIST_PERMISSIONS](state, permissions) {
        state.listPermissions = permissions;
        state.errors = {};
    },
    [PROFILE_IMAGE](state, image) {
        state.profileImage = image;
        state.errors = {};
    },
    [UPDATE_CORPORATE_USERS](state) {
        // replace user in existinglist
        // state.coporateUsers = user;
        state.errors = {};
    },
    [SET_CORPORATE_USERS](state, users) {
        state.coporateUsers = users;
        state.errors = {};
    },
    [APPEND_CORPORATE_USER](state, user) {
        // append user to existing
        state.coporateUsers.push(user);
        state.errors = {};
    },
    [SET_ACCOUNT_IDS](state, ids) {
        state.deliveryAccounts = ''
        state.deliveryAccounts = ids;
        state.errors = {};
    },
    [PURGE_AUTH](state) {
        state.deliveryAccounts = '';
            state.contactDetails = {};
            state.coporateUsers = '';
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};