<template>
  <div class="backgroundColor"> <h3>Terms and Conditions</h3> </div>
</template>

<script>
export default {

}
</script>

<style>
.backgroundColor{
  height:100vh; 
  color: antiquewhite;
}

</style>